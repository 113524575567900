import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import Section from '../components/Section';
import TermsHtml from '../components/htmlContent/termsHtml.html';
import MainTemplate from '../templates/MainTemplate';

const createMarkup = text => ({
  __html: text,
});

const TermsConditions = () => (
  <MainTemplate title='Terms and conditions' hasBack={true} hasDrawer={false}>
    <Section>
      <Typography variant='h6'>
        Terms and conditions
      </Typography>
      <Typography>
        'at heart' v1.0
      </Typography>
      <Typography paragraph>
        Last updated: July 1, 2021
      </Typography>
      <Typography>
        <span dangerouslySetInnerHTML={createMarkup(TermsHtml)} />
      </Typography>
    </Section>
  </MainTemplate>
);

export default TermsConditions;
