import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {
  Drawer,
  List,
  Icon,
  IconButton,
  Typography,
  Link,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Section from './Section';
import ButtonListItem from './ButtonListItem';
import SignOutModal from './SignOutModal';
const links = [
  {
    text: 'Chatbot',
    link: '/chat',
  },
  {
    text: 'Library',
    link: '/library',
  },
  {
    text: 'My check-ins',
    link: '/checkins',
  },
  {
    text: 'My Profile',
    link: '/profile',
  },
];

const footerLinks = [
  {
    text: 'Settings',
    link: '/settings',
  },
  {
    text: 'About',
    link: '/about',
  },
];

function DrawerRight({ open, handleDrawerClose }) {

  const history = useHistory();
  const [openSignOutModal, setOpenSignOutModal] = useState(false);
  const name = useSelector(state => state.auth.user.name);
  const greeting = name ? `Hi ${name}!` : 'Hi!';

  const handleButtonLink = (link) => {
    history.push(link);
  }

  return (
    <Drawer
      className='drawer'
      variant='persistent'
      anchor='right'
      open={open}
    >
      <IconButton className='drawer__close-button black-text' onClick={handleDrawerClose}>
        <img className='drawer__close-butto--icon' src='/images/CloseX.svg' />
      </IconButton>
      <img className='drawer__avatar' src="/images/Mobile_MenuDrawer_image.png" />
      <Section>
        <List className='drawer__text'>
          <Typography variant='h5'>
            <span><strong>{greeting}</strong></span>
          </Typography>
        </List>
        <List className='drawer__list'>
          {links.map((link) => (
            <ButtonListItem className='black-text' disableRipple={true} key={link.text} onClick={() => handleButtonLink(link.link)} text={link.text} hasIcon />
          ))}
        </List>
        <List className='drawer__list'>
          {footerLinks.map((link) => (
            <ButtonListItem className='black-text' disableRipple={true} key={link.text} onClick={() => handleButtonLink(link.link)} text={link.text} hasIcon />
          ))}
        </List>
        <List className='drawer__list'>
          <Link className='pink-text' onClick={() => setOpenSignOutModal(true)}>
            <ListItem button >
              <ListItemText primary='Sign Out' />
            </ListItem>
          </Link>
        </List>
        <SignOutModal
          open={openSignOutModal}
          textOnClick={() => setOpenSignOutModal(false)}
          onClose={() => setOpenSignOutModal(false)}
        />
      </Section>
    </Drawer>
  );
}

DrawerRight.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default DrawerRight;
