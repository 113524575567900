import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import store from './redux/store';
import theme from './theme';

import HomePage from './pages/index';
import ChatPage from './pages/chat';
import EducationPage from './pages/education';
import MyProfilePage from './pages/profile';
import Article from './pages/article';
import SignOut from './pages/signOut';

import DataDonationConsentPage from './pages/data-donation/consent';
import DataDonationSharePage from './pages/data-donation/share';
import DataDonationProgressPage from './pages/data-donation/progress';
import DataDonationSurveyForm from './pages/data-donation/surveyForm';

import LibrarySearch from './pages/library/librarySearch';
import Library from './pages/library/library';

import CheckIns from './pages/checkIns/checkIns';
import CheckInDetail from './pages/checkIns/checkInDetail'
import CheckInNote from './pages/checkIns/checkInNote'

import Login from './pages/login/login';

import SignUpContainer from './pages/login/signupContainer';

import SignUpSuccess from './pages/login/signupSuccess';
import VerifySuccess from './pages/login/verifySuccess';

import Forget from './pages/login/forget';
import ForgetSuccess from './pages/login/forgetSuccess';
import Reset from './pages/login/reset'
import ResetSuccess from './pages/login/resetSuccess';
import Welcome from './pages/login/welcome';

import Settings from './pages/settings';
import Password from './pages/password';
import About from './pages/about';
import AboutAtHeart from './pages/aboutAtHeart';

import Unsubscribe from './pages/unsubscribe';
import UnsubscribeSuccess from './pages/unsubscribeSuccess';

import HealthBackground from './pages/healthBackground';
import EventProfile from './pages/eventProfile';
import EventProfileEdit from './pages/eventProfileEdit';

import TermsConditions from './pages/termsConditions';

import ProtectedRoute from './routers/ProtectedRoute';
import UnprotectedRoute from './routers/UnprotectedRoute';

import PageScrollController from './components/PageScrollController';

export default function App() {
  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <PageScrollController />
            <Switch>
              <ProtectedRoute exact path="/" component={HomePage} />
              <ProtectedRoute exact path="/chat" component={ChatPage} />
              <ProtectedRoute exact path="/education" component={EducationPage} />
              <ProtectedRoute exact path="/profile" component={MyProfilePage} />

              <ProtectedRoute exact path="/profile/health-background" component={HealthBackground} />
              <ProtectedRoute exact path="/profile/event-profile" component={EventProfile} />
              <ProtectedRoute exact path="/profile/event-profile/edit" component={EventProfileEdit} />

              <ProtectedRoute exact path="/library/article/:slug" component={Article} />
              <ProtectedRoute exact path="/library/search" component={LibrarySearch} />
              <ProtectedRoute exact path="/library/:category?" component={Library} />

              <ProtectedRoute exact path="/checkins" component={CheckIns} />
              <ProtectedRoute exact path="/checkins/note/:uuid" component={CheckInNote} />
              <ProtectedRoute exact path="/checkins/:type/:date" component={CheckInDetail} />

              <UnprotectedRoute path="/account/signup" component={SignUpContainer} />

              <UnprotectedRoute path="/account/success" component={SignUpSuccess} />
              <UnprotectedRoute path="/account/confirm" component={VerifySuccess} />

              <UnprotectedRoute exact path="/account/login" component={Login} />
              <UnprotectedRoute exact path="/account/forget" component={Forget} />
              <UnprotectedRoute exact path="/account/forget/success" component={ForgetSuccess} />
              <UnprotectedRoute exact path="/account/reset" component={Reset} />
              <UnprotectedRoute exact path="/account/reset/success" component={ResetSuccess}/>
              <ProtectedRoute exact path="/account/welcome" component={Welcome} />
              <ProtectedRoute exact path="/account/signout" component={SignOut} />

              {/* Look for better way to handle nested routes */}
              <ProtectedRoute exact path="/data-donation/consent" component={DataDonationConsentPage} />
              <ProtectedRoute exact path="/data-donation/share" component={DataDonationSharePage} />
              <ProtectedRoute exact path='/data-donation/progress' component={DataDonationProgressPage} />
              <ProtectedRoute exact path='/data-donation/form/:id' component={DataDonationSurveyForm} />
              <ProtectedRoute exact path="/data-donation/survey-form" component={DataDonationSurveyForm} />

              <ProtectedRoute exact path="/settings" component={Settings} />
              <ProtectedRoute exact path="/settings/password" component={Password} />
              <ProtectedRoute exact path="/about" component={About} />
              <ProtectedRoute exact path="/about/at-heart" component={AboutAtHeart} />

              <ProtectedRoute exact path="/about/terms" component={TermsConditions} />

              <Route exact path="/unsubscribe/success" component={UnsubscribeSuccess} />
              <Route exact path="/unsubscribe/:uuid" component={Unsubscribe} />

              {/* TODO: 404 page*/}
              <Route render={() => <Redirect to='/' />} />

            </Switch>
          </Router>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
}
