import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCurrentAllCheckInEntry, fetchCurrentHeartCheckInEntry, fetchCurrentWellnessCheckInEntry } from '../../redux/actions/checkInActions';
import {
  useMediaQuery,
  useTheme,
  Typography,
  Fab,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Section from '../../components/Section';
import CheckInEntry from '../../components/CheckInEntry';
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import MainTemplate from '../../templates/MainTemplate';
import BackButton from '../../components/BackButton';
import PrintIcon from '@material-ui/icons/Print';

const CheckInDetail = () => {

  let { type, date } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.checkIn.currentCheckIn);
  let history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let today = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    if (type === 'all') {
      dispatch(fetchCurrentAllCheckInEntry(date));
    } else if (type === 'heart') {
      dispatch(fetchCurrentHeartCheckInEntry(date));
    } else if (type === 'wellness') {
      dispatch(fetchCurrentWellnessCheckInEntry(date));
    } else {
      history.push("/checkins");
    }
  }, []);

  let currentDate = null;
  let currentData = [];

  if (data) {
    currentDate = data.date;
    currentData = data.data;
  }

  const handlePrint = () => {
    window.print();
  }

  return (
    <MainTemplate hasBack={true} hasPadding={true} title='My check-ins'>
      <Box className='check-in__top' display="block" displayPrint="none">
        {!isMobile &&
            <BackButton />
        }
        {type === 'all' &&
          <div className='check-in__fab'>
            <Fab color='primary' aria-label='print' onClick={handlePrint}>
              <PrintIcon />
            </Fab>
          </div>
        }
      </Box>
      <Section>
        <Typography className='check-in__date'>
          {currentDate === today ?
            'Today'
            :
            currentDate
          }
        </Typography>

        {currentData.map((d, index) => (
          <CheckInEntry key={index} data={d} />
        ))}
      </Section>
    </MainTemplate>
  );
};

export default CheckInDetail;
