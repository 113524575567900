import { api } from './api';
import getQueryString from '../../util/getQueryString';

async function getAllCheckInData(query) {
  const queryString = getQueryString(query);
  const { data, status } = await api.get(`/checkin/all${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getHeartCheckInData(query) {
  const queryString = getQueryString(query);
  const { data, status } = await api.get(`/checkin/heart${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getWellnessCheckInData(query) {
  const queryString = getQueryString(query);
  const { data, status } = await api.get(`/checkin/wellness${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getAllCheckInDataByDate(date) {
  const { data, status } = await api.get(`/checkin/all/${date}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getHeartCheckInDataByDate(date) {
  const { data, status } = await api.get(`/checkin/heart/${date}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getWellnessCheckInDataByDate(date) {
  const { data, status } = await api.get(`/checkin/wellness/${date}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getNotesCheckInData(query) {
  const queryString = getQueryString(query);
  const { data, status } = await api.get(`/checkin/note${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

export {
  getAllCheckInData,
  getHeartCheckInData,
  getWellnessCheckInData,
  getAllCheckInDataByDate,
  getHeartCheckInDataByDate,
  getWellnessCheckInDataByDate,
  getNotesCheckInData,
};
