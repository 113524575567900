import {
  getAllCheckInData,
  getHeartCheckInData,
  getWellnessCheckInData,
  getAllCheckInDataByDate,
  getHeartCheckInDataByDate,
  getWellnessCheckInDataByDate,
  getNotesCheckInData,
} from '../../services/heartPainApi/checkIn';


export const GET_ALL_CHECK_IN_ENTRY = 'GET_ALL_CHECK_IN_ENTRY';
export const GET_MORE_ALL_CHECK_IN_ENTRY = 'GET_MORE_ALL_CHECK_IN_ENTRY';
export const GET_HEART_CHECK_IN_ENTRY = 'GET_HEART_CHECK_IN_ENTRY';
export const GET_MORE_HEART_CHECK_IN_ENTRY = 'GET_MORE_HEART_CHECK_IN_ENTRY';
export const GET_WELLNESS_CHECK_IN_ENTRY = 'GET_WELLNESS_CHECK_IN_ENTRY';
export const GET_MORE_WELLNESS_CHECK_IN_ENTRY = 'GET_MORE_WELLNESS_CHECK_IN_ENTRY';
export const GET_CURRENT_ALL_CHECK_IN_ENTRY = 'GET_CURRENT_ALL_CHECK_IN_ENTRY';
export const GET_CURRENT_HEART_CHECK_IN_ENTRY = 'GET_CURRENT_HEART_CHECK_IN_ENTRY';
export const GET_CURRENT_WELLNESS_CHECK_IN_ENTRY = 'GET_CURRENT_WELLNESS_CHECK_IN_ENTRY';
export const GET_NOTES_CHECK_IN_ENTRY = 'GET_NOTES_CHECK_IN_ENTRY';
export const GET_MORE_NOTES_CHECK_IN_ENTRY = 'GET_MORE_NOTES_CHECK_IN_ENTRY';

export const getAllCheckInEntry = data => ({
  type: GET_ALL_CHECK_IN_ENTRY,
  data,
});

export const getMoreAllCheckInEntry = data => ({
  type: GET_MORE_ALL_CHECK_IN_ENTRY,
  data,
});

export const getHeartCheckInEntry = data => ({
  type: GET_HEART_CHECK_IN_ENTRY,
  data,
});

export const getMoreHeartCheckInEntry = data => ({
  type: GET_MORE_HEART_CHECK_IN_ENTRY,
  data,
});

export const getWellnessCheckInEntry = data => ({
  type: GET_WELLNESS_CHECK_IN_ENTRY,
  data,
});

export const getMoreWellnessCheckInEntry = data => ({
  type: GET_MORE_WELLNESS_CHECK_IN_ENTRY,
  data,
});

export const getCurrentAllCheckInEntry = data => ({
  type: GET_CURRENT_ALL_CHECK_IN_ENTRY,
  data,
});

export const getCurrentHeartCheckInEntry = data => ({
  type: GET_CURRENT_HEART_CHECK_IN_ENTRY,
  data,
});

export const getCurrentWellnessCheckInEntry = data => ({
  type: GET_CURRENT_WELLNESS_CHECK_IN_ENTRY,
  data,
});

export const getNotesCheckInEntry = data => ({
  type: GET_NOTES_CHECK_IN_ENTRY,
  data,
});

export const getMoreNotesCheckInEntry = data => ({
  type: GET_MORE_NOTES_CHECK_IN_ENTRY,
  data,
});

export function getAllCheckIn() {
  return async dispatch => {
    let data = await getAllCheckInData();
    await dispatch(getAllCheckInEntry(data));
  };
}

export function getMoreAllCheckIn() {
  return async (dispatch, getState) => {
    let beforeDate = getState().checkIn.firstDate;
    if (beforeDate) {
      let data = await getAllCheckInData({before: beforeDate});
      await dispatch(getMoreAllCheckInEntry(data));  
    }
  };
}

export function getHeartCheckIn() {
  return async dispatch => {
    let data = await getHeartCheckInData();
    await dispatch(getHeartCheckInEntry(data));
  };
}

export function getMoreHeartCheckIn() {
  return async (dispatch, getState) => {
    let beforeDate = getState().checkIn.firstDate;
    if (beforeDate) {
      let data = await getHeartCheckInData({before: beforeDate});
      await dispatch(getMoreHeartCheckInEntry(data));  
    }
  };
}

export function getWellnessCheckIn() {
  return async dispatch => {
    let data = await getWellnessCheckInData();
    await dispatch(getWellnessCheckInEntry(data));
  };
}

export function getMoreWellnessCheckIn() {
  return async (dispatch, getState) => {
    let beforeDate = getState().checkIn.firstDate;
    if (beforeDate) {
      let data = await getWellnessCheckInData({before: beforeDate});
      await dispatch(getMoreWellnessCheckInEntry(data));  
    }
  };
}

export function fetchCurrentAllCheckInEntry(date) {
  return async dispatch => {
    let data = await getAllCheckInDataByDate(date);
    await dispatch(getCurrentAllCheckInEntry(data));
  };
}

export function fetchCurrentHeartCheckInEntry(date) {
  return async dispatch => {
    let data = await getHeartCheckInDataByDate(date);
    await dispatch(getCurrentHeartCheckInEntry(data));
  };
}

export function fetchCurrentWellnessCheckInEntry(date) {
  return async dispatch => {
    let data = await getWellnessCheckInDataByDate(date);
    await dispatch(getCurrentWellnessCheckInEntry(data));
  };
}

export function getNotesCheckIn() {
  return async dispatch => {
    let data = await getNotesCheckInData();
    await dispatch(getNotesCheckInEntry(data));
  };
}

export function getMoreNotesCheckIn() {
  return async (dispatch, getState) => {
    let beforeDate = getState().checkIn.firstDate;
    if (beforeDate) {
      let data = await getNotesCheckInData({before: beforeDate});
      await dispatch(getMoreNotesCheckInEntry(data)); 
    }
  };
}
