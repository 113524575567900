// TODO: handle errors

import {
  authenticated,
  login,
  logout,
  register,
  forget,
  reset,
} from '../../services/heartPainApi/auth';


export const AUTH_AUTHENTICATED_CHECK = 'AUTH_AUTHENTICATED_CHECK';
export const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REGISTER_USER = 'AUTH_REGISTER_USER';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_FORGET_EMAIL = 'AUTH_FORGET_EMAIL';

export function loginAction(userData) {
  return async dispatch => {
    const { user, firstLogin, error } = await login(userData);
    if (error) {
      dispatch({ type: AUTH_LOGIN_ERROR, error });
      return error;
    } else {
      dispatch({ type: AUTH_LOGIN_USER, firstLogin, user });
    }
  };
}

export function registerAction(userData) {
  return async dispatch => {
    const { user, error } = await register(userData);
    if (error) {
      dispatch({ type: AUTH_REGISTER_ERROR, error });
      return error;
    } else {
      return true;
    }
  };
}

export function forgetAction(userData) {
  return async dispatch => {
    const { error } = await forget(userData);
    if (error) {
      dispatch({ type: AUTH_RESET_ERROR, error }); //need change
      return error;
    } else {
      dispatch({ type: AUTH_FORGET_EMAIL, forgetEmail: userData.email });
    }
  };
}

export function resetAction(password, targetToken) {
  return async dispatch => {
    const { error } = await reset(password, targetToken);
    if (error) {
      dispatch({ type: AUTH_RESET_ERROR, error }); // need change
      return error;
    }
  };
}

export function authCheckAction(cb) {
  return async dispatch => {
    const { isAuthenticated, data } = await authenticated();
    if (isAuthenticated && data) {
      const user = data.user;
      dispatch({ type: AUTH_AUTHENTICATED_CHECK, isAuthenticated, user });
    } else {
      dispatch({ type: AUTH_LOGOUT });
    }
    cb && cb();
  }
}

export function logoutAction() {
  return async dispatch => {
    await logout();
    dispatch({ type: AUTH_LOGOUT });
  }
}
