import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Icon,
  IconButton,
} from '@material-ui/core';
import { ParentUrlIndex } from '../constant';

function BackButton({
  isAbsolute,
  isNavButton,
  iconClass,
}) {
  const history = useHistory();
  const location = useLocation();
  const navButtonClass = isNavButton ? 'back-button--nav' : 'back-button';
  const absoluteClass = isAbsolute ? ' back-button--absolute' : '';
  const pathnames = location.pathname.split('/').filter((el) => (el.length != 0));

  function goBack() {
    const path = history.location.state?.from?.pathname;
    if (path) {
      history.goBack();
    } else if (pathnames.length > 1) {
      const targetLink = ParentUrlIndex[pathnames[0]];
      if (targetLink) {
        history.push(targetLink);
      } else {
        history.goBack();
      }
    } else {
      history.push('/');
    }
  }

  return (
    <IconButton edge='start' className={navButtonClass.concat(absoluteClass)} aria-label='back' onClick={goBack}>
      <Icon className={iconClass}>arrow_back</Icon>
    </IconButton>
  );
}

export default BackButton;
