import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Typography,
  FormControl,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const createMarkup = text => ({
  __html: text,
});

const Checkbox = ({ nodeId, question, data, multi, answer, onParentChange, alignLeft = false }) => {

  const defaultNoneValue = answer.length === 1 && data.find(data => data.id === answer[0])?.type === 'solo';

  const [choices, setChoices] = useState(answer);
  const [noneChoice, setNoneChoice] = useState(defaultNoneValue);

  useEffect(() => {
    onParentChange(null, choices, nodeId);
  }, [choices]);

  const addChoice = c => {
    setChoices(choices => [...choices, c]);
  }

  const removeChoice = c => {
    let newChoices = [...choices];
    const index = newChoices.indexOf(c);
    if (index > -1) {
      newChoices.splice(index, 1);
    }
    setChoices(newChoices);
  }

  const isSelected = c => {
    return choices.includes(c.id);
  }

  const handleSelect = (choice, type)  => {
    if (multi) {
      if(choices.includes(choice)){
        if (type === 'solo') {
          setNoneChoice(false);
        }
        removeChoice(choice);
      } else {
        if (type === 'solo') {
          setChoices([]);
          setNoneChoice(true);
        } else if (choices.length === 1 && noneChoice) {
          setChoices([]);
          setNoneChoice(false);
        }
        addChoice(choice);
      }
    } else {
      if(choices.includes(choice)){
        removeChoice(choice);
      } else if (choices.length === 0) {
        addChoice(choice);
      } else {
        setChoices([choice]);
      }
    }
  }

  const buttonClasses = ['checkbox'];
  alignLeft && buttonClasses.push('checkbox--align-left');

  return (
    <>
      <div className="question-node checkbox-question" id={nodeId}>
        <Typography variant='subtitle1' className='data-donation__question'>
          <span dangerouslySetInnerHTML={createMarkup(question)} />
        </Typography>
        <FormControl variant="outlined" className='data-donation__form--checkbox'>
          <ButtonGroup variant='text' aria-label='text button group' className='checkbox-group'>
            {data.map((c) => (
              <Button
                key={c.id}
                value={c.id}
                className={`${buttonClasses.join(' ')} ${(isSelected(c)) ? 'selected' : ''}`}
                variant='contained'
                onClick={() => handleSelect(c.id, c.type)}
                disableElevation
              >
                {c.text}
              </Button>
            ))}
          </ButtonGroup>
        </FormControl>
      </div>
    </>
  );
};

Checkbox.propTypes = {
  nodeId: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  answer: PropTypes.array,
  onParentChange: PropTypes.func,
};

Checkbox.defaultProps = {
  data: [],
  answer: [],
};

export default Checkbox;
