import React from 'react';
import {
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
} from '@material-ui/core';
import BackButton from '../components/BackButton';
import Section from '../components/Section';
import AboutArticle from '../components/htmlContent/aboutAtHeart.html';
import MainTemplate from '../templates/MainTemplate';


const createMarkup = text => ({
  __html: text,
});

const AboutAtHeart = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainTemplate title='' hasGlobal={false} hasPadding={!isMobile} hasDrawer={!isMobile} hideNavBarInMobile={true}>
      <Section className='section section__about'>
        <BackButton />
        <article>
          <Typography>
            <span dangerouslySetInnerHTML={createMarkup(AboutArticle)} />
          </Typography>
        </article>
      </Section>
    </MainTemplate>
  );
}

export default AboutAtHeart;
