import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useTheme,
  useMediaQuery,
  List,
  Typography,
  TextField,
  Button,
  ButtonGroup
} from '@material-ui/core';
import MainTemplate from '../templates/MainTemplate';
import Section from '../components/Section';
import Checkbox from './data-donation/form/Checkbox';
import TextInput from './data-donation/form/TextInput';

import { getHealthBackground, updateHealthBackground } from '../services/heartPainApi/profile';

const sexOption = [
  {
    id: 'female',
    text: 'Female'
  },{
    id: 'male',
    text: 'Male',
  }
];

const ocadOption = [
  {
    "id": "obstructive_coronary_artery_disease",
    "text": "Obstructive coronary artery disease",
  },{
    "id": "non_obstructive_coronary_artery_disease",
    "text": "Non-obstructive coronary artery disease",
  },{
    "id": "dont_know",
    "text": "I don't know",
  }
]

const heartRelatedConditionOption = [
  {
    "id": "heart_related_condition_obstructive_coronary_artery_disease",
    "text": "Obstructive coronary artery disease",
  },{
    "id": "heart_related_condition_non_obstructive_coronary_artery_disease",
    "text": "Non-obstructive coronary artery disease",
  },{
    "id": "heart_related_condition_sudden_coronary_artery_dissection",
    "text": "Sudden coronary artery dissection (SCAD)",
  },{
    "id": "heart_related_condition_coronary_artery_spasm",
    "text": "Coronary artery spasm",
  },{
    "id": "heart_related_condition_heart_failure",
    "text": "Heart failure",
  },{
    "id": "heart_related_condition_cardiomyopathy",
    "text": "Cardiomyopathy",
  },{
    "id": "heart_related_condition_takotsubo_syndrome",
    "text": "Takotsubo syndrome",
  },{
    "id": "heart_related_condition_stent_inserted_into_a_coronary_artery",
    "text": "Stent inserted into a coronary artery",
  },{
    "id": "heart_related_condition_coronary_artery_bypass_graft_surgery",
    "text": "Coronary artery bypass graft surgery",
  },{
    "id": "heart_related_condition_no",
    "text": "No I haven't",
    "type": "solo",
  },{
    "id": "heart_related_condition_dont_know",
    "text": "I don't know",
    "type": "solo",
  },{
    "id": "heart_related_condition_other",
    "text": "Other",
    "type": "solo",
  }
];

const yesNoOption = [
  {
    id: 'true',
    text: 'Yes'
  },{
    id: 'false',
    text: 'No'
  },
]

const yesNoDontKnowOption = [
  {
    id: 'yes',
    text: 'Yes'
  },{
    id: 'no',
    text: 'No'
  },{
    id: 'dont_know',
    text: "I don't know"
  }
]

const dailyHeartPainOption = [
  {
    id: 'true',
    text: 'Yes, I have heart pain, discomfort, or symptoms on a very regular basis'
  },{
    id: 'false',
    text: 'No'
  }
]

const HealthBackground = () => {

  const [answer, setAnswer] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    (async () => {
      const data = await getHealthBackground() ?? {};

      if (Object.keys(data).length === 0) {
        setAnswer({});
      } else {
        const heart_related_condition = [];
        for (const key of Object.keys(data)) {
          if (key.startsWith('heart_related_condition_') && key !== 'heart_related_condition_other_text' && data[key] === 'true') {
            heart_related_condition.push(key);
          }
        }
        setAnswer({
          age: data.age,
          sex: [data.sex],
          heart_attack: [data.heart_attack],
          daily_heart_pain: [data.daily_heart_pain],
          physical_disability: [data.physical_disability],
          heart_related_condition: heart_related_condition,
          heart_related_condition_other_text: data.heart_related_condition_other_text ?? '',
        });
      }
    })()
  }, []);

  function handleAnswerChange(event, value, answerId) {
    if (value.length === 0 && answerId !== 'age') {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
    setAnswer({
      ...answer,
      [answerId]: value
    });
  }

  function handleSubmit() {
    const result = {
      age: answer.age,
      sex: answer.sex[0],
      heart_attack: answer.heart_attack[0],
      daily_heart_pain: answer.daily_heart_pain[0],
      physical_disability: answer.physical_disability[0],
      heart_related_condition_obstructive_coronary_artery_disease: answer.heart_related_condition.includes('heart_related_condition_obstructive_coronary_artery_disease'),
      heart_related_condition_non_obstructive_coronary_artery_disease: answer.heart_related_condition.includes('heart_related_condition_non_obstructive_coronary_artery_disease'),
      heart_related_condition_sudden_coronary_artery_dissection: answer.heart_related_condition.includes('heart_related_condition_sudden_coronary_artery_dissection'),
      heart_related_condition_coronary_artery_spasm: answer.heart_related_condition.includes('heart_related_condition_coronary_artery_spasm'),
      heart_related_condition_heart_failure: answer.heart_related_condition.includes('heart_related_condition_heart_failure'),
      heart_related_condition_cardiomyopathy: answer.heart_related_condition.includes('heart_related_condition_cardiomyopathy'),
      heart_related_condition_takotsubo_syndrome: answer.heart_related_condition.includes('heart_related_condition_takotsubo_syndrome'),
      heart_related_condition_stent_inserted_into_a_coronary_artery: answer.heart_related_condition.includes('heart_related_condition_stent_inserted_into_a_coronary_artery'),
      heart_related_condition_coronary_artery_bypass_graft_surgery: answer.heart_related_condition.includes('heart_related_condition_coronary_artery_bypass_graft_surgery'),
      heart_related_condition_no: answer.heart_related_condition.includes('heart_related_condition_no'),
      heart_related_condition_dont_know: answer.heart_related_condition.includes('heart_related_condition_dont_know'),
      heart_related_condition_other: answer.heart_related_condition.includes('heart_related_condition_other'),
      heart_related_condition_other_text: answer.heart_related_condition.includes('heart_related_condition_other') ? answer.heart_related_condition_other_text : '',
    };
    updateHealthBackground(result);
    setCanSubmit(false);
  }

  if (answer === null) {
    return null;
  } else if (Object.keys(answer).length === 0) {
    return(
      <MainTemplate title='My profile' hasBack={true} hasDrawer={false} hasPadding={true}>
        <Section>
          <Typography className='health-background__title' variant='h5'>
            Please finish Onboarding flow in Chat first.
          </Typography>
        </Section>
      </MainTemplate>
    );
  } else {
    return (
      <MainTemplate title='My profile' hasBack={true} hasDrawer={false} hasPadding={true}>
        <img className='health-background__picture' src='/images/health_background_logo.png' alt='chat banner image' />
        <Section>
          <Typography className='health-background__title' variant='h5'>
            My health background
          </Typography>
          <div className='health-background__q1'>
            <TextInput
              nodeId='age'
              key='text_input_1'
              question='1. What is your age in years?'
              type='numberinput'
              answer={answer.age}
              onParentChange={handleAnswerChange}
            />
          </div>
          <div className='health-background__q2'>
            <Checkbox
              nodeId='sex'
              key='single_checkbox_2'
              question='2. What is your sex assigned at birth?'
              data={sexOption}
              answer={answer.sex}
              onParentChange={handleAnswerChange}
            />
          </div>
          <div className='health-background__q3'>
            <Checkbox
              nodeId='heart_related_condition'
              key='single_checkbox_3'
              question='3. Do you have either of the following?'
              data={heartRelatedConditionOption}
              answer={answer.heart_related_condition}
              onParentChange={handleAnswerChange}
              multi
            />
          </div>
          {answer.heart_related_condition.includes('heart_related_condition_other') &&
            <div className='health-background__q3-other'>
              <TextInput
                nodeId='heart_related_condition_other_text'
                key='text_input_3'
                question='3.5. Please specify your other heart related condition(s):'
                type='textinput'
                answer={answer.heart_related_condition_other_text}
                onParentChange={handleAnswerChange}
              />
            </div>
          }
          <div className='health-background__q4'>
            <Checkbox
              nodeId='heart_attack'
              key='single_checkbox_4'
              question='4. Have you had a heart attack?'
              data={yesNoDontKnowOption}
              answer={answer.heart_attack}
              onParentChange={handleAnswerChange}
            />
          </div>
          <div className='health-background__q5'>
            <Checkbox
              nodeId='daily_heart_pain'
              key='single_checkbox_7'
              question='5. Do you have <strong>daily</strong> heart pain/discomfort and/or other symptoms related to your heart?'
              data={dailyHeartPainOption}
              answer={answer.daily_heart_pain}
              onParentChange={handleAnswerChange}
            />
          </div>
          <div className='health-background__q6'>
            <Checkbox
              nodeId='physical_disability'
              key='single_checkbox_8'
              question='6. Do you have upper or lower limb loss, difficulty with manual dexterity, coordination, or a physical disability that requires the use of an assistance device (e.g., low vision aid, bath/shower or toilet aid, cane/walker, or scooter/wheelchair)?'
              data={yesNoOption}
              answer={answer.physical_disability}
              onParentChange={handleAnswerChange}
            />
          </div>

          <ButtonGroup variant='contained' orientation='vertical' className={isMobile ? 'survey-form__links' : 'survey-form__links survey-form__links--desktop'} aria-label='vertical contained button group'>
            <Button
              className='data-donation__button'
              color='primary'
              disabled={!canSubmit}
              onClick={handleSubmit}
            >
              Save changes
            </Button>
          </ButtonGroup>
        </Section>
      </MainTemplate>
    );
  }
}

export default HealthBackground;
