import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { fetchResearchArticles } from '../../redux/actions/articleActions';
import Section from '../../components/Section';
import ArticleCard from '../../components/ArticleCard';
import updateImagePath from '../../util/getImagePath';
import MainTemplate from '../../templates/MainTemplate';
import SearchBar from '../../components/SearchBar';

const LibrarySearch = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const urlParams = new URLSearchParams(props.location.search);
  const searchTerm = urlParams.get('s');
  const [loading, setloading] = React.useState(true);
  const searchArticles = useSelector(state => state.article.searchArticles);

  useEffect(() => {
    if( !searchTerm ) {
      history.push('/library');
    }
    dispatch(fetchResearchArticles(searchTerm));
    setloading(false);
  }, [searchTerm]);

  const handleSearchBar = (searchValue) => {
    if ( searchValue ) {
      history.push(`/library/search?s=${searchValue}`);
    } else {
      cancelGoBackClick();
    }
  };

  const cancelGoBackClick = () => {
    history.push('/library');
  }

  return (
    <MainTemplate title='Library' isWide={true} hasPadding={true}>
      <Section className='section library__activity-research'>
        <SearchBar
          cancelButton={true}
          searchTerm={searchTerm}
          handleSearch={handleSearchBar}
          onCancelClick={cancelGoBackClick}
        />
        <Typography color='textSecondary' className='library__result-title'>
          Your search result for "{searchTerm}"
        </Typography>
        <CircularProgress
          className={ loading ? 'library__loading-circle' : 'library__loading-circle library__hidden' }
        />
        {searchArticles.length === 0 &&
          <Typography
            align="left"
            variant='h5'
            component='h5'
            className={ loading ? 'library__hidden' : '' }
          >
            Did not find any articles
          </Typography>
        }
        <Grid container spacing={2}>
        { searchArticles.map((article, idx) => (
          <Grid
            key={idx}
            className={ loading ? 'library__hidden' : '' }
            item
            xs={12}
            md={4}
          >
            <ArticleCard image={updateImagePath(article.thumbnail)} title={article.title} link={'/library/article/' + article.slug} type={article.type} />
          </Grid>
        ))}
        </Grid>
      </Section>
    </MainTemplate>
  );
};

export default LibrarySearch;
