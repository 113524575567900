import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery,
  useTheme,
  Icon,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import ArticleCard from './ArticleCard';
import updateImagePath from '../util/getImagePath';

function ArticleList({
  categories,
  description,
  first,
  collapseBool,
}) {
  const [collapseSwitches, setCollapseSwitches] = React.useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (collapseSwitches.length === 0) {
    categories.map((category) => {
      if (category.subCategories) {
        return setCollapseSwitches(collapseSwitches => ({ ...collapseSwitches, [category.id]: false }));
      }
    });
  }

  function handleClick(categoryId) {
    setCollapseSwitches(collapseSwitches => ({ ...collapseSwitches, [categoryId]: !collapseSwitches[categoryId] }));
  }

  return (
    <div className={`article-list ${first ? 'article-list--first' : 'article-list--second'} ${collapseBool && 'collapse'}`}>
      <Typography align='center' color='textSecondary' className='article-list__description'>
        {description}
      </Typography>
      {categories.map(category => (
        <div key={category.id}>
          {!first &&
            <Typography
              component='h4'
              color={`${first ? 'textPrimary' : 'textSecondary'}`}
            >
              {category.name}
            </Typography>
          }
          {first &&
            <div className={`article-list__heading ${collapseSwitches[category.id] && 'collapse'}`}>
              <Typography
                component='h4'
                color={`${first ? 'textPrimary' : 'textSecondary'}`}
              >
                {category.name}
              </Typography>
              {first &&
                <IconButton edge='start' className='black-text' aria-label='expand' onClick={() => handleClick(category.id)}>
                  <Icon>expand_more</Icon>
                </IconButton>
              }
            </div>
          }
          {(category.articles.length != 0) &&
            <Grid container spacing={2} className={`article-list__grid ${collapseSwitches[category.id] && 'collapse'}`}>
              {category.articles.map(article =>
                <Grid key={article.title} item xs={12} sm={6} md={4}>
                  <ArticleCard image={updateImagePath(article.thumbnail)} title={article.title} link={`/library/article/${article.slug}`} type={article.type} />
                </Grid>
              )}
            </Grid>
          }
          {category.subCategories &&
            <ArticleList first={false} categories={category.subCategories} collapseBool={collapseSwitches[category.id]} />
          }
        </div>
      ))}
    </div>
  );
}

ArticleList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  description: PropTypes.string,
  first: PropTypes.bool.isRequired,
  collapseBool: PropTypes.bool,
};

ArticleList.defaultProps = {
  description: '',
  collapseBool: false,
};

export default ArticleList;
