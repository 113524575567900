import React from 'react';
import Navbar from '../components/Navbar';
import {
  useMediaQuery,
  useTheme,
  Box,
} from '@material-ui/core/';

const MainTemplate = ({
  title,
  hasGlobal=false,
  isFixed=false,
  hasBack=false,
  hasDrawer=true,
  hasPadding=false,
  isWide=false,
  hideNavBarInMobile=false,
  className='',
  children,
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box display="block" displayPrint="none">
        <Navbar color='primary' hidden={!hasGlobal} hasBack={hasBack} hasDrawer={hasDrawer} title={title} hideNavBarInMobile={hideNavBarInMobile} />
      </Box>
      <main id='main' className={`${isMobile ? `main-template${hideNavBarInMobile ? ' main-template--hide-navbar' : ''}` : `main-template-desktop${isFixed ? ' main-template-desktop--fixed' : ''}`}`}>

        {isMobile ?
          children
        :
          <div className={`${className} main-template-desktop__container${hasPadding ? ' main-template-desktop__container--padding' : ''}${isWide ? ' main-template-desktop__container--wide' : ''}`}>
            {children}
          </div>
        }
      </main>
    </>
  );
}

export default MainTemplate;
