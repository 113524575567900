import React from 'react';
import {
  useTheme,
  useMediaQuery,
  Dialog,
  Slide
} from '@material-ui/core';
import BodyModal from '../components/BodyModal';

function BodyDialog({ onAnswer }) {

  const [open, setOpen] = React.useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => {
    setOpen(true);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  if (isMobile) {
    return (
      <Dialog fullScreen open={open}>
        <BodyModal onAnswer={onAnswer} />
      </Dialog>
    );
  } else {
    return (
      <Dialog className='body-dialog-desktop' open={open}>
        <div className='body-dialog-desktop__container'>
          <BodyModal onAnswer={onAnswer} />
        </div>
      </Dialog>
    );
  }
};

export default BodyDialog;