import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAnswer, fetchMessages, checkPendingMessages, fetchMoreMessages } from '../redux/actions/chatActions';
import {
  useMediaQuery,
  useTheme,
} from '@material-ui/core/';
import { useLocation, useHistory } from 'react-router-dom';
import MainTemplate from '../templates/MainTemplate';
import ChatKeyboard from '../components/chat/ChatKeyboard';
import ChatMsgs from '../components/chat/ChatMsgs';
import GlobalChatAction from '../components/GlobalChatAction';
import { triggerWellnessCheckFlow, triggerHeartCheckFlow } from '../redux/actions/chatActions';

export default function Chat() {
  const chat = useSelector(state => state.chat);
  const dispatch = useDispatch();
  const { messages, action, currentNode } = chat;
  const [answerNode, setAnswerNode] = useState(-1);

  const history = useHistory();
  const location = useLocation();

  async function triggerHeartCheck() {
    await dispatch(triggerHeartCheckFlow());
  };

  async function triggerWellnessCheck() {
    await dispatch(triggerWellnessCheckFlow());
  }

  useEffect(() => {
    if (!location) {
      return;
    }
    const params = new URLSearchParams(location.search);
    const trigger = params.get('trigger')

    if (trigger && trigger === 'heartCheck') {
      triggerHeartCheck();
      history.replace('/chat');
    } else if (trigger && trigger === 'wellnessCheck') {
      triggerWellnessCheck();
      history.replace('/chat');
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchMessages());
      dispatch(checkPendingMessages());
    })();
  }, []);

  const onAnswer = answer => {
    setAnswerNode(currentNode.id);
    dispatch(setAnswer(currentNode, answer));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MainTemplate title='at heart' hasGlobal={true} isFixed={!isMobile} hasPadding={!isMobile} className="chat-page">
      <div id='chat-box' className={isMobile ? 'chat__container': 'chat-desktop__container'}>
        {!isMobile && <GlobalChatAction />}
        <ChatMsgs messages={messages} />
        <ChatKeyboard action={action} onAnswer={onAnswer} />
      </div>
    </MainTemplate>
  );
}
