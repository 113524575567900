import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ChatMsg from './msg/ChatMsg';
import { fetchMoreMessages } from './../../redux/actions/chatActions';
import ChatMessageTypingIndicator from './ChatMessageTypingIndicator';


const ChatMsgs = ({ messages }) => {
  const [chatMsgsHeight, setChatMsgsHeight] = useState(0);
  const dispatch = useDispatch();
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const botIsTyping = useSelector(state => state.chat.botIsTyping);

  const adjustKeyboardHeight = () => {
    const chatKeyboardHeight = document.querySelector('.chat__keyboard')?.offsetHeight ?? 0;
    const headerHeight = document.querySelector('header').offsetHeight;
    setChatMsgsHeight(`calc(100vh - ${chatKeyboardHeight + headerHeight}px)`);
  };

  useEffect(adjustKeyboardHeight);

  const handleScroll = async (e) => {
    if (e.target.classList.contains('chat-messages') && e.target.scrollTop === 0 && !isFetchingMessages) {
      setIsFetchingMessages(true);
      await dispatch(fetchMoreMessages());
      setIsFetchingMessages(false);
    }
  };

  return (
    <div className="chat-messages" style={{ height: chatMsgsHeight }} onScroll={handleScroll}>
      {messages.map((message, idx) => (
        <ChatMsg key={idx} msg={message} />
      ))}
      {botIsTyping &&
        <ChatMessageTypingIndicator />
      }
    </div>
  );
};

ChatMsgs.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default ChatMsgs;
