import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

function ArticleCard({
  image,
  title,
  subtitle,
  link,
  type,
}) {
  const history = useHistory();
  const location = useLocation();

  const typeIconImage = {
    video: '/images/video_type_icon.png',
    audio: '/images/audio_type_icon.png',
  }

  const ArticleLink = () => (
    history.push(link, { from: location } )
  );

  return (
    <Card className='article-card'>
      <CardActionArea className='article-card__content' onClick={ArticleLink}>
        <CardMedia
          className='article-card__image'
          component='img'
          alt='Article image'
          image={image}
          title={title}
        />
        <CardContent className='article-card__title'>
          <Typography className='article-card__header' variant='subtitle1'>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant='subtitle2' color='textSecondary'>
              {subtitle}
            </Typography>
          )}
        </CardContent>
        {typeIconImage[type]
          && (
          <CardMedia
            className='article-card__icon'
            component='img'
            alt='article type icon'
            image={typeIconImage[type]}
          />
          )
        }
      </CardActionArea>
    </Card>
  );
}

ArticleCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default ArticleCard;
