import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
} from '@material-ui/core';
import Section from '../components/Section';
import MainTemplate from '../templates/MainTemplate';
import ButtonListItem from '../components/ButtonListItem';

const About = () => {
  const history = useHistory();

  const handleAtHeart = () => {
    history.push('/about/at-heart');
  }
  const handleTerms = () => {
    history.push('/about/terms');
  }
  return (
    <MainTemplate title='About' hasBack={true} hasDrawer={false}>
      <Section>
        <List className='list-view__list'>
          <ButtonListItem onClick={handleAtHeart} text='About at heart' className='black-text' hasIcon />
          <ButtonListItem onClick={handleTerms} text='Terms and conditions' className='black-text' hasIcon />
        </List>
      </Section>
    </MainTemplate>
  );
}
export default About;
