import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Section from '../components/Section';

const DesktopSignInTemplate = ({ picture, left, right, children }) => (
  <div className='login-desktop'>
    <Grid container className='login-desktop__grid'>
      <Grid item xs={left} className='login-desktop__grid--item'>
        <img className='login-desktop__logo' src={picture} alt='hp-logo' />
      </Grid>
      <Grid item xs={right} className='login-desktop__grid--item login-desktop__grid--scroll'>
        <div className='login-desktop__content'>
          {children}
        </div>
      </Grid>
    </Grid>
  </div>
);

export default DesktopSignInTemplate;