import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery,
  useTheme,
  Grid,
} from '@material-ui/core';
import SliderCard from '../../SliderCard';
import updateImagePath from '../../../util/getImagePath';

const ChatSlider = ({ msg }) => {
  const { data } = msg;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <div className='slider__container'>
        <div className='slider__row'>
          {
            data.slides.map((slide)=>
              <SliderCard key={slide.text} title={slide.text} image={updateImagePath(slide.img)} marks={slide.marks} value={data.choices[slide.id]} isDisabled={true} />
            )
          }
        </div>
      </div>
    );
  } else {
    return (
      <div className='slider-desktop__container'>
        <Grid container spacing={2}>
          {
            data.slides.map((slide)=>
              <Grid key={slide.text} item xs={6}>
                <SliderCard title={slide.text} image={updateImagePath(slide.img)} marks={slide.marks} value={data.choices[slide.id]} isDisabled={true} />
              </Grid>
            )
          }
        </Grid>
      </div>
    );
  }
};

ChatSlider.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default ChatSlider;
