const discomfortOption = [
  {
    "id": "dull",
    "text": "Dull",
  },{
    "id": "heavy",
    "text": "Heavy",
  },{
    "id": "tight",
    "text": "Tight",
  },{
    "id": "pressure",
    "text": "Pressure",
  },{
    "id": "aching",
    "text": "Aching",
  },{
    "id": "squeezing",
    "text": "Squeezing",
  },{
    "id": "crushing",
    "text": "Crushing",
  },{
    "id": "gripping",
    "text": "Gripping",
  },{
    "id": "other",
    "text": "Other",
  },{
    "id": "no_pain_discomfort",
    "text": "No pain/discomfort",
    "type": "solo",
  }
];

export {
  discomfortOption,
};

export default {
  discomfortOption,
};