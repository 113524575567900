import React, { useState } from 'react';
import {
  InputLabel,
  FormControl,
  Select,
  Typography,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Selects = ({ question, data, name, id, label, nodeId, answer, onParentChange }) => {

  let defaultValue = answer ?? '';

  const [state, setState] = useState(defaultValue);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    setState(event.target.value);
    onParentChange(event, event.target.value, nodeId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const labelId = ['outlined', id].join('-');

  return (
    <div className="question-node select-question" id={nodeId}>
      <Typography variant='subtitle1' className='data-donation__question'>
        {question}
      </Typography>
      <div className="select-group">
        <FormControl variant="outlined" className='data-donation__form--select'>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            name={name}
            value={state}
            onChange={handleChange}
            label={label}
            labelId={labelId}
            id={id}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <MenuItem value=''>
              <em>N/A</em>
            </MenuItem>
            {data.map((c) => (
              <MenuItem key={c.text} value={c.value}>{c.text}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

Selects.propTypes = {
  nodeId: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.string,
  onParentChange: PropTypes.func,
};

Selects.defaultProps = {
  name: '',
  label: '',
  id: '',
  data: [],
  answer: '',
};

export default Selects;
