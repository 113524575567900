export default `
<style data-jss="">
h1 {
  color: #212121;
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;
  line-height: 33px;
  margin-top: 40px;
  margin-bottom: 24px;
}

h2 {
  color: #212121;
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0;
  margin-top: 40px;
}

p {
  color: #212121;
  font-size: 16px;
  line-height: 26px;
}

ul {
  padding-inline-start: 0;
  list-style-type: none;
}

li h3 {
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

li p {
  color: #212121;
  font-size: 14px;
  line-height: 23px;
  margin-right: 5px;
}

li img {
  border: 2px solid #C1D830;
  border-radius: 50%;
  height: 120px;
  object-fit: cover;
  object-position: center;
  width: 120px;
}

.pac-border {
  border: 2px solid #EC008C;
}

.acknowledge-border {
  border: 2px solid #3393A4;
}

.annotation {
  font-size: 14px;
}

.head-image .annotation {
  font-size: 12px;
  letter-spacing: 0;
}

.hhf-logo {
  max-width: 60%;
}

.thousand-logo {
  max-width: 50%;
}

@media (max-width: 959px) {
  .head-image img {
    width: calc(100% + 48px);
    margin: -24px;
    padding-bottom: 14px;
  }
}

@media (min-width: 960px) {
  p {
    margin-bottom: 30px;
    margin-top: 0;
  }

  .article-content {
    max-width: 670px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ul li {
    flex: 1 0 50%;
    max-width: calc(150% - 30px);
  }

  .head-image {
    max-width: 330px;
    float: right;
    padding-left: 40px;
  }

  .head-image img {
    width: 97%;
  }

  .head-image .annotation {
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    text-align: left;
  }
}
</style>
<div class='article-content'>
<div class='head-image'>
<img src='/images/about_image.png' alt='about_featured_image' />
<p class='annotation'>
Pedie Wolfond, Heart 56, from Find your Heart Book 1, 2004<br>
Image courtesy of Lonsdale Gallery<br>
© Pedie Wolfond, 2018<br>
</p>
</div>
<h1>About <strong>at heart</strong></h1>
<p>
<span>'at heart' v1.0</span><br/>
<span>Last updated: July 1, 2021</span><br/>
<span>Contact: <a href="tel:+18885662515">1-888-566-2515</a></span>
</p>
<p><strong>at heart</strong> is a self-management web app designed to improve the health and well-being of women with heart disease.
The three main features of the web app are the Heart Check, Wellness Check and the Library. <strong>at heart</strong> uses an interactive chat bot named ‘Holly’ to manage its content and your conversations.
A chatbot is a software application used to conduct an on-line text conversation. Although experts have been involved in developing the conversation, you will not have direct contact with them in the web app.
</p>
<p>
Heart Check compares your current heart pain/discomfort symptoms to those experienced during your last heart event (i.e., when you were treated in the emergency department and/or hospital). Based on your current symptoms, the chat bot will ask you to seek assessment or it will give you access to personalized self-management and well-being information.
</p>
<p>
In the Wellness Check, you will ‘score’ the degree that heart pain/discomfort symptoms interfere with various aspects of your everyday life: mood, walking, relations with others, sleep, life satisfaction, paid and unpaid work. Based on your score, focused readings and self-management advice will be suggested and provided for you by ‘Holly’.
You may also browse the Library for educational articles with information on heart disease in women and self-management strategies that address physical, mental, emotional and spiritual health and wellbeing. There are also videos of women who share their experiences of living with heart disease.
</p>
<p>
The <strong>at heart</strong> investigative team includes recognized experts in cardiac (heart) pain, women’s heart health, Indigenous health, knowledge transfer and exchange, apps/web-based solutions, and statistics.
Our team also includes a Patient Advisory Committee that guides our project with collective insights from women who live with heart disease.
</p>
</div>
<h2><strong>at heart</strong> investigative team</h2>
<ul>
<li>
<img src='/images/about/monicaP.png'>
<h3>Parry, Monica (PI)</h3>
<p>Dr. Monica Parry is a Nurse Practitioner (Adult) with over 30 years of cardiovascular clinical experience. She is a member of the Banting & Best Diabetes Centre, and Investigator with Diabetes Action Canada, and is Core Faculty and a Collaborating Investigator with the Collaborative Program in Resuscitation Science, Faculty of Medicine, University of Toronto.</p>
</li>
<li>
<img src='/images/about/hanceC.png'>
<h3>Hance Clarke (Co-PI)</h3>
<p>Dr. Hance Clarke is the Director of Pain Services and the Medical Director of the Pain Research Unit at Toronto General Hospital. He is appointed to the Institute of Medical Sciences at the University of Toronto. His research interests include identifying novel acute pain treatments following major surgery and identifying factors in the transition of acute to chronic pain.</p>
</li>
<li>
<img src='/images/about/annKB.png'>
<h3>Dr. Ann Kristin Bjørnnes</h3>
<p>Dr. Ann Kristin Bjørnnes is an Associate Professor in the Department of Nursing and Health Promotion at the Faculty of Health Sciences at OsloMet in Norway. Her research interests are in gender and women perspectives, chronic pain, pain research, women’s health, and self-management support using both quantitative and qualitative research methods.</p>
</li>
<li>
<img src='/images/about/josephC.png'>
<h3>Dr. Joseph Cafazzo</h3>
<p>Dr. Joseph Cafazzo is a Canadian biomedical engineer, educator, and researcher. He is known for his work on the design of health technologies and how they facilitate patient self-care of complex chronic conditions. Dr. Cafazzo is the Executive Director of Healthcare Human Factors and the Wolfond Chair in Digital Health at the University Health Network.</p>
</li>
<li>
<img src='/images/about/lynnC.png'>
<h3>Lynn Cooper</h3>
<p>Lynn Cooper BES has applied the knowledge and skills gained during more than 25 years of lived experience with persistent pain and volunteering in pain related organizations to become recognized as a national/international pain community leader.
She is an advocate, innovator and spokesperson for improved pain care, and a professional/peer educator and mentor towards success in chronic pain management. Lynn is the pain education and research advisor of the Canadian Injured Workers’ Alliance and a steering committee member of the SPOR Chronic Pain Network.  She served as president of the former Canadian Pain Coalition.</p>
</li>
<li>
<img src='/images/about/abidaD.png'>
<h3>Abida Dhukai</h3>
<p>Abida Dhukai is a Nurse Practitioner and a PhD student at the Lawrence S. Bloomberg Faculty of Nursing. She is a member of the Canadian Women’s Heart Health Alliance engaged in the knowledge translation working group to develop strategies to disseminate knowledge of women’s heart health.</p>
</li>
<li>
<img src='/images/about/paulaH.png'>
<h3>Dr. Paula Harvey </h3>
<p>Dr. Paula Harvey is a Cardiologist/Physician-in-Chief in the Department of Medicine and the Director of the Cardiovascular Research Program at Women’s College Hospital. She is also the F.M. Hill Chair in Women’s Academic Medicine with research interests that include lifestyle interventions, cardio-rheumatology, atherosclerosis and CV disease in women. </p>
</li>
<li>
<img src='/images/about/joelK.png'>
<h3>Dr. Joel Katz</h3>
<p>Dr. Joel Katz is a Distinguished Research Professor of psychology and Tier 1 Canada Research Chair in Health Psychology at York University. He is the Research Director of the Pain Research Unit in the Department of Anesthesia and Pain Management at the Toronto General Hospital in addition to serving as a Professor in the Department of Anesthesiology and Pain Medicine at the University of Toronto.</p>
</li>
<li>
<img src='/images/about/chitraL.png'>
<h3>Dr. Chitra Lalloo </h3>
<p>Dr. Chitra Lalloo is a Research Associate at The Hospital for Sick Children and Assistant Professor at the University of Toronto. She is the research lead for iCanCope with Pain, a digital pain self-management platform for adolescents and young adults with chronic pain, arthritis, sickle cell disease, post-operative pain, or neurofibromatosis.</p>
</li>
<li>
<img src='/images/about/maritL.png'>
<h3>Dr. Marit Leegaard</h3>
<p>Dr. Marit Leegaard is a Professor in the Department of Nursing and Health Promotion at the Faculty of Health Sciences at OsloMet in Norway. Her research interests include pain assessment, postoperative pain, and interprofessional collaboration.</p>
</li>
<li>
<img src='/images/about/franceL.png'>
<h3>Dr. France Légaré </h3>
<p>Dr. France Légaré is a family physician and a Professor in the Department of Family Medicine and Emergency Medicine at Laval University.
She holds the Tier 1 title of the Canada Research Chair in Shared Decision Making and Knowledge Translation and is the Scientific Co-Director of the Canadian Cochrane Network.</p>
</li>
<li>
<img src='/images/about/judithM.png'>
<h3>Judith McFetridge-Durdle</h3>
<p>Dr. France Légaré is a family physician and a Professor in the Department of Family Medicine and Emergency Medicine at Laval University.
She holds the Tier 1 title of the Canada Research Chair in Shared Decision Making and Knowledge Translation and is the Scientific Co-Director of the Canadian Cochrane Network.</p>
</li>
<li>
<img src='/images/about/michaelM.png'>
<h3>Dr. Michael McGillion </h3>
<p>Dr. Michael McGillion is an Associate Professor, a University Scholar and holds the Heart and Stroke Foundation/Michael G. DeGroote Endowed Chair of Cardiovascular Nursing Research at McMaster University. He is also a Scientist at the Population Health Research Institute in Hamilton, Ontario.
His research program focuses on remote automated monitoring and virtual recovery support models for people recovering from cardiac, vascular and other forms of surgery. Dr. McGillion is Co-Chair of the Heart and Stroke Foundation Pan-Canadian Council on Mission: Priorities, Advice, Science and Strategy (CoMPASS). </p>
</li>
<li>
<img src='/images/about/colleenN.png'>
<h3>Dr. Colleen Norris </h3>
<p>Dr. Colleen Norris is a Professor at the University of Alberta. She is the Scientific Director for the Cardiovascular Health and Stroke Strategic Clinical Network (SCN) and has collaborated with researchers locally, nationally and internationally from various health care disciplines in Women's Heart Health and sex and gender health outcomes research.  </p>
</li>
<li>
<img src='/images/about/roseP.png'>
<h3>Rose Patterson</h3>
<p>Rose Patterson is a Nurse Practitioner in Addictions and Mental Heath. She worked previously as a Nurse Practitioner at Anishnawbe Health in Toronto and has 7 years experience working with indigenous and vulnerable populations. Anishnawbe Health Toronto promotes Traditional Aboriginal practices and approaches, which are reflected in the design of its programs and services.</p>
</li>
<li>
<img src='/images/about/louiseP.png'>
<h3>Louise Pilote</h3>
<p>Dr. Pilote is a clinician scientist and tenured Professor of Medicine at McGill University.  She has held a James McGill chair since 2008 and is the former Director of the Division of General Internal Medicine at McGill University. Her research interests include cardiovascular epidemiology, outcomes research and health services research. </p>
</li>
<li>
<img src='/images/about/leahP.png'>
<h3>Leah Pink</h3>
<p>Leah Pink MN, NP-Adult is a Nurse Practitioner in the Chronic and Transitional Pain Service and in the Pain in Pregnancy Program at Mount Sinai Hospital in Toronto. Leah completed her undergraduate degree in nursing in 2000, her Master of Nursing in 2005 and the Post-Masters Nurse Practitioner program in 2014, all at the University of Toronto. She also completed the Opioid Dependence Treatment Certificate Program at the Centre for Addiction and Mental Health in 2020. While chronic pain management has been her focus since 2008, she has also worked in high risk pregnancy, critical care and nursing research at the University of Toronto. Leah is cross-appointment as an Adjunct Lecturer at the Lawrence S. Bloomberg Faculty of Nursing, University of Toronto.</p>
</li>
<li>
<img src='/images/about/jenniferP.png'>
<h3>Dr. Jennifer Price </h3>
<p>Dr. Jennifer Price is an advance practice nurse at Women’s College Hospital’s Women's Cardiovascular Health Initiative. Her research interests include cardiac rehabilitation and women and heart disease, and interventions to help women make healthy lifestyle choices. </p>
</li>
<li>
<img src='/images/about/jenniferS.png'>
<h3>Dr. Jennifer Stinson </h3>
<p>Dr. Jennifer Stinson is a Nurse Clinician-Scientist in Child Health Evaluative Sciences and a Nurse Practitioner in the Chronic Pain Program at the Hospital for Sick Children. She is also a Professor at the Lawrence S. Bloomberg, Faculty of Nursing, University of Toronto. Dr. Stinson’s research interests are in the area of pain and symptom management and the use of e-health (internet) and m-health (mobile phones) technologies. </p>
</li>
<li>
<img src='/images/about/charlesV.png'>
<h3>J. Charles Victor</h3>
<p>J. Charles Victor is the Senior Director of Strategic Partnerships and External Services at the Institute for Clinical Evaluative Sciences. He is also a lecturer at the Institute of Health Policy, Management and Evaluation at the University of Toronto.</p>
</li>
<li>
<img src='/images/about/judyW.png'>
<h3>Dr. Judy Watt-Watson </h3>
<p>Dr. Judy Watt-Watson is a Professor Emeritus at the University of Toronto Lawrence S. Bloomberg Faculty of Nursing and was Inaugural Executive Director of their Centre for Professional Development. She is President of the Canadian Pain Society, and a member of the UofT Centre for the Study of Pain Executive Committee. </p>
</li>
<li>
  <img src='/images/about/arland.png'>
  <h3>Arland O'Hara</h3>
  <p>Arland O'Hara has been a Research Officer at the Lawrence S. Bloomberg Faculty of Nursing since 2017, having worked at OISE/UofT, The Hospital for Sick Children and York University. Arland specializes in qualitative data collection and analysis and over the past 25 years has managed projects in bullying, emotion regulation, mother-child relationships, math curriculum, sleep, and women's cardiac health. She has most enjoyed working on health interventions using apps and mobile devices and is excited about the future of <strong>at heart</strong>. </p>
  </li>
</ul>
<h2><strong>at heart</strong> patient advisory committee</h2>
<ul>
<li>
<img class='pac-border' src='/images/about/christineF.png'>
<h3>Christine Faubert</h3>
<p>Christine Faubert has focussed on healthcare (nurse) and business for more than 35 years.  She had experience in Intensive Care and Oncology before becoming a Clinical Research Associate conducting clinical research trials. Christine had HOCM / HCM chest pain in 2014 and in 2015 had Septal Myectomy Surgery. In May 2017 Christine had a stroke resulting in conductive aphasia and in 2019 had radiation treatment. </p>
</li>
<li>
<img class='pac-border' src='/images/about/vincenzaSD.png'>
<h3>Vincenza Spiteri DeBonis</h3>
<p>Vincenza Spiteri DeBonis is a retired organizational development specialist and educator in the healthcare sector.  She actively engages in promoting health equity.  Vincenza had a heart attack of unknown origin in 2016 and continues to manage her cardiac pain.</p>
</li>
<li>
<img class='pac-border' src='/images/about/donnaH.png'>
<h3>Donna Hart</h3>
<p>In December 2014 Donna was diagnosed with heart failure, six weeks later she was listed for a heart transplant; May 2015, her life was saved when she received a new heart. Donna has been married for over thirty-five years and has two adult children.
She is a registered social worker with a Bachelor of Arts degree from the University of Waterloo. Donna’s career focused on working with people with disabilities and assisting them to lead productive and fuller lives. She is excited and honoured to be a patient partner for at heart.</p>
</li>
<li>
<img class='pac-border' src='/images/about/nicoleN.png'>
<h3>Nicole Nickerson</h3>
<p>Nicole Nickerson was 30 years old when she lost her 25- year old sister to a MI. She herself went on to have a NONSTEMI the following year and another at the age of 34 while pregnant with her second child on the anniversary of her sisters death. After witnessing firsthand the disparity in cardiac health care between not only sex and gender but geographically, she has become a strong advocate for both woman’s heart health and equal access and outcomes for rural communities.</p>
</li>
</ul>
<h2><strong>at heart</strong> acknowledgements</h2>
<ul>
<li>
<img class='acknowledge-border' src='/images/about/hafsaA.png'>
<h3>Hafsa Ansari </h3>
<p>Hafsa Ansari is a Registered Nurse who completed her Bachelor of Science in Nursing at the Lawrence S. Bloomberg Faculty of Nursing. Throughout her degree, she was involved in the Undergraduate Summer Student Research program under the direction of Dr. Monica Parry. She continues to take part in research projects focusing on the differential illness and pain experiences of women with cardiovascular disease.</p>
</li>
<li>
<img class='acknowledge-border' src='/images/about/faithD.png'>
<h3>Faith Delos-Reyes</h3>
<p>Faith Delos-Reyes is the Program Coordinator for the Women's Cardiovascular Health Initiative (WCHI), a gender specialized Cardiac Rehabilitation and Primary Prevention Program at Women's College Hospital, in Toronto.
She completed a Bachelor of Physical and Health Education at the University of Toronto and is a Registered Kinesiologist and sits as a Committee Member with the College of Kinesiologists of Ontario. She has over 15 years of experience working in cardiac rehab in England and Canada.
She is part of the Avoiding Diabetes After Pregnancy Trial in Moms (ADAPT-M) to develop a life-style prevention program for women who have had gestational diabetes (GDM). She is interested in the role of physical activity in the prevention and management of chronic disease in women.</p>
</li>
<li>
<img class='acknowledge-border' src='/images/about/michelleD.png'>
<h3>Michelle Duong</h3>
<p>Michelle Duong is a nursing student and research assistant at the University of Toronto. She is interested in gaining more knowledge about women's heart health, self-management practices, and the development of evidence-based interventions. </p>
</li>
<li>
<img class='acknowledge-border' src='/images/about/margaretH.png'>
<h3>Margaret Harrington</h3>
<p>Margaret Harrington is completing her undergraduate degree in Kinesiology at the University of Toronto. She is also a research assistant at the Lawrence S. Bloomberg Faculty of Nursing. She is interested in learning about how physical activity can be used to treat and prevent chronic diseases.</p>
</li>
<li>
<img class='acknowledge-border' src='/images/about/sallyT.png'>
<h3>Sally Twin</h3>
<p>Sally Twin graduated from the University of Toronto Lawrence S. Bloomberg Faculty of Nursing June 2020. She holds an Honours BSc. in Biomedical Science from the University of Waterloo. She was a participant of the University of Toronto Work-Study Program under Dr. Monica Parry and created lay summaries to develop content for the app. Her current interests include cardiology and facilitating mixed methods research.</p>
</li>
</ul>
<h2><strong>at heart</strong>’s design and development team</h2>
<img class='hhf-logo' src='/images/hhf.png'>
<p class='annotation'>
Laura Parente<br>
Pia Nyakairu<br>
Jennifer Rosart<br>
</p>
<img class='thousand-logo' src='/images/thousand-plus.png'>
<p class='annotation'>
Jason Van<br>
</p>`;
