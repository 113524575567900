import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Icon,
  Link,
  Divider,
} from '@material-ui/core';

const ButtonListItem = ({
  onClick,
  className,
  text,
  hasIcon,
  hasDivider,
  disableRipple,
}) => (
  <>
    <Link className={className} onClick={onClick}>
      <ListItem button disableRipple={disableRipple}>
        <ListItemText
          primary={text}
        />
        {hasIcon
          && (
            <ListItemSecondaryAction>
              <Icon className='drawer__icon'>
                <img className='drawer__icon--image' src='/images/Drawer__Icon.svg' />
              </Icon>
            </ListItemSecondaryAction>
          )
        }
      </ListItem>
    </Link>
    {hasDivider
      && (
        <Divider />
      )
    }
  </>
);

ButtonListItem.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  hasIcon: PropTypes.bool,
  disableRipple: PropTypes.bool,
};

ButtonListItem.defaultProps = {
  className: '',
  hasIcon: false,
  disableRipple: false,
};

export default ButtonListItem;
