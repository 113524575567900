import React from 'react';
import Navbar from '../components/Navbar';
import PropTypes from 'prop-types';
import {
  useMediaQuery,
  useTheme,
} from '@material-ui/core/';

const DataDonationTemplate = ({ color, textClass, hasBack, hasDrawer, title, buttonGroup, hasPadding=false, isWide=false, children }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let classes = isMobile? 'data-donation' : 'data-donation data-donation--desktop';

  if( buttonGroup === 'bottom' && isMobile) {
    classes += ' button-group__bottom';
  }

  return (
    <>
      <Navbar
        color={color}
        textClass={textClass}
        hasBack={hasBack}
        title={title}
        hasDrawer={hasDrawer}
        hidden />
      <main id='main' className={classes}>
        {isMobile ? 
          children
        :
          <div className={`main-template-desktop__container${hasPadding ? ' main-template-desktop__container--padding' : ''}${isWide ? ' main-template-desktop__container--wide' : ''}`}>
            {children}
          </div>
        }
      </main>
    </>
  );
}

DataDonationTemplate.propTypes = {
  color: PropTypes.string,
  textClass: PropTypes.string,
  hasDrawer: PropTypes.bool,
  hasBack: PropTypes.bool,
  title: PropTypes.string,
  buttonGroup: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

DataDonationTemplate.defaultProps = {
  color: 'primary',
  leftButton: 'arrow_back'
};

export default DataDonationTemplate;
