import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CardContent,
  CardMedia,
  Typography,
  Fab,
  Box,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

function CheckInEntry({
  data,
}) {
  const { type, entry, status, visitedEr, other } = data;
  const history = useHistory();

  const typeIconImage = {
    green: '/images/green_check_in_icon.png',
    yellow: '/images/yellow_check_in_icon.png',
    red: '/images/red_check_in_icon.png',
    wellness: '/images/wellness_check_in_icon.png',
    note: '/images/note_check_in_icon.png',
  }

  const handleCheckinsNote = () => {
    history.push('/checkins/note/' + data.uuid);
  }

  return (
    <div className='check-in-entry'>
      <div className='check-in-card-entry__row'>
        <CardMedia
          className='check-in-card-entry__icon'
          component='img'
          alt='article type icon'
          image={typeIconImage[type] ? typeIconImage[type] : typeIconImage[status]}
        />
        <CardContent className='check-in-card-entry__card-content'>
          {(type === 'note') &&
            <div className='check-in__fab'>
              <Box displayPrint="none">
                <Fab onClick={handleCheckinsNote} color='primary' aria-label='edit'>
                  <EditIcon />
                </Fab>
              </Box>
            </div>
          }
          <Typography className='check-in-card-entry__header' variant='subtitle1'>
            {(type === 'heart') && 'Heart check'}
            {(type === 'wellness') && 'Wellness check'}
            {(type === 'note') && 'Note'}
          </Typography>
          <Typography className='check-in-card-entry__description'>
            {(status === 'green') && 'You are in the clear, no symptoms reported!'}
            {(status === 'yellow') && 'You were asked to follow-up with your primary care provider because you reported:'}
            {(status === 'red') && 'You were asked to call 911 and notify a family member or friend because you reported:'}
            {(type === 'wellness') && 'How you’ve been doing in the following aspects of your life. 0 means you’re not doing well, 10 means you’re doing awesome!'}
            {(type === 'note') && data.title}
          </Typography>
          {(status === 'yellow' || status === 'red') &&
            entry.map((e, index) => (
              <Typography key={index} className='check-in-card-entry__content'>
                {e.title} - {e.text}
              </Typography>
            ))}
          {visitedEr &&
            <Typography className='check-in-card-entry__sub-content'>
              {(visitedEr === 'er-currently' || visitedEr === 'er-done' || visitedEr === 'er-omw') && 'You visited the ER'}
              {(visitedEr === 'er-no') && 'You did not visit the ER'}
            </Typography>
          }
          {(type === 'wellness') &&
            entry.map((e, index) => (
              <Typography key={index} className='check-in-card-entry__content'>
                {e.title} - <Typography className='check-in-card-entry__value' component='span'>{e.value}</Typography>
              </Typography>
            ))
          }
          {other && 
            <Typography className='check-in-card-entry__sub-content'>
              Note: {other}
            </Typography>
          }
          {(type === 'note') &&
            <Typography className='check-in-card-entry__content'>
              {data.content}
            </Typography>
          }
        </CardContent>
      </div>
    </div>
  );
}

export default CheckInEntry;
