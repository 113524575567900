// TODO: This may need to be cleaned up

import React from 'react';
import {
  Fab,
  Icon,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';


const ChatSendButton = ({ onClick, type, text, disabled }) => {
  let button = null;

  switch (type) {
    case 'send': {
      button = (
        <Fab className='chat-send-container__send' color='secondary' aria-label='send' onClick={onClick} disabled={disabled}>
          <Icon className=''>arrow_upward</Icon>
        </Fab>
      );
      break;
    }
    case 'confirm': {
      button = (
        <Button className='chat-send-container__confirm' variant="contained" color="secondary" onClick={onClick} disabled={disabled}>
          {text}
        </Button>
      );
      break;
    }
    default:
      break;
  }

  return button;

  // return (
  //   <div className={type === 'send' ? 'chat-send-container chat-send-container__bottom-line' : 'chat-send-container chat-send-container__top-line'}>
  //     {button}
  //   </div>
  // );
};

ChatSendButton.defaultProps = {
  text: '',
  type: 'send',
}

ChatSendButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  type: PropTypes.string,
};

export default ChatSendButton;
