import React from 'react';
import {
  Typography,
  FormControl,
  TextField,
} from '@material-ui/core';
import DatePicker from '../../../components/Datepicker';
import PropTypes from 'prop-types';

const TextInput = ({ question, type, suffix, id, nodeId, answer, singleLine, onParentChange }) => {

  const handleChange = (event) => {
    onParentChange(event, event.target.value, nodeId);
  };

  return(
  <>
    <div className="question-node textinput-question" id={nodeId}>
      <Typography className='data-donation__question' variant='subtitle1'>
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </Typography>
      <FormControl variant="outlined" className='data-donation__form--input'>
        {type === 'datepicker'
          && (
          <div className='data-donation__date-picker'>
            <TextField
              className='data-donation__input-field'
              id={id}
              type="date"
              defaultValue={answer}
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          )
        }
        {(type === 'textinput' || type === 'numberinput')
          && (
          <TextField
            className='data-donation__input-field'
            id={id}
            multiline={!singleLine}
            rowsMax={5}
            defaultValue={answer}
            onChange={handleChange}
            type={type === 'numberinput' ? 'number' : 'text'}
          />
          )
        }
      </FormControl>
    </div>
  </>
  );
};

TextInput.propTypes = {
  question: PropTypes.string.isRequired,
  type: PropTypes.string,
  suffix: PropTypes.string,
  id: PropTypes.string,
  nodeId: PropTypes.string.isRequired,
  answer: PropTypes.string,
  singleLine: PropTypes.bool,
  onParentChange: PropTypes.func,
};

TextInput.defaultProps = {
  answer: '',
  singleLine: true,
};

export default TextInput;
