import React from 'react';
import {
  useMediaQuery,
  useTheme,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';

function CheckInRows({
  data,
}) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { type, text, status } = data;
  const typeIconImage = {
    green: '/images/green_check_in_icon.png',
    yellow: '/images/yellow_check_in_icon.png',
    red: '/images/red_check_in_icon.png',
    wellness: '/images/wellness_check_in_icon.png',
    note: '/images/note_check_in_icon.png',
  }

  return (
    <div className='check-in-card__content'>
      <div className='check-in-card__row'>
        <CardMedia
          className='check-in-card__icon'
          component='img'
          alt='article type icon'
          image={typeIconImage[type] ? typeIconImage[type] : typeIconImage[status]}
        />
        <CardContent className={isMobile ? 'check-in-card__title' : 'check-in-card__title check-in-card__title--desktop'}>
          <Typography className='check-in-card__header' variant='subtitle1'>
            {(type === 'heart') && 'Heart check'}
            {(type === 'wellness') && 'Wellness check'}
            {(type === 'note') && data.title}
          </Typography>
          <Typography className={isMobile ? 'check-in-card__text' : 'check-in-card__text check-in-card__text--desktop'} variant='subtitle2' color='textSecondary'>
            {(type === 'note')
              ? data.content
                ? data.content
                : 'Empty'
              : text
            }
          </Typography>
        </CardContent>
      </div>
    </div>
  );
}

export default CheckInRows;
