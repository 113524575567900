import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Button,
  Link,
  Icon,
  FormControlLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import Navbar from '../components/Navbar';
import MainTemplate from '../templates/MainTemplate';
import { withStyles } from '@material-ui/core/styles';
import Section from '../components/Section';
import ButtonListItem from '../components/ButtonListItem';
import { authCheckAction } from '../redux/actions/authActions';
import {
  heartCheckNotificationOn,
  heartCheckNotificationOff,
  wellnessCheckNotificationOn,
  wellnessCheckNotificationOff,
} from '../redux/actions/userActions';

import {
  userSettingsUpdate
} from '../services/heartPainApi/user';

const SettingsSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string()
    .email('Invalid email'),
  heartCheckSwitch: Yup.boolean(),
  wellnessCheckSwitch: Yup.boolean(),
  canReceiveEmailSwitch: Yup.boolean(),
});


function ProfileListener() {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext();
  const user = useSelector(state => state.auth.user);

  // Trigger an auth check so we can refresh the user information on this page
  useEffect(() => {
    dispatch(authCheckAction());
  }, []);

  useEffect(() => {
    setFieldValue('name', user.name);
  }, [user]);

  return null;
}


export default function Settings() {
  const user = useSelector(state => state.auth.user);
  const history = useHistory();

  const { heartCheckNotification, wellnessCheckNotification } = user;

  const [wellnessCheck, setWellnessCheck] = useState(wellnessCheckNotification);
  const [heartCheck, setHeartCheck] = useState(heartCheckNotification);
  const [canReceiveEmail, setCanReceiveEmail] = useState(user.canReceiveEmail);

  const initialValues = {
    name: user.name ?? '',
    email: user.email,
    heartCheckSwitch: heartCheckNotification,
    wellnessCheckSwitch: wellnessCheckNotification,
    canReceiveEmailSwitch: user.canReceiveEmail,
  };

  function handleWellnessCheckSwitch() {
    setWellnessCheck(!wellnessCheck);
  }

  function handleHeartCheckSwitch() {
    setHeartCheck(!heartCheck);
  }

  function handleCanReceiveEmail() {
    setCanReceiveEmail(!canReceiveEmail);
  }

  const handlePasswordChange = () => {
    history.push('/settings/password');
  }

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const result = await userSettingsUpdate({
      data: values,
    });
    window.location.reload();
    if (result) {
      setSubmitting(false);
      resetForm({ values: values });
    }
  };

  return (
    <MainTemplate title="Settings" hasBack hasDrawer={false} className="settings">
      <Section>
        <Typography variant="subtitle1" className="settings__title">
          Edit your name and email
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={SettingsSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, dirty, setFieldValue, values }) => (
            <Form>
              <ProfileListener />
              <Field
                className='settings__input'
                component={TextField}
                name='name'
                type='name'
                label='First Name'
              />
              <Field
                className='settings__input'
                component={TextField}
                name='email'
                type='email'
                label='Email'
                disabled={true}
              />
              <Link className='settings__link black-text' onClick={handlePasswordChange}>
                <Typography className='settings__link-text' variant='subtitle1'>
                  Change your password
                </Typography>
                <Icon className='drawer__icon'>arrow_forward_ios</Icon>
              </Link>

              <Typography variant='subtitle1'>
                Email Notifications
              </Typography>
              <Typography variant='subtitle2'>
                Would you like to receive email reminders from <strong>at heart</strong> when you're overdue for a Heart check or a Wellness check?
              </Typography>
              <div className='settings__switch-row'>
                <Typography className='settings__switch-label'>
                  Heart check (every 7 days)
                </Typography>
                <FormControlLabel
                  control={
                    <Field
                      label="Heart Check Notification Switch"
                      name="heartCheckSwitch"
                      type='checkbox'
                      component={Switch}
                      onChange={handleHeartCheckSwitch}
                      checked={heartCheck}
                    />
                  }
                />
              </div>
              <div className='settings__switch-row'>
                <Typography className='settings__switch-label'>
                  Wellness check (every 15 days)
                </Typography>
                <FormControlLabel
                  control={
                    <Field
                      label="Wellness Check Notification Switch"
                      name="wellnessCheckSwitch"
                      type='checkbox'
                      component={Switch}
                      onChange={handleWellnessCheckSwitch}
                      checked={wellnessCheck}
                    />
                  }
                />
              </div>
              <div className='settings__switch-row'>
                <Typography className='settings__switch-label'>
                  Receive Email Notification
                </Typography>
                <FormControlLabel
                  control={
                    <Field
                      label="Receive Email Switch"
                      name="canReceiveEmailSwitch"
                      type='checkbox'
                      component={Switch}
                      onChange={handleCanReceiveEmail}
                      checked={canReceiveEmail}
                    />
                  }
                />
              </div>
              <div className='settings__button-container'>
                <Button className='settings__button' type='submit' variant='contained' color='secondary' disabled={!dirty || isSubmitting}>Save changes</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Section>
    </MainTemplate>
  );
}
