import React from 'react';
import PropTypes from 'prop-types';
import updateImagePath from '../../../util/getImagePath';

const ChatImage = ({ msg }) => {
  
  return (
    <section className='chat-image'>
      <img className='chat-image__picture' src={updateImagePath(msg.image)} alt='chat banner image' />
    </section>
  )
};

ChatImage.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default ChatImage;