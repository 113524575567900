import { api } from './api';

async function createNewNote(user) {
  const { data, status } = await api.post('/note', user);
  if (status !== 200) {
    return null;
  }

  return data.id;
}

async function getNote(uuid, user) {
  try {
    const { data } = await api.get(`/note/${uuid}`, user);
    return data;
  } catch (e) {
    return null;
  }
}

async function updateNote(uuid, note) {
  const { data, status } = await api.put(`/note/${uuid}`, note);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function deleteNote(uuid, user) {
  const { data, status } = await api.delete(`/note/${uuid}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

export {
  createNewNote,
  getNote,
  updateNote,
  deleteNote,
};
