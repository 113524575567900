import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ className, children }) => (
  <section className={'section ' + className}>
    {children}
  </section>
);

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default Section;
