import Path from 'path';
import { API_DOMAIN } from 'constant';

function updateImagePath(image) {

  if(Path.isAbsolute(image)) {
    image = API_DOMAIN + image;
  }

  return image;
}

export default updateImagePath