import React from 'react';
import {
  useTheme,
  Icon,
  Typography,
  InputAdornment,
  TextField,
  useMediaQuery,
  Link,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';

function SearchBar({
  handleSearch,
  cancelButton,
  searchTerm,
  onCancelClick,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="center"
    >
      <TextField
        className={isMobile ? 'library__search' : 'library__search library__search--desktop'}
        placeholder='What would you like to explore?'
        type='search'
        variant='outlined'
        defaultValue={searchTerm}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Icon className='red-text'>search</Icon>
            </InputAdornment>
          ),
        }}
        onKeyDown={event => event.key === 'Enter' && handleSearch(event.target.value)}
      />

      {(cancelButton && onCancelClick) &&
        <Link onClick={onCancelClick}>
          <Typography
            className={'library__cancel-button'}
          >
            CANCEL
          </Typography>
        </Link>
      }
    </Grid>
  );
}

SearchBar.propTypes = {
  handleSearch: PropTypes.func,
  cancelButton: PropTypes.bool,
  searchTerm: PropTypes.string,
  onCancelClick: PropTypes.func,
};

SearchBar.defaultProps = {
  cancelButton: false,
};

export default SearchBar;
