import React from 'react';
import HPModal from './HPModal';
import { useHistory } from 'react-router-dom';

function SignOutModal({ open, textOnClick, onClose }) {
  let history = useHistory();

  const handleSignOut = () => {
    history.push("/account/signout");
  };

  return (
    <HPModal
      open={open}
      image="/images/sign_out_icon.png"
      title="Time to go?"
      buttonText="Sign out"
      buttonOnClick={handleSignOut}
      className="signOut__modal"
      text="Cancel"
      textOnClick={textOnClick}
      onClose={onClose}
    >
      Are you sure you'd like to sign out? You'll need to sign in again to keep using <strong>at heart</strong>
    </HPModal>
  );
}
export default SignOutModal;
