import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChatSendButton from './ChatSendButton';
import SliderCard from '../SliderCard';
import updateImagePath from '../../util/getImagePath';

const ChatMulSlider = ({ action, onAnswer }) => {
  const defaultChoiceValues = {}; 

  action.data.slides.forEach((slide) => {
    defaultChoiceValues[slide.id] = slide.default_value
  });

  const [choiceValues,setChoiceValues] = useState(defaultChoiceValues);   

  const handleSliderCardValueChange = (id, value) => {
    let newChoiceValues = {...choiceValues};
    if (newChoiceValues[id] === value){
      return;
    }
    newChoiceValues[id] = value;
    setChoiceValues(newChoiceValues);
  }

  return (
    <div className='slider__container'>
      <div className='chat__sendContainer'>
        <ChatSendButton onClick={() => onAnswer({choiceValues})} />
      </div>
      <div className='slider__row'>
        {
          action.data.slides.map((slide)=>
            <SliderCard key={slide.text} title={slide.text} image={updateImagePath(slide.img)} marks={slide.marks} value={choiceValues[slide.id]} handleValueChange={(value) => handleSliderCardValueChange(slide.id, value)}/>
          )
        }
      </div>
    </div>
  );
};

ChatMulSlider.propTypes = {
  action: PropTypes.object.isRequired,
};

export default ChatMulSlider;
