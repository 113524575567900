const API_DOMAIN = process.env.REACT_APP_API_DOMAIN ?? 'http://localhost:8111'
const DASHBOARD_DOMAIN = process.env.REACT_APP_DASHBOARD_DOMAIN ?? 'http://localhost:3001';

const ChatMessageFrom = {
  USER: 'USER',
};

const ChatMessageType = {
  TIMESTAMP: 'timestamp',
  TEXT: 'text',
};

const ParentUrlIndex = {
  'settings': '/settings',
  'profile': '/profile',
  'library': '/library',
  'checkins': '/checkins',
  'data-donation': '/data-donation/progress',
  'about': '/about',
  'account': '/account/login',
};

const IsProd = (process.env.NODE_ENV === 'production');

export {
  API_DOMAIN,
  DASHBOARD_DOMAIN,
  ChatMessageFrom,
  ChatMessageType,
  ParentUrlIndex,
  IsProd,
};

export default {
  API_DOMAIN,
  DASHBOARD_DOMAIN,
  ChatMessageFrom,
  ChatMessageType,
  ParentUrlIndex,
  IsProd,
};
