import { GET_ARTICLES, GET_FAVOURITE_ARTICLES, GET_CURRENT_ARTICLE, GET_RESEARCH_ARTICLES } from '../actions/articleActions';

const defaultState = {
  currentArticle: null,
  articles: [],
  favouriteArticles: [],
  searchArticles: [],
};

const articleReducer = (state = defaultState, action) => {
  switch (action.type) {
    //TODO: This case does not work yet
    case GET_CURRENT_ARTICLE:
      return {
        ...state,
        currentArticle: {...action.article},
      };
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.articles.map(category => ({
          ...category,
          slug: category.name.replace(/[\W_]/gi, '-').toLowerCase(),
        })),
      };
    case GET_FAVOURITE_ARTICLES:
      return {
        ...state,
        favouriteArticles: [...action.articles],
      };
    case GET_RESEARCH_ARTICLES:
      return {
        ...state,
        searchArticles: [...action.articles],
      };
    default:
      return { ...state };
  }
};

export default articleReducer;
