import React from 'react';
import {
  useMediaQuery,
  useTheme,
  Typography,
  Button,
  Link,
  Modal,
  ButtonGroup,
  IconButton,
} from '@material-ui/core';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useFormikContext } from 'formik';
import Section from '../../components/Section';
import BackButton from '../../components/BackButton';
import DesktopSignInTemplate from '../../templates/DesktopSignInTemplate';
import TermsHtml from '../../components/htmlContent/termsHtml.html';

const createMarkup = text => ({
  __html: text,
});

const TermsContent = ({ handleOpen, open, handleClose }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  }

  return (
    <>
      <Section className={`login__terms${isMobile ? ' section' : ' login-desktop__section login-desktop__section--terms'}`}>
        <Typography className='login__title' variant='h4' component='h4'>
          Terms & Conditions
        </Typography>
        <Typography variant='body1' paragraph>
          'at heart' v1.0
        </Typography>
        <Typography variant='body1' paragraph>
          Last updated: July 1, 2021
        </Typography>
        <Typography variant='body1' paragraph>
          <span dangerouslySetInnerHTML={createMarkup(TermsHtml)} />
        </Typography>
        <footer className={`login__submit ${isMobile ? ' login__submit--footer' : ' login-desktop__submit--footer'}`}>
          <Button className='login__button' type='submit' variant='contained' color='secondary'>I agree</Button>
         <Typography variant='subtitle2'>
            <Link onClick={handleOpen} className='black-text semi-bold'>
              I disagree
            </Link>
          </Typography>
        </footer>
      </Section>
      <Modal
        open={open}
        className='hp-modal'
        onClose={handleClose}
        aria-labelledby="terms-modal-title"
        aria-describedby="terms-modal-description"
      >
        <div className={isMobile ? 'login__modal' : 'login-desktop__modal'}>
          <img className='login__modal-logo' src='/images/modal_logo.png' alt='modal-logo' />
          <IconButton className='black-text login__modal--close' onClick={handleClose}>
            <img src='/images/CloseX.svg' />
          </IconButton>
          <div className='login__modal-content'>
            <Section>
              <Typography id='terms-modal-title' className='login__modal-title' variant='h5' component='h5'>
                Are you sure?
              </Typography>
              <Typography id='terms-modal-description' className='login__modal-paragraph' variant='body1' paragraph>
                Just a quick heads up that while you've signed the consent form, disagreeing to the Terms & Conditions means you cannot proceed into the <strong>at heart</strong> tool.
              </Typography>
              <Link className='login__text-button' component={RouterLink} to="/account/login">
                <Button className='login__button' type='submit' variant='contained' color='secondary'>
                  Yes I'm sure, I disagree
                </Button>
              </Link>
              <Typography variant='subtitle2'>
                <Link className='black-text semi-bold' onClick={handleSubmit}>
                  Nevermind, I agree!
                </Link>
              </Typography>
            </Section>
          </div>
        </div>
      </Modal>
    </>
  )
}

const Terms = ({ formFilled }) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);

  if (!formFilled) {
    history.push('/account/signup');
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isMobile) {
    return (
      <div className='login'>
        <BackButton />
        <img className='login__logo login__logo--extra-small' src='/images/login_logo.png' alt='hp-logo' />
        <TermsContent handleOpen={handleOpen} open={open} handleClose={handleClose} />
      </div>
    );
  } else {
    return (
      <>
        <BackButton isAbsolute={true} />
        <DesktopSignInTemplate picture='/images/login_logo.png' left={4} right={8}>
          <TermsContent handleOpen={handleOpen} open={open} handleClose={handleClose} />
        </DesktopSignInTemplate>
      </>
    );
  }
};

export default Terms;
