import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useTheme,
  useMediaQuery,
  Typography,
  Icon,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Section from '../components/Section';
import BackButton from '../components/BackButton';
import MainTemplate from './MainTemplate';

import { favouriteSelectedArticle, unfavouriteSelectedArticle } from '../redux/actions/articleActions';

const ArticleContent = ({ image, isFavourited, handleFavourite, title, category, children }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <BackButton />
      <img className={isMobile ? 'article__picture' : 'article-desktop__picture'} src={image} alt='article_featured_image' />
      <Section>
        <IconButton className={isFavourited ? 'favourite-button-favourited favourite-button' : 'favourite-button'} onClick={e => handleFavourite(e)}>
          <Icon>favorite</Icon>
        </IconButton>
        <Typography className='article__title' variant='h5' component='h5'>
          {title}
        </Typography>
        <Typography className='article__subtitle' variant='subtitle2'>
          {category}
        </Typography>
        <article>
          {children}
        </article>
      </Section>
    </>
  )
}

const ArticleTemplate = ({
  id,
  image,
  title,
  category,
  favourited,
  children,
}) => {
  const [isFavourited, setIsFavourited] = useState(favourited);

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function handleFavourite() {
    if (isFavourited) {
      dispatch(unfavouriteSelectedArticle(id));
    } else {
      dispatch(favouriteSelectedArticle(id));
    }
    setIsFavourited(!isFavourited);
  }

  useEffect(() => {
    setIsFavourited(favourited);
  }, [favourited]);

  if (isMobile) {
    return (
      <main id='main' className='article'>
        <ArticleContent image={image} isFavourited={isFavourited} handleFavourite={handleFavourite} title={title} category={category} children={children} />
      </main>
    );
  } else {
    return (
      <MainTemplate>
        <div className='article-desktop'>
          <ArticleContent image={image} isFavourited={isFavourited} handleFavourite={handleFavourite} title={title} category={category} children={children} />
        </div>
      </MainTemplate>
    );
  }
};

ArticleTemplate.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default ArticleTemplate;
