import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { resetAction } from '../../redux/actions/authActions';
import BackButton from '../../components/BackButton';
import Section from '../../components/Section';
import DesktopSignInTemplate from '../../templates/DesktopSignInTemplate';


const ResetSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .required('Required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const initialValues = {
  password: '',
  passwordConfirm: '',
};

const ResetForm = ({ initialValues, ForgetSchema, onSubmit, errorMessage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const passwordProps = {
    endAdornment: (
      <InputAdornment position='end'>
        <IconButton
          aria-label='toggle password visibility'
          onClick={handleClickShowPassword}
        >
          {showPassword
            ? <img src='/images/VisiblePassword.svg' alt="show password" />
            : <img src='/images/HiddenPassword.svg' alt="hide password" />
          }
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <>
      <Typography className='login__title' variant='h4' component='h4'>
        Reset your password
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={ResetSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              className='login__input'
              component={TextField}
              name='password'
              type={showPassword ? 'text' : 'password'}
              label='New Password'
              InputProps={passwordProps}
            />
            <Field
              className='login__input'
              component={TextField}
              name='passwordConfirm'
              type={showPassword ? 'text' : 'password'}
              label='Confirm password'
              InputProps={passwordProps}
            />
            <div className='login__submit'>
              {errorMessage &&
                <Typography className='login__error-message'>{errorMessage}</Typography>
              }
              <Button className='login__button' type='submit' variant='contained' color='secondary' disabled={isSubmitting}>Submit</Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const Reset = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const errorMessage = useSelector(state => state.auth.errorMessage);
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  if (!token) {
    history.push('/account/login');
    return null;
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const result = await dispatch(resetAction(values.password, token));
    if (result) {
      setSubmitting(false);
    } else {
      history.push('/account/reset/success');
    }
  };

  if (isMobile) {
    return (
      <div className='login'>
        <BackButton />
        <img className='login__logo' src='/images/login_logo.png' alt='hp-logo' />
        <Section>
          <ResetForm initialValues={initialValues} ResetSchema={ResetSchema} onSubmit={onSubmit} errorMessage={errorMessage} />
        </Section>
      </div>
    );
  } else {
    return (
      <DesktopSignInTemplate picture='/images/login_logo.png' left={6} right={6}>
        <Section className='login-desktop__section'>
          <ResetForm initialValues={initialValues} ResetSchema={ResetSchema} onSubmit={onSubmit} errorMessage={errorMessage} />
        </Section>
      </DesktopSignInTemplate>
    );
  }
};

export default Reset;
