import React from 'react';
import ChatText from './ChatText';
import ChatImage from './ChatImage';
import ChatSlider from './ChatSlider';
import ChatArticle from './ChatArticle';
import ChatEventProfile from './ChatEventProfile';
import ChatBodyMap from './ChatBodyMap';
import ChatTimestamp from './ChatTimestamp';

const ChatMsg = (props) => {

  const { msg } = props;
  switch (msg.type) {
    case 'text':
      return <ChatText msg={msg} />;
    case 'image-banner':
      return <ChatImage msg={msg} />;
    case 'slider':
      return <ChatSlider msg={msg} />;
    case 'article':
      return <ChatArticle msg={msg} />;
    case 'event_profile':
      return <ChatEventProfile msg={msg} />;
    case 'multiple_slider':
      return <ChatSlider msg={msg} />;
    case 'body_map':
      return <ChatBodyMap msg={msg} />;
    case 'timestamp':
      return <ChatTimestamp msg={msg} />;
    default:
      return <div />;
  }
};
export default ChatMsg;
