import {
  getArticle,
  getArticleList,
  getFavouriteArticleList,
  favouriteArticle,
  unfavouriteArticle,
  getResearchArticleList
} from '../../services/heartPainApi/article';

export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_FAVOURITE_ARTICLES = 'GET_FAVOURITE_ARTICLES';
export const GET_CURRENT_ARTICLE = 'GET_CURRENT_ARTICLE';
export const GET_RESEARCH_ARTICLES = 'GET_RESEARCH_ARTICLES';

export const getCurrentArticle = article => ({
  type: GET_CURRENT_ARTICLE,
  article,
});

export const getFavouriteArticles = articles => ({
  type: GET_FAVOURITE_ARTICLES,
  articles,
});

export const getResearchArticles = articles => ({
  type: GET_RESEARCH_ARTICLES,
  articles,
});

export const getArticles = articles => ({
  type: GET_ARTICLES,
  articles,
});

export function favouriteSelectedArticle(id) {
  return async dispatch => {
    favouriteArticle(id);
  }
};

export function unfavouriteSelectedArticle(id) {
  return async dispatch => {
    unfavouriteArticle(id);
  }
};


export function fetchCurrentArticle(slug) {
  return async dispatch => {
    const article = await getArticle(slug);
    dispatch(getCurrentArticle(article));
  }
};

export function fetchArticles() {
  return async dispatch => {
    const { categories } = await getArticleList();
    dispatch(getArticles(categories));
  }
};

export function fetchFavouriteArticles() {
  return async dispatch => {
    const { articles } = await getFavouriteArticleList();
    dispatch(getFavouriteArticles(articles));
  }
};

export function fetchResearchArticles( str ) {
  return async dispatch => {
    const { articles } = await getResearchArticleList({s: str});
    if ( articles === undefined ) {
      dispatch(getResearchArticles([]));
    } else {
      dispatch(getResearchArticles(articles));
    }
  }
};
