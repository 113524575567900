import React, { useEffect, useState } from 'react';
import {
  InputLabel,
  FormControl,
  Select,
  Typography,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Selects = ({ question, valueOptions, unitOptions, label, nodeId, answer, onParentChange }) => {
  let defaultValue = answer[0] ?? '';
  let defaultUnit = answer[1] ?? '';

  const [value, setValue] = useState(defaultValue);
  const [unit, setUnit] = useState(defaultUnit);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!unit) {
      setUnit(unitOptions[0].value);
    }
  }, [unitOptions]);

  useEffect(() => {
    onParentChange(null, [value, unit], nodeId);
  }, [value, unit]);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
    setValue('');
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const labelId = ['outlined', nodeId].join('-');
  let extraSelect = null;

  return (
    <div className="question-node select-question" id={nodeId}>
      <Typography className='data-donation__question' variant="subtitle1">
        {question}
      </Typography>
      <div className="select-group">
        <FormControl variant="outlined" className='data-donation__form--select'>
          <InputLabel id='drop-down-value-id'>{label}</InputLabel>
          <Select
            name='drop-down-value'
            value={value}
            onChange={handleValueChange}
            label='drop-down-value'
            labelId='drop-down-value-id'
            id={nodeId}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            <MenuItem value=''>
              <em>N/A</em>
            </MenuItem>
            {valueOptions.map((c) => (
              <MenuItem key={c.text} value={c.value}>{c.text}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className='data-donation__form--select'>
          <InputLabel id={'drop-down-unit-id'} className="hidden">Unit</InputLabel>
          <Select
            name='drop-down-unit'
            value={unit}
            onChange={handleUnitChange}
            label='drop-down-unit'
            labelId='drop-down-unit-id'
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {unitOptions.map((eo) => (
              <MenuItem key={eo.value} value={eo.value}>{eo.text}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

Selects.propTypes = {
  nodeId: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  valueOptions: PropTypes.array.isRequired,
  unitOptions: PropTypes.array,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  answer: PropTypes.array,
  onParentChange: PropTypes.func,
};

Selects.defaultProps = {
  label: '',
  id: '',
  valueOptions: [],
  unitOptions: [],
  answer: [],
};

export default Selects;
