import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import DataDonationTemplate from '../../templates/DataDonationTemplate';
import Section from '../../components/Section';
import ProgressCard from '../../components/ProgressCard';
import { fetchForms } from '../../redux/actions/dataDonationActions';
import updateImagePath from '../../util/getImagePath';
import {
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
} from '@material-ui/core/';

const Progress = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const forms = useSelector(state => state.dataDonation.forms);
  const hasConsented = useSelector(state => state.auth.user.dataDonationConsent);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(fetchForms());
  }, []);

  if( !hasConsented ) {
    return (
      <Redirect to={
        {
          pathname: '/data-donation/share',
          state: { from: location.pathname },
        }
      }
      />
    );
  }

  return (
  <DataDonationTemplate textClass='white-text' title='Share my Data' hasDrawer={true}>
    {!isMobile && <img className='data-donation__banner' src='/images/share_data_logo.png' alt='share_data_banner' />}
    <Section>
      <div className='data-donation__description'>
        <Typography className='data-donation__description-title' variant='h5'>
          Share my data
        </Typography>
        <Typography>
          Select a category below to begin sharing some information about yourself. Keep in mind, you don't need to answer every question, you can submit your answers at any time, and you can revisit them later if you'd like to update any of them!
        </Typography>
      </div>
      <Grid container spacing={isMobile ? 0 : 2}>
        {forms.map((form) => (
          <Grid key={'data-donation-form' + form.id} item xs={12} md={6}>
            <ProgressCard
              title={form.name} image={updateImagePath(form.thumbnail)}
              progress={form.progressState}
              link={'/data-donation/form/' + form.id}
              isSubmit={form.dataSubmit}
            />
          </Grid>
        ))}
      </Grid>
    </Section>
  </DataDonationTemplate>
  );
};

export default Progress;
