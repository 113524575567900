import { api } from './api';

async function getDataDonationForms(user) {
  const { data, status } = await api.get(`/data-donation/all-forms`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getDataDonationQuestions(id, user) {
  const { data, status } = await api.get(`/data-donation/form/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function saveDataDonationForm(id, answers) {
  const { data, status } = await api.post(`/data-donation/save/${id}`, answers);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function submitDataDonationForm(id, user) {
  const { data, status } = await api.post(`/data-donation/submit/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

export {
  getDataDonationForms,
  getDataDonationQuestions,
  saveDataDonationForm,
  submitDataDonationForm,
};