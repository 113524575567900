import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import Section from '../../components/Section';
import DataDonationTemplate from '../../templates/DataDonationTemplate';

const Share = () => {
  const hasConsented = useSelector(state => state.auth.user.dataDonationConsent);
  let location = useLocation();
  const history = useHistory();

  if( hasConsented ) {
    return (
      <Redirect to={
        {
          pathname: '/data-donation/progress',
          state: { from: location.pathname },
        }
      }
      />
    );
  }

  function handleStartDataDonation () {
    history.push('/data-donation/consent');
  }

  return (
    <DataDonationTemplate textClass='white-text' title='Share my data' hasDrawer={true}>
      <Section>
        <img className='logo data-donation__image' src='/images/donation_image.png' alt='donation-image' />
        <Typography className='data-donation__title text-center' variant='h4' component='h4'>
          Sharing your data
        </Typography>
        <Typography className='data-donation__paragraph text-center' variant='body1' paragraph>
          By donating your data, you'll be contributing to supporting and fueling research on heart pain in women. The data you donate will be helping researchers, clinicians and other women learn more about heart pain and heart health in women. Furthermore, your data will <strong>always be kept anonymous</strong>.
        </Typography>
        <Button variant='contained' color='primary' className='text-button data-donation__button' onClick={handleStartDataDonation}>
          Let's do it!
        </Button>
      </Section>
    </DataDonationTemplate>
  );
};

export default Share;
