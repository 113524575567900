const bodyPartsName = {
  foreHead: 'Forehead',
  rightJaw: 'Right jaw',
  leftJaw: 'Left jaw',
  rightNeckFront: 'Front of right neck',
  leftNeckFront: 'Front of left neck',
  rightShoulderFront: 'Front of right shoulder',
  leftShoulderFront: 'Front of left shoulder',
  rightUpperChest: 'Right upper chest',
  centralUpperChest: 'Central upper chest',
  leftUpperChest: 'Left upper chest',
  rightAxillaFront: 'Front right axilla',
  leftAxillaFront: 'Front left axilla',
  rightLowerChest: 'Right lower chest',
  centralLowerChest: 'Central lower chest',
  leftLowerChest: 'Left lower chest',
  rightUpperArmFront: 'Front of right upper arm',
  epigastric: 'Epigastric',
  leftUpperArmFront: 'Front of left upper arm',
  rightLowerArmFront: 'Front of right lower arm',
  abdomen: 'Abdomen / Stomach',
  leftLowerArmFront: 'Front of left lower arm',
  rightLowerArmFront: 'Front of right lower arm',
  perineum: 'Perineum',
  rightHand: 'Right hand',
  leftHand: 'Left hand',
  rightThigh: 'Right Thigh',
  leftThigh: 'Left Thigh',
  rightShin: 'Right Shin',
  leftShin: 'Left Shin',
  rightFoot: 'Right Foot',
  leftFoot: 'Left Foot',
  headBack: 'Back of head',
  leftNeckBack: 'Back of left neck',
  rightNeckBack: 'Back of right neck',
  leftShoulderBlade: 'Left shoulder blade',
  rightShoulderBlade: 'Right shoulder blade',
  leftShoulderBack: 'Back of left shoulder',
  centralBack: 'Central back',
  rightShoulderBack: 'Back of right shoulder',
  leftMiddleBack: 'Left middle back',
  rightMiddleBack: 'Right middle back',
  leftUpperArmBack: 'Back of left upper arm',
  rightUpperArmBack: 'Back of right upper arm',
  leftLowerBack: 'Left lower back',
  rightLowerBack: 'Right lower back',
  leftLowerArmBack: 'Back of left lower arm',
  rightLowerArmBack: 'Back of right lower arm',
  leftButtock: 'Left buttock',
  rightButtock: 'Right buttock',
  leftHandBack: 'Back of left hand',
  rightHandBack: 'Back of right hand',
  leftThighBack: 'Back of left thigh',
  rightThighBack: 'Back of right thigh',
  leftCalfBack: 'Back of left calf',
  rightCalfBack: 'Back of right calf',
  leftAnkleBack: 'Back of left ankle',
  rightAnkleBack: 'Back of right ankle',
}

export {
  bodyPartsName,
};

export default {
  bodyPartsName,
};