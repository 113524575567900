import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Slider,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';


const CardSlider = withStyles({
  track: {
    backgroundColor: '#3393a4',
    height: 19,
    borderRadius: 100,
  },
  rail: {
    backgroundColor: '#e5e5e5',
    height: 19,
    borderRadius: 100,
  },
})(Slider);

function SliderCard({
  image,
  title,
  value,
  marks,
  handleValueChange,
  isDisabled = false,
}) {

  return (
    <Card className='slider-card' variant="outlined">
      <CardContent className='slider-card__content'>
        <Typography className='slider-card__header' variant='subtitle1'>
          {title}
        </Typography>
        <CardMedia
          className='slider-card__image responsive-image'
          component='img'
          alt='Slider image'
          image={image}
          title={title}
        />
        <Typography className='slider-card__description'>
          {marks[value].text}
        </Typography>
        <CardSlider
          color='secondary'
          aria-labelledby='slider-input'
          defaultValue={5}
          marks={marks}
          step={1}
          min={0}
          max={10}
          valueLabelDisplay='off'
          value={value}
          onChange={(e, value) => handleValueChange(value)}
          disabled={isDisabled}
        />
        <div className="slider-card__label">
          {marks.map((mark, idx) => {
            const fallbackClass = idx === 0 || idx === marks.length - 1
              ? 'slider-card__label-text--show'
              : 'slider-card__label-text';
            return (
              <Typography key={idx} className={`${(value === idx) ? 'slider-card__label-text--selected' : fallbackClass}`}>
                {idx}
              </Typography>   
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
}

SliderCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SliderCard;
