import { 
  turnOnHeartCheckNotification,
  turnOffHeartCheckNotification,
  turnOnWellnessCheckNotification,
  turnOffWellnessCheckNotification,
  agreeDataDonationConsent,
  disagreeDataDonationConsent,
  turnOffHeartCheckNotificationById,
  turnOffWellnessCheckNotificationById,
} from '../../services/heartPainApi/user';

export const GET_DATADONATION_CONSENT = 'GET_DATADONATION_CONSENT';

export const getDataDonationConsent = state => ({
  type: GET_DATADONATION_CONSENT,
  state,
});

export function heartCheckNotificationOn() {
  return async dispatch => {
    turnOnHeartCheckNotification();
  }
};

export function heartCheckNotificationOff() {
  return async dispatch => {
    turnOffHeartCheckNotification();
  }
};

export function heartCheckNotificationOffById(id) {
  return async dispatch => {
    await turnOffHeartCheckNotificationById(id);
  }
};

export function wellnessCheckNotificationOn() {
  return async dispatch => {
    turnOnWellnessCheckNotification();
  }
};

export function wellnessCheckNotificationOff() {
  return async dispatch => {
    turnOffWellnessCheckNotification();
  }
};

export function wellnessCheckNotificationOffById(id) {
  return async dispatch => {
    await turnOffWellnessCheckNotificationById(id);
  }
};

export function dataDonationConsentAgree() {
  return async dispatch => {
    const state = await agreeDataDonationConsent();
    dispatch(getDataDonationConsent(state));
    return state.user.dataDonationConsent;
  }
};

export function dataDonationConsentDisagree() {
  return async dispatch => {
    const state = await disagreeDataDonationConsent();
    dispatch(getDataDonationConsent(state));
    return state.user.dataDonationConsent;
  }
};
