import { api } from './api';
import getQueryString from '../../util/getQueryString';

async function getArticle(id, user) {
  const { data, status } = await api.get(`/article/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getArticleList(user) {
  const { data, status } = await api.get(`/category/withArticles`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getFavouriteArticleList(user) {
  const { data, status } = await api.get(`/favourite`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function getResearchArticleList(query, user) {
  const queryString = getQueryString(query);
  const { data, status } = await api.get(`/articles/search${queryString}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function favouriteArticle(id, user) {
  const { data, status } = await api.post(`/favourite/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function unfavouriteArticle(id, user) {
  const { data, status } = await api.post(`/unfavourite/${id}`, user);
  if (status !== 200) {
    return null;
  }
  return data;
}

export {
  getArticle,
  getArticleList,
  getFavouriteArticleList,
  favouriteArticle,
  unfavouriteArticle,
  getResearchArticleList,
};