import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllCheckIn,
  getMoreAllCheckIn,
  getHeartCheckIn,
  getMoreHeartCheckIn,
  getWellnessCheckIn,
  getMoreWellnessCheckIn,
  getNotesCheckIn,
  getMoreNotesCheckIn,
} from '../../redux/actions/checkInActions';
import {
  useMediaQuery,
  useTheme,
  Card,
  CardActionArea,
  Fab,
  Icon,
  Typography,
  InputAdornment,
  TextField,
  Tabs,
  AppBar,
  Tab,
  Box,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Section from '../../components/Section';
import Navbar from '../../components/Navbar';
import CheckInRows from '../../components/CheckInRows';
import ArticleList from '../../components/ArticleList';
import updateImagePath from '../../util/getImagePath';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import {
  createNewNote
} from '../../services/heartPainApi/note';
import MainTemplate from '../../templates/MainTemplate';

const CheckIns = () => {
  const [checkIn, setCheckIn] = useState('all');
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let history = useHistory();

  const dispatch = useDispatch();
  const data = useSelector(state => state.checkIn.checkIns);
  
  let today = format(new Date(), 'yyyy-MM-dd');


  useEffect(() => {
    dispatch(getAllCheckIn());
  }, []);

  const handleAllCheck = async() => {
    if (checkIn !== 'all') {
      setCheckIn('all');
      await dispatch(getAllCheckIn());
    }
  }

  const handleHeartCheck = async() => {
    if (checkIn !== 'heart') {
      setCheckIn('heart');
      await dispatch(getHeartCheckIn());
    }
  }

  const handleWellnessCheck = async() => {
    if (checkIn !== 'wellness') {
      setCheckIn('wellness');
      await dispatch(getWellnessCheckIn());
    }
  }

  const handleNotes = async() => {
    if (checkIn !== 'note') {
      setCheckIn('note');
      await dispatch(getNotesCheckIn());
    }
  }

  const handleNewNote = async() => {
    const newNoteId = await createNewNote();
    history.push(`/checkins/note/${newNoteId}`);

  }

  const handleScroll = async (e) => {
    const maxScroll = e.target.scrollHeight - e.target.offsetHeight

    //TODO: May need to Update after container size fix
    if ((maxScroll - e.target.scrollTop) < 1 && !isFetchingMessages) {
      setIsFetchingMessages(true);
      if (checkIn === 'all') {
        await dispatch(getMoreAllCheckIn());
      } else if (checkIn === 'heart') {
        await dispatch(getMoreHeartCheckIn());
      } else if (checkIn === 'wellness') {
        await dispatch(getMoreWellnessCheckIn());
      } else if (checkIn === 'note') {
        await dispatch(getMoreNotesCheckIn());
      }
      setIsFetchingMessages(false);
    }
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  
  return (
    <MainTemplate title='My check-ins' isWide={true} hasPadding={true}>
      <div id='check-in-box' className='check-in__container' onScroll={handleScroll}>
        <AppBar position='static' color='default' component='div' elevation={0}>
          <Tabs
            className='check-in__tabs'
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='check in types'
          >
            <Tab label='View all' disableRipple onClick={handleAllCheck} />
            <Tab label='Heart checks' disableRipple onClick={handleHeartCheck} />
            <Tab label='Wellness checks' disableRipple onClick={handleWellnessCheck} />
            <Tab label='Notes' disableRipple onClick={handleNotes} />
          </Tabs>
        </AppBar>
        {data &&
          Object.keys(data).map((key) => (
            <div key={data[key].date}>
              <Typography className='check-in__date'>
                {data[key].date === today ?
                  'Today'
                  :
                  data[key].date
                }
              </Typography>
              {checkIn === 'note' ?
                <>
                  {data[key].data.map((d, index) => (
                    <Card className={isMobile ? 'check-in-card' : 'check-in-card check-in-card--desktop'} key={index}>
                      <CardActionArea href={`/checkins/${checkIn}/${d.uuid}`}>
                          <CheckInRows data={d} />
                      </CardActionArea>
                    </Card>
                  ))}
                </>
              :
              <Card className={isMobile ? 'check-in-card' : 'check-in-card check-in-card--desktop'}>
                <CardActionArea href={`/checkins/${checkIn}/${data[key].date}`}>
                  {data[key].data.map((d, index) => (
                    <CheckInRows key={index} data={d} />
                  ))}
                </CardActionArea>
              </Card>
              }
            </div>
          ))
        }
      </div>
      {checkIn === 'note' &&
        <div className='check-in-note-fab'>
          <Fab color='primary' aria-label='add-note' onClick={handleNewNote}>
            <AddIcon />
          </Fab>
        </div>
      }
    </MainTemplate>
  );
};

export default CheckIns;
