import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Button,
  Icon,
} from '@material-ui/core';
import MainTemplate from '../templates/MainTemplate';
import Section from '../components/Section';

import { getEventProfile } from '../services/heartPainApi/profile';

const EventProfile = () => {
  const [profile, setProfile] = useState(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { data = {} } = await getEventProfile() ?? {};
      if (Object.keys(data).length === 0){
        setProfile({});
      } else {
        setProfile(data);
      }
    })()
  }, []);

  const handleUpdateEventProfile = () => {
    history.push('/profile/event-profile/edit');
  }

  if (profile === null) {
    return null;
  } else if (Object.keys(profile).length === 0) {
    return(
      <MainTemplate title='My profile' hasBack={true} hasDrawer={false} hasPadding={true}>
        <Section>
          <Typography className='event-profile__title' variant='h5'>
            Please finish Onboarding flow in Chat first.
          </Typography>
        </Section>
      </MainTemplate>
    );
  } else {
    return (
      <MainTemplate title='My profile' hasBack={true} hasDrawer={false} hasPadding={true}>
        <img className='event-profile__picture' src='/images/event_profile_logo.png' alt='chat banner image' />
        <Section>
          <Typography className='event-profile__title' variant='h5'>
            My event profile
          </Typography>
          <div className='event-profile__row'>
            <Typography className='event-profile__question' variant='subtitle1'>
              1. At the time of your last cardiac event, how would you describe your heart pain/discomfort?
            </Typography>
          <Typography>
            {profile.discomfort}
          </Typography>
          </div>
          <div className='event-profile__row'>
            <Typography className='event-profile__question' variant='subtitle1'>
              2. Where did you experience this pain/discomfort?
            </Typography>
            <Typography>
              {profile.location ?
                profile.location
                :
                "There is no pain"
              }
            </Typography>
          </div>
          <div className='event-profile__row'>
            <Typography className='event-profile__question' variant='subtitle1'>
              3. Did your pain/discomfort spread or move? If so, select the location(s) it moved to
            </Typography>
            <Typography>
              {profile.travel ?
                profile.travel
                :
                "The pain didn't travel"
              }
            </Typography>
          </div>
          <div className='event-profile__row'>
            <Typography className='event-profile__question' variant='subtitle1'>
              4. What other symptoms, if any, did you experience at the time of your last cardiac event?
            </Typography>
            <Typography>
              {profile.symptoms}
            </Typography>
          </div>
          <div className='event-profile__button-container'>
            <Button
              className='event-profile__button'
              color='primary'
              variant="contained"
              endIcon={<Icon>arrow_forward</Icon>}
              onClick={handleUpdateEventProfile}
            >
              Update my event profile
            </Button>
          </div>
        </Section>
      </MainTemplate>
    )
  }
}

export default EventProfile;
