import {
  GET_FORMS,
  GET_CURRENT_FORM,
  GET_CURRENT_ANSWERS,
  GET_FORM_SUBMIT_STATE
} from '../actions/dataDonationActions';

const defaultState = {
  currentForm: null,
  image: null,
  forms: [],
  questions: [],
  saveData: {},
  submitState: false,
};

const dataDonationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_FORMS:
      return {
        ...state,
        forms: [...action.forms],
      };
    case GET_CURRENT_FORM:
      return {
        ...state,
        currentForm: action.currentForm.name,
        image: action.currentForm.thumbnail,
        questions: [...action.currentForm.questions],
        saveData: action.saveData,
        submitState: action.submitState,
      };
    case GET_CURRENT_ANSWERS:
      return {
        ...state,
        saveData: action.saveData,
      };
    case GET_FORM_SUBMIT_STATE:
      return {
        ...state,
        submitState: action.submitState,
      }
    default:
      return { ...state };
  }
};

export default dataDonationReducer;