import _ from 'lodash';

import * as Action from '../actions/chatActions';

const onboardingFlow = 5;

const defaultState = {
  messages: [],
  scheduleMessages: [],
  action: {},
  currentNode: {},
  lastMessageId: null,
  firstMessageId: null,
  keyboardDisplay: true,
  botIsTyping: false,
  displayGlobal: false,
};

const chatReducer = (state = defaultState, action) => {
  switch (action.type) {
     case Action.GET_MESSAGES:
      // Make sure all the incoming messages don't match the last message id
      const newMessages = action.data.messages.filter(message => message.id !== state.lastMessageId);
      return {
        ...state,
        lastMessageId: action.data.lastMessageId,
        firstMessageId: action.data.firstMessageId,
        messages: [...state.messages, ...newMessages],
        action: { ...action.data.node.action },
        currentNode: action.data.node,
        displayGlobal: (action.data.node.chatFlow !== onboardingFlow),
      };

    case Action.GET_OLD_MESSAGES:
      return {
        ...state,
        firstMessageId: action.data.firstMessageId,
        messages: [...action.data.messages, ...state.messages],
      };

    case Action.GET_SCHEDULE_MESSAGES: {
      // Make sure all the incoming messages don't match the last message id
      const scheduleMessages = action.data.messages.filter(message => message.id !== state.lastMessageId);
      return {
        ...state,
        lastMessageId: action.data.lastMessageId,
        scheduleMessages,
        action: { ...action.data.node.action },
        currentNode: action.data.node,
        displayGlobal: (action.data.node.chatFlow !== onboardingFlow),
      };
    }
    case Action.UPDATE_FIRST_SCHEDULE_MESSAGE_TO_MESSAGES: {
      const scheduledMessage = state.scheduleMessages[0];
      const scheduleMessages = [...state.scheduleMessages.slice(1)];

      const messageExists = !!state.messages.find(message =>
        message.id === scheduledMessage.id
        && message.type === scheduledMessage.type
        && (
          message.type === 'text' && message.text === scheduledMessage.text
          || (message.type === 'timestamp' && message.data === scheduledMessage.data)
          || (message.type === 'image-banner' && message.image === scheduledMessage.image)
          || (message.type === 'article' && message.slug === scheduledMessage.slug)
          || (message.type === 'slider' && _.isEqual(message.data, scheduledMessage.data))
          || (message.type === 'event_profile' && _.isEqual(message.data, scheduledMessage.data))
          || (message.type === 'body_map' && _.isEqual(message.data, scheduledMessage.data))
        )
      );

      return {
        ...state,
        messages: !messageExists ? [...state.messages, scheduledMessage] : [...state.messages],
        scheduleMessages,
      };
    }
    case Action.SHOW_KEYBOARD:
      return {
        ...state,
        keyboardDisplay: true,
      };

    case Action.HIDE_KEYBOARD:
      return {
        ...state,
        keyboardDisplay: false,
      };

    case Action.BOT_IS_TYPING:
      return {
        ...state,
        botIsTyping: action.payload.isTyping,
      };

    default:
      return { ...state };
  }
};

export default chatReducer;
