import React, { useState, useEffect } from 'react';
import {
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import ChatSendButton from './ChatSendButton';

// TODO: the click listener should be a button instead of a div
const ChatCheckbox = ({ action, onAnswer, multi }) => {
  const [choices, setChoices] = useState([]);
  const [noneChoice, setNoneChoice] = useState(false);

  useEffect(() => {
    setChoices([]);
    setNoneChoice(false);
  }, [action]);

  const addChoice = c => {
    setChoices(choices => [...choices, c]);
  }

  const removeChoice = c => {
    let newChoices = [...choices];
    const index = newChoices.indexOf(c);
    if (index > -1) {
      newChoices.splice(index, 1);
    }
    setChoices(newChoices);
  }

  const isSelected = c => {
    return choices.includes(c.id);
  }

  const handleSelect = (choice, type)  => {
    if (multi) {
      if(choices.includes(choice)){
        if (type === 'solo') {
          setNoneChoice(false);
        }
        removeChoice(choice);
      } else {
        if (type === 'solo') {
          setChoices([]);
          setNoneChoice(true);
        } else if (choices.length === 1 && noneChoice) {
          setChoices([]);
          setNoneChoice(false);
        }
        addChoice(choice);
      }
    } else {
      if(choices.includes(choice)){
        removeChoice(choice);
      } else if (choices.length === 0) {
        addChoice(choice);
      } else {
        setChoices([choice]);
      }
    }
  };

  const sendAnswer = () => {
    if (choices.length === 0) {
      return;
    } else if (multi) {
      onAnswer({ choices: choices })
    } else {
      onAnswer({ choiceId: choices[0]})
    }
    setChoices([]);
    setNoneChoice(false);
  };

  const handleKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    sendAnswer();
  };

  return (
    <div className='chat-checkbox'>
      <ChatSendButton
        onClick={sendAnswer}
        disabled={choices.length === 0}
      />
      <div className='chat-checkbox__row'>
        {action.data.choices.map((c) => (
          <Button
            key={c.id}
            className={`checkbox ${(isSelected(c)) ? 'selected' : ''}`}
            variant="contained"
            onClick={() => handleSelect(c.id, c.type)}
            disableElevation
            onKeyDown={handleKeyDown}
          >
            {c.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

ChatCheckbox.defaultProps = {
  multi: false,
};

ChatCheckbox.propTypes = {
  action: PropTypes.object.isRequired,
  onAnswer: PropTypes.func.isRequired,
  multi: PropTypes.bool,
};

export default ChatCheckbox;
