const symptomOption = [
  {
    "id": "nausea",
    "text": "Nausea",
  },{
    "id": "vomiting",
    "text": "Vomiting",
  },{
    "id": "sweating",
    "text": "Sweating",
  },{
    "id": "racing_heat_rate",
    "text": "Racing Heart Rate",
  },{
    "id": "shortness_of_breath",
    "text": "Shortness of Breath",
  },{
    "id": "headache",
    "text": "Headache",
  },{
    "id": "indigestion",
    "text": "Indigestion",
  },{
    "id": "palpitations_fluttering",
    "text": "Palpitations/Fluttering",
  },{
    "id": "lightheaded_faint_dizzy",
    "text": "Lightheaded/Faint/Dizzy",
  },{
    "id": "feeling_shaky",
    "text": "Feeling shaky",
  },
  {
    "id": "weakness_fatigue",
    "text": "Weakness/Fatigue",
  },{
    "id": "other",
    "text": "Other",
  },{
    "id": "no_other_symptoms",
    "text": "No other symptoms",
    "type": "solo",
  }
];

export {
  symptomOption,
};

export default {
  symptomOption,
};