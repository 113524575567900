import { api } from './api';

async function answerNode(id, payload) {
  const { data, status } = await api.post(`/chat/node/${id}/answer`, payload);
  if (status !== 200) {
    return null;
  }

  return data.node;
}

async function getNextNode(user) {
  const { data, status } = await api.get(`/chat/node`, user);
  if (status !== 200) {
    return null;
  }

  return data.node;
}

export {
  answerNode,
  getNextNode,
};
