import {
  AUTH_AUTHENTICATED_CHECK,
  AUTH_LOGIN_USER,
  AUTH_LOGOUT,
  AUTH_REGISTER_USER,
  AUTH_LOGIN_ERROR,
  AUTH_REGISTER_ERROR,
  AUTH_RESET_ERROR,
  AUTH_FORGET_EMAIL,
} from '../actions/authActions';

import {
  GET_DATADONATION_CONSENT,
} from '../actions/userActions'


const defaultState = {
  user: null,
  isAuthenticated: false,
  forgetEmail: null,
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_REGISTER_USER:
      return { 
        ...state,
        isAuthenticated: true,
        user: {...action.user},
      };
    case AUTH_LOGIN_USER:
      return { 
        ...state,
        isAuthenticated: true,
        user: {...action.user, firstLogin: action.firstLogin},
      };
    case AUTH_LOGOUT:
      return { ...state, user: null, isAuthenticated: false };
    case AUTH_AUTHENTICATED_CHECK:
      return { 
        ...state,
        isAuthenticated: action.isAuthenticated,
        user: {...action.user},
      };
    case AUTH_LOGIN_ERROR:
      return { ...state, isAuthenticated: false, errorMessage: action.error };
    case AUTH_REGISTER_ERROR:
      return { ...state, isAuthenticated: false, errorMessage: action.error };
    case AUTH_RESET_ERROR:
      return { ...state, isAuthenticated: false, errorMessage: action.error };
    case GET_DATADONATION_CONSENT:
      return {
        ...state,
        user: {...action.state.user},
      };
    case AUTH_FORGET_EMAIL:
      return {
        ...state,
        forgetEmail: action.forgetEmail
      }
    default:
      return { ...state };
  }
};

export default authReducer;
