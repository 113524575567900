import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SuccessTemplate from '../../templates/SuccessTemplate';
import { accountVerify } from '../../services/heartPainApi/auth.js';

const SignUpSuccess = () => {
  return(
    <SuccessTemplate message='Registration successful. Please check your email and verify your account within the next 24 hours.' />
  );
}

export default SignUpSuccess;