import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SuccessTemplate from '../../templates/SuccessTemplate';

const ForgetSuccess = () => {
  const email = useSelector(state => state.auth.forgetEmail);

  return (
    <SuccessTemplate
      image='/images/email_success.png'
      message={
        email ?
          `If an account exisits with your email address, <strong>${email}</strong>, we just sent you an email with instructions to reset your password.`
        :
          `Thanks! If you have an account setup with us, you should receive and email shortly with instructions on resetting your password.`
      }
    />
  );
}

export default ForgetSuccess;
