import { api } from './api';
import getQueryString from '../../util/getQueryString';

async function getChatMessages(query) {
  const queryString = getQueryString(query);
  const { data, status } = await api.get(`/chat/message${queryString}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function triggerHeartCheck(user) {
  const { data, status } = await api.post('/chat/trigger/heartCheck', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function triggerWellnessCheck(user) {
  const { data, status } = await api.post('/chat/trigger/wellnessCheck', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

export {
  getChatMessages,
  triggerHeartCheck,
  triggerWellnessCheck,
};
