import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SuccessTemplate from '../../templates/SuccessTemplate';
import { accountVerify } from '../../services/heartPainApi/auth.js';

const VerifySuccess = () => {
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [verificationDetermined, setVerificationDetermined] = useState(false);

  useEffect(() => {
    (async () => {
      if (token) {
        const verified = await accountVerify({ token });
        setVerifySuccess(verified);
      }
      setVerificationDetermined(true);
    })()
  }, []);

  if (!verificationDetermined) {
    return null;
  }

  if (verifySuccess) {
    return (
      <SuccessTemplate message='Congratulations! You are now verified. Please login again to start participating At Heart!' />
    );
  } else {
    return(
      <SuccessTemplate message='Verification failed due to token expire or invalid token. Please register again. Your account may also be verified already. Please try to login from the login page.' success={false} />
    );
  }
}

export default VerifySuccess;
