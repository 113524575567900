import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
  useMediaQuery,
  useTheme,
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { loginAction } from '../../redux/actions/authActions';
import Section from '../../components/Section';
import DesktopSignInTemplate from '../../templates/DesktopSignInTemplate';

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
  password: Yup.string()
    .min(8, 'Your password must be at least 8 characters!')
    .max(50, 'Too Long!')
    .required('Required'),
});

const initialValues = {
  email: '',
  password: '',
};

const LoginForm = ({ initialValues, SigninSchema, onSubmit, errorMessage }) => (
  <>
    <Typography className='login__title' variant='h4' component='h4'>
      Sign in
    </Typography>
    <Formik
      initialValues={initialValues}
      validationSchema={SigninSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field
            className='login__input'
            component={TextField}
            name='email'
            type='email'
            label='Email'
          />
          <Field
            className='login__input'
            component={TextField}
            name='password'
            type='password'
            label='Password'
          />
          <Typography className='login__forgot' variant='subtitle2'>
            <Link className='login__text semi-bold' component={RouterLink} to="/account/forget">
              Forgot your password?
          </Link>
          </Typography>
          <div className='login__submit'>
            {errorMessage &&
              <Typography className='login__error-message'>{errorMessage}</Typography>
            }
            <Button className='login__button' type='submit' variant='contained' color='secondary' disabled={isSubmitting}>Sign in</Button>
            <Typography className='' variant='subtitle2'>
              {"Don't have an account yet?  "}
              <Link className='login__text semi-bold' component={RouterLink} to="/account/signup">
                Sign up
              </Link>
            </Typography>
          </div>
        </Form>
      )}
    </Formik>
  </>
)

const Login = () => {

  const errorMessage = useSelector(state => state.auth.errorMessage);
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const onSubmit = async (values, { setSubmitting }) => {
    const result = await dispatch(loginAction(values));
    if (result) {
      setSubmitting(false);
    }
  };

  if (isMobile) {
    return (
      <div className='login'>
        <img className='login__logo' src='/images/login_logo.png' alt='hp-logo' />
        <Section>
          <LoginForm initialValues={initialValues} SigninSchema={SigninSchema} onSubmit={onSubmit} errorMessage={errorMessage} />
        </Section>
      </div>
    );
  } else {
    return (
      <DesktopSignInTemplate picture='/images/login_logo.png' left={6} right={6}>
        <Section className='login-desktop__section'>
          <LoginForm initialValues={initialValues} SigninSchema={SigninSchema} onSubmit={onSubmit} errorMessage={errorMessage} />
        </Section>
      </DesktopSignInTemplate>
    );
  }
};

export default Login;
