import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTheme,
  useMediaQuery,
  Typography,
  ButtonGroup,
  Button,
  Link,
} from '@material-ui/core';
import { Redirect, useLocation, useHistory, useParams } from 'react-router-dom';
import Countries from 'country-list';
import Util from 'lodash';
import Section from '../../components/Section';
import SurveyFormTemplate from '../../templates/SurveyFormTemplate';
import Checkbox from './form/Checkbox';
import DropDown from './form/DropDown';
import UnitDropDown from './form/UnitDropDown';
import TextInput from './form/TextInput';
import HPModal from '../../components/HPModal';
import { fetchCurrentForm, saveFormAction, submitFormAction } from '../../redux/actions/dataDonationActions';
import updateImagePath from '../../util/getImagePath';


const intersects = (a = [], b = []) => a.filter(x => b.includes(x)).length !== 0;

const canSkip = (question, response) => {
  return response
    && (typeof question.show_if.value === 'string' && !response.includes(question.show_if.value)
      || typeof Array.isArray(question.show_if.value) && !intersects(response, question.show_if.value));
};

const SurveyForm = () => {
  const hasConsented = useSelector(state => state.auth.user.dataDonationConsent);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const dataDonation = useSelector(state => state.dataDonation);
  const { currentForm, questions, saveData, image } = dataDonation;
  const [dataDonationAnswer, setDataDonationAnswer] = useState({});
  const [noChangeState, setNoChangeState] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [banner, setBanner] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let order = 0;

  function handleCloseModal() {
    history.push('/data-donation/progress');
  }

  function getSkipAnswers() {
    const skippableAnswerIds = [];
    questions.forEach(question => {
      if (question.type === 'text') {
        skippableAnswerIds.push(question.id);
      }

      if(question.show_if) {
        const targetId = question.show_if.id;
        if (canSkip(question, dataDonationAnswer[targetId])) {
          skippableAnswerIds.push(question.id);
        }
      };
    });
    return skippableAnswerIds;
  }

  function cleanseAnswer() {
    const skippableAnswerIds = getSkipAnswers();
    let newState = { ...dataDonationAnswer };
    skippableAnswerIds.forEach(answer => {
      delete newState[answer];
    });
    return newState;
  }

  function checkIfCanSubmit() {
    const skippableAnswerIds = getSkipAnswers();
    for (let key in dataDonationAnswer) {
      if ((dataDonationAnswer[key] === undefined || dataDonationAnswer[key].length === 0) && !skippableAnswerIds.includes(key)) {
        setCanSubmit(false);
        return;
      }
    }
    setCanSubmit(true);
  }

  useEffect(() => {
    dispatch(fetchCurrentForm(id));
  }, []);

  useEffect(() => {
    const image = dataDonation?.image;
    setBanner(image ? updateImagePath(image) : undefined);
    checkIfCanSubmit();
  }, [dataDonationAnswer]);

  if( !hasConsented ) {
    return (
      <Redirect to={
        {
          pathname: '/data-donation/share',
          state: { from: location.pathname },
        }
      }
      />
    );
  }

  if (saveData &&
    Object.keys(saveData).length > 0 &&
    Object.keys(dataDonationAnswer).length === 0
  ) {
    setDataDonationAnswer(saveData);
  } else if (
    Object.keys(saveData).length === 0 &&
    Object.keys(dataDonationAnswer).length === 0 &&
    questions.length > 0
  ) {
    questions.forEach( function(question) {
      if (question.type === 'text_input' || question.type === 'dropdown') {
        setDataDonationAnswer(dataDonationAnswer => {
          return { ...dataDonationAnswer, [question.id]: '' }
        });
      } else {
        setDataDonationAnswer(dataDonationAnswer => {
          return { ...dataDonationAnswer, [question.id]: [] }
        });
      }
    } );
  }

  function handleAnswerChange(event, value, answerId) {
    setDataDonationAnswer(dataDonationAnswer => {
      return { ...dataDonationAnswer, [answerId]: value }
    });

    if (noChangeState) {
      setNoChangeState(false);
    }
  }

  function getOptions(node) {
    if (node.staticOptions) {
      let options = [];
      let numbers = [];
      let [minNumber, maxNumber] = [0, 1];

      switch (node.staticOptions) {
        case 'countries':
          let countriesList = Countries.getNames();
          for (const country of countriesList.sort()) {
            options.push({
              value: Countries.getCode(country),
              text: country,
            });
          }
          break;
        case 'height':
        case 'waist':
          if (dataDonationAnswer[node.id] && dataDonationAnswer[node.id][1] === 'in') {
            [minNumber, maxNumber] = [20, 80];
          } else {
            [minNumber, maxNumber] = [50, 200];
          }
          numbers = Util.range(minNumber, maxNumber);

          options.push({
            value: `${minNumber}-`,
            text: `${minNumber} -`,
          });
          numbers.forEach((number) => {
            options.push({
              value: `${number}`,
              text: number,
            });
          });
          options.push({
            value: `${maxNumber}+`,
            text: `${maxNumber} +`,
          });
          break;
        case 'weight':
          if (dataDonationAnswer[node.id] && dataDonationAnswer[node.id][1] === 'kg') {
            [minNumber, maxNumber] = [14, 100];
          } else {
            [minNumber, maxNumber] = [30, 200];
          }
          numbers = Util.range(minNumber, maxNumber);

          options.push({
            value: `${minNumber}-`,
            text: `${minNumber} -`,
          });
          numbers.forEach((number) => {
            options.push({
              value: `${number}`,
              text: number,
            });
          });
          options.push({
            value: `${maxNumber}+`,
            text: `${maxNumber} +`,
          });
          break;
        default:
          console.log('Unknown static options');
      }
      return options;
    }
    return node.options;
  }

  function getUnitOptions(type) {
    switch (type) {
      case 'height':
      case 'waist':
        return [{
          value: 'cm',
          text: 'Cm',
        },{
          value: 'in',
          text: 'In',
        }];
      case 'weight':
        return [{
          value: 'kg',
          text: 'Kg',
        },{
          value: 'lb',
          text: 'Lb',
        }];
      default:
        return [];
    }
  }

  async function handleSaveForm() {
    setNoChangeState(true);

    await dispatch(saveFormAction(id, dataDonationAnswer));
    await history.push('/data-donation/progress');
  }

  async function handleSubmitForm() {

    if (!noChangeState) {
      const cleansedAnswer = cleanseAnswer();
      await dispatch(saveFormAction(id, cleansedAnswer));
      await dispatch(submitFormAction(id));

      setOpenModal(true);
    }
  }

  return (
    <SurveyFormTemplate className='survey-form' image={banner}>
      <Section className="survey-form__section">
        <HPModal
          open={openModal}
          onClose={handleCloseModal}
          image='/images/submitted_image.png'
          title='Submitted!'
          buttonText='Sounds good!'
          buttonOnClick={handleCloseModal}
          className='data-donation__modal'
        >
          Heads up, you can always return to this category later to edit your answers, or to complete any unanswered questions.
        </HPModal>
        <Typography className='data-donation__title' variant='h5' component='h5'>
          {currentForm}
        </Typography>
        <form className='data-donation-form-questions'>
          {questions.map((formQuestion) => {
            if (!formQuestion.no_order) {
              order += 1;
            }
            if (formQuestion.show_if) {
              const targetId = formQuestion.show_if.id;
              if (canSkip(formQuestion, dataDonationAnswer[targetId])) {
                return <div key={formQuestion.id} />;
              }
            }
            switch (formQuestion.type) {
              case 'text':
                return (
                  <Typography variant="subtitle1">{`${formQuestion.no_order ? '' : `${order}.`} ${formQuestion.question}`}</Typography>
                );
              case 'single_checkbox':
                return (
                  <Checkbox
                    nodeId={formQuestion.id}
                    key={`single_checkbox_${formQuestion.id}`}
                    question={`${formQuestion.no_order ? '' : `${order}.`} ${formQuestion.question}`}
                    data={formQuestion.options}
                    answer={dataDonationAnswer[formQuestion.id]}
                    alignLeft
                    onParentChange={handleAnswerChange}
                  />
                );
              case 'multi_checkbox':
                return (
                  <Checkbox
                    nodeId={formQuestion.id}
                    key={`multi_checkbox_${formQuestion.id}`}
                    question={`${formQuestion.no_order ? '' : `${order}.`} ${formQuestion.question}`}
                    data={formQuestion.options}
                    answer={dataDonationAnswer[formQuestion.id]}
                    onParentChange={handleAnswerChange}
                    alignLeft
                    multi
                  />
                );
              case 'text_input':
              case 'number_input':
                return (
                  <TextInput
                    nodeId={formQuestion.id}
                    key={`text_input_${formQuestion.id}`}
                    question={`${formQuestion.no_order ? '' : `${order}.`} ${formQuestion.question}`}
                    type={formQuestion.type === 'number_input' ? 'numberinput' : 'textinput'}
                    answer={dataDonationAnswer[formQuestion.id]}
                    onParentChange={handleAnswerChange}
                  />
                );
              case 'date_picker':
                return (
                  <TextInput
                    nodeId={formQuestion.id}
                    key={`text_input_${formQuestion.id}`}
                    question={`${formQuestion.no_order ? '' : `${order}.`} ${formQuestion.question}`}
                    type='datepicker'
                    answer={dataDonationAnswer[formQuestion.id]}
                    onParentChange={handleAnswerChange}
                  />
                );
              case 'dropdown':
                return (
                  <DropDown
                    nodeId={formQuestion.id}
                    key={`single_dropdown_${formQuestion.id}`}
                    label={formQuestion.label}
                    question={`${formQuestion.no_order ? '' : `${order}.`} ${formQuestion.question}`}
                    data={getOptions(formQuestion)}
                    answer={dataDonationAnswer[formQuestion.id]}
                    onParentChange={handleAnswerChange}
                  />
                );
              case 'height_input':
              case 'weight_input':
              case 'waist_input':
                return (
                  <UnitDropDown
                    nodeId={formQuestion.id}
                    key={`multi_dropdown_${formQuestion.id}`}
                    label={formQuestion.label}
                    question={`${formQuestion.no_order ? '' : `${order}.`} ${formQuestion.question}`}
                    valueOptions={getOptions(formQuestion)}
                    unitOptions={getUnitOptions(formQuestion.staticOptions)}
                    answer={dataDonationAnswer[formQuestion.id]}
                    onParentChange={handleAnswerChange}
                  />
                );
              default:
                order -= 1;
                return null;
            }
          })}
        </form>
      </Section>
      {questions.length > 0 &&
        <ButtonGroup
          variant='contained'
          orientation='vertical'
          className={`survey-form__links survey-form__links--${isMobile ? 'mobile' : 'desktop'}`}
          aria-label='vertical contained button group'
        >
          <Button
            className='text-button data-donation__button'
            color='primary'
            onClick={handleSubmitForm}
            disabled={noChangeState}
          >
            {canSubmit ? 'Submit' : 'Submit and finish later'}
          </Button>
        </ButtonGroup>
      }
    </SurveyFormTemplate>
  );
}

export default SurveyForm;
