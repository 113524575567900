function getQueryString(query) {
  if (query) {
    const filteredQuery = Object.entries(query)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return filteredQuery !== '' ? `?${filteredQuery}` : ''
  } else {
    return '';
  }   
}

export default getQueryString;