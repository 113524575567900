import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Navbar from '../components/Navbar';
import Section from '../components/Section';

import {
  heartCheckNotificationOffById,
  wellnessCheckNotificationOffById
} from '../redux/actions/userActions';

export default function Unsubscribe() {
  const { uuid } = useParams();

  const dispatch = useDispatch();
  let history = useHistory();

  const [heartCheck, setHeartCheck] = useState(true);
  const [wellnessCheck, setWellnessCheck] = useState(true);

  function handleWellnessCheckSwitch() {
    setWellnessCheck(!wellnessCheck);
  }

  function handleHearCheckSwitch() {
    setHeartCheck(!heartCheck);
  }

  const handleSubmit = async () => {
    if (!wellnessCheck) {
      await dispatch(wellnessCheckNotificationOffById(uuid));
    }
    if (!heartCheck) {
      await dispatch(heartCheckNotificationOffById(uuid));
    }
    history.push('/unsubscribe/success');
  }

  return (
  <>
    <Navbar color='primary' textClass='white-text' title='Unsubscribe' hidden />
    <main id='main' className='unsubscribe'>
      <Section>
        <Typography>
          Please check the checkboxes to remain subscribed.
        </Typography>
        <div className='unsubscribe__row'>
          <Typography className='unsubscribe__label'>
            Heart check (every 7 days)
          </Typography>
          <Checkbox
            checked={heartCheck}
            onClick={() => handleHearCheckSwitch()}
          />
        </div>
        <div className='unsubscribe__row'>
          <Typography className='unsubscribe__label'>
            Wellness check (every 15 days)
          </Typography>
          <Checkbox
            checked={wellnessCheck}
            onClick={() => handleWellnessCheckSwitch()}
          />
        </div>
        <div className='unsubscribe__button'>
          <Button className='login__button' variant='contained' color='secondary' onClick={handleSubmit}>Unsubscribe</Button>
        </div>
      </Section>
    </main>
    </>
  );
}
