import React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import BodyMapF from '../../BodyMapF';
import BodyMapB from '../../BodyMapB';

const FrontBodyDisplay = ({ selectedFrom, selectedTo }) => (
  <>
    <Typography className='body-modal__map-label'>Front</Typography>
    <Typography className='body-modal__map-direction-l'>R</Typography>
    <Typography className='body-modal__map-direction-r'>L</Typography>
    <BodyMapF selectedFrom={selectedFrom} selectedTo={selectedTo} disabled />
  </>
)

const BackBodyDisplay = ({ selectedFrom, selectedTo }) => (
  <>
    <Typography className='body-modal__map-label'>Back</Typography>
    <Typography className='body-modal__map-direction-l'>L</Typography>
    <Typography className='body-modal__map-direction-r'>R</Typography>
    <BodyMapB selectedFrom={selectedFrom} selectedTo={selectedTo} disabled />
  </>
)

const ChatBodyMap = ({ msg }) => {
  const { data } = msg;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  if (isMobile) {
    return (
      <>
        <div className='chat-body-map__container'>
          <div className='chat-body-map__map'>
            <FrontBodyDisplay selectedFrom={data.choice.selectedFrom} selectedTo={data.choice.selectedTo} />
          </div>
        </div>
        <div className='chat-body-map__container'>
          <div className='chat-body-map__map'>
            <BackBodyDisplay selectedFrom={data.choice.selectedFrom} selectedTo={data.choice.selectedTo} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className='chat-body-map__container'>
        <div className='chat-body-map__map chat-body-map__map--space'>
          <FrontBodyDisplay selectedFrom={data.choice.selectedFrom} selectedTo={data.choice.selectedTo} />
        </div>
        <div className='chat-body-map__map'>
          <BackBodyDisplay selectedFrom={data.choice.selectedFrom} selectedTo={data.choice.selectedTo} />
        </div>
      </div>
    );
  }
};

ChatBodyMap.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default ChatBodyMap;
