// TODO: Clean up

import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';


const ChatEventProfile = ({ msg }) => {
  const { data } = msg;
  return (
    <div className='eventprofile__container'>
      <img className='eventprofile__img' src='/images/event_profile.png' alt='' />
      <div className='eventprofile__content'>
        <Typography className='eventprofile__title' variant='h5' component='h5'>
          My event profile
        </Typography>
        {
          data.map((d) => (
            <div key={d.value} className='eventprofile__item'>
              <Typography className={d.header ? 'eventprofile__header' : 'eventprofile__text'}>{d.text}</Typography>
            </div>
          ))
        }
      </div>
    </div>
  );
};

ChatEventProfile.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default ChatEventProfile;
