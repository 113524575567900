import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { ReactComponent as SummonHeartIcon } from '../assets/svg/summon_heart_check.svg';
import { ReactComponent as SummonWellnessIcon } from '../assets/svg/summon_wellness_check.svg';
import { triggerWellnessCheckFlow, triggerHeartCheckFlow } from '../redux/actions/chatActions';
import { 
  useMediaQuery,
  useTheme,
} from '@material-ui/core/';
import SignOutModal from './SignOutModal';
import HPModal from './HPModal';

export default function GlobalChatAction({hidden}) {
  const displayGlobal = useSelector(state => state.chat.displayGlobal);
  
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = () => {
    setOpen(!open);
  };

  async function handleHeartCheck() {
    const skip = await dispatch(triggerHeartCheckFlow());
    if (skip) {
      setOpenModal(true);
    }
    setOpen(false);
  };

  async function handleWellnessCheck() {
    const skip = await dispatch(triggerWellnessCheckFlow());
    if (skip) {
      setOpenModal(true);
    }
    setOpen(false);
  }

  const actions = [
    { icon: <SummonWellnessIcon />, name: 'Wellness check', trigger: handleWellnessCheck },
    { icon: <SummonHeartIcon />, name: 'Heart check', trigger: handleHeartCheck },
  ];

  return (
    <div className={isMobile ? 'chat-action chat-action--mobile' : 'chat-action chat-action--desktop'}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Chat Action UI"
        className='speed-dial'
        hidden={hidden || !displayGlobal}
        icon={<SpeedDialIcon />}
        direction='down'
        onClick={handleChange}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            tooltipPlacement = 'right'
            onClick={action.trigger}
          />
        ))}
      </SpeedDial>
      <HPModal
        open={openModal}
        title="Oops!"
        buttonText="Got it!"
        buttonOnClick={()=>{setOpenModal(false)}}
        className="chat-action__modal"
        onClose={()=>{setOpenModal(false)}}
      >
        Sorry. Looks like you are in the middle of something, please try again after you finish the current conversation.
      </HPModal>
    </div>
  );
}