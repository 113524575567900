import React from 'react';


const ChatMessageTypingIndicator = () => {
  return (
    <div className="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default ChatMessageTypingIndicator;
