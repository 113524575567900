import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Button,
  Link,
  Icon,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import MainTemplate from '../templates/MainTemplate';
import Navbar from '../components/Navbar';
import Section from '../components/Section';
import {
  userPasswordUpdate
} from '../services/heartPainApi/user';


const SettingsSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'Password must be at least 9 characters long')
    .required('Required'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 9 characters long')
    .notOneOf([Yup.ref('oldPassword'), null], 'New password can not be the same as the original password')
    .required('Required'),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
});

export default function Password() {
  const user = useSelector(state => state.auth.user);
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const passwordProps = {
    endAdornment: (
      <InputAdornment position='end'>
        <IconButton
          aria-label='toggle password visibility'
          onClick={handleClickShowPassword}
        >
          {showPassword
            ? <img src='/images/VisiblePassword.svg' />
            : <img src='/images/HiddenPassword.svg' />
          }
        </IconButton>
      </InputAdornment>
    ),
  };

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const result = await userPasswordUpdate({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword
    });

    if (result.error) {
      setErrorMessage(result.error);
    } else {
      setSubmitting(false);
      resetForm({ values: initialValues });
      history.push('/settings');
    }
    return;
  };

  return (
    <MainTemplate title="Settings" hasBack hasDrawer={false} className="settings">
      <Section>
        <Typography variant='subtitle1' className="settings__title">
          Edit your name and email
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={SettingsSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Field
                className='settings__input'
                component={TextField}
                name='oldPassword'
                type={showPassword ? 'text' : 'password'}
                label='Old password'
                InputProps={passwordProps}
              />
              <Field
                className='settings__input'
                component={TextField}
                name='newPassword'
                type={showPassword ? 'text' : 'password'}
                label='New password'
                InputProps={passwordProps}
              />
              <Field
                className='settings__input'
                component={TextField}
                name='newPasswordConfirm'
                type={showPassword ? 'text' : 'password'}
                label='New password confirm'
                InputProps={passwordProps}
              />
              <div className='settings__button-container'>
                <Button className='settings__button' type='submit' variant='contained' color='secondary' disabled={!isValid || isSubmitting}>Save changes</Button>
              </div>
              {errorMessage &&
                <Typography className='settings__error-message'>{errorMessage}</Typography>
              }
            </Form>
          )}
        </Formik>
      </Section>
    </MainTemplate>
  );
}
