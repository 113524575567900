import React, { useState, useEffect } from 'react';
import { useHistory, withRouter, useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import SignOutModal from './SignOutModal';
import {
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Typography,
  Icon,
  SvgIcon,
  IconButton,
  Tabs,
  Tab,
  Link,
} from '@material-ui/core/';
import Drawer from './Drawer';
import GlobalChatAction from './GlobalChatAction';
import BackButton from '../components/BackButton';
import { ReactComponent as HamburgerMenuIcon } from '../assets/svg/hamburger_menu.svg';

export default function Navbar({
  textClass,
  color,
  title,
  hasBack,
  hasDrawer,
  hidden,
  hideNavBarInMobile,
}) {

  const [open, setOpen] = useState(false);
  const [openSignOutModal, setOpenSignOutModal] = useState(false);
  const [value, setValue] = React.useState(0);
  const [tabValue, setTabValue] = React.useState(0);
  const history = useHistory();
  const location = useLocation();

  let locationValue;
  let locationTabValue;
  if (location.pathname === '/chat') {
    locationValue = 0;
  } else if (location.pathname.startsWith('/checkins')) {
    locationValue = 1;
  } else if (location.pathname.startsWith('/library')) {
    locationValue = 2;
  } else if (location.pathname === '/settings') {
    locationTabValue = 0;
  }

  const subMenuItemsProfile = [
    {
      text: 'My event profile',
      link: '/profile/event-profile',
    },
    {
      text: 'My health background',
      link: '/profile/health-background',
    },
    {
      text: 'Share my data',
      link: '/data-donation/share',
    },
  ];

  const subMenuItemsAbout = [
    {
      text: 'About at heart',
      link: '/about/at-heart',
    },
    {
      text: 'Terms and conditions',
      link: '/about/terms',
    }
  ];

  function openDrawer() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function a11yProps(index) {
    return {
      id: `article-library-tab-${index}`,
      'aria-controls': `article-library-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const handleChatTab = () => {
    history.push('/chat');
  }

  const handleCheckInTab = () => {
    history.push('/checkins');
  }

  const handleLibraryTab = () => {
    history.push('/library');
  }

  const handleSettingsTab = () => {
    history.push('/settings');
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <>
        <GlobalChatAction hidden={hidden} />
        <AppBar className={`navbar${hideNavBarInMobile ? ' navbar--hide' : ''}`} color={color} position='fixed' elevation={0}>
          <Toolbar>
            {hasBack
              && (
                <BackButton iconClass={textClass} isNavButton isAbsolute />
              )
            }
            <Typography variant='subtitle1' className={hasDrawer ? ['navbar__title navbar__title--drawer', textClass].join(' ') : ['navbar__title', textClass].join(' ')}>
              {title}
            </Typography>
            {hasDrawer
              && (
                <IconButton edge='start' className='white-text' onClick={openDrawer} aria-label='menu'>
                  <SvgIcon className={textClass} viewBox="0 0 27 18">
                    <HamburgerMenuIcon />
                  </SvgIcon>
                </IconButton>
              )
            }
          </Toolbar>
        </AppBar>
        {hasDrawer && <Drawer open={open} handleDrawerClose={handleDrawerClose} />}
      </>
    );
  } else {
    return (
      <AppBar className='navbar-desktop' color={color} position='fixed' elevation={0}>
        <Toolbar>
          <Link className='navbar-desktop__logo-link' component={RouterLink} to="/chat">
            <img className='responsive-image' src='/images/nav_logo.png' alt="Nav Logo" />
          </Link>
          <div className='navbar-desktop__content'>
            <Tabs
              className='navbar-desktop__tabs'
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              scrollButtons='auto'
              aria-label='desktop-nav'
              value={(typeof locationValue !== 'undefined') ? locationValue : false}
            >
              <Tab label="Chatbot" {...a11yProps(0)} onClick={handleChatTab} />
              <Tab label="My check-ins" {...a11yProps(1)} onClick={handleCheckInTab} />
              <Tab label="Library" {...a11yProps(2)} onClick={handleLibraryTab} />
            </Tabs>
            <DropDownMenu menuItem="My profile" subMenuItems={subMenuItemsProfile} idx={0} />
            <DropDownMenu menuItem="About" subMenuItems={subMenuItemsAbout} idx={1} />
            <Tabs
              className='navbar-desktop__tabs'
              onChange={handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              scrollButtons='auto'
              aria-label='desktop-nav'
              value={typeof locationTabValue !== 'undefined' ? locationTabValue : false}
            >
              <Tab label="Settings" {...a11yProps(0)} onClick={handleSettingsTab} />
            </Tabs>
            <Typography className='navbar-desktop__logout'>
              <Link onClick={() => setOpenSignOutModal(true)}>
                Sign out
              </Link>
            </Typography>
            <SignOutModal
              open={openSignOutModal}
              textOnClick={() => setOpenSignOutModal(false)}
              onClose={() => setOpenSignOutModal(false)}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

function DropDownMenu({
  menuItem,
  subMenuItems,
  idx,
}) {

  const location = useLocation();
  let aboutTab = location.pathname.startsWith('/about');
  let profileTab = (location.pathname.startsWith('/data-donation') || location.pathname.startsWith('/profile'));

  const history = useHistory();
  const handleMenuLink = (link) => {
    history.push(link);
  }
  return (
    <div className="dropdown">
      <ul className="dropdown__menu">
        <li className="dropdown__menu-item">
          <div className="dropdown__menu-item-tab">
            <Tabs
              className='navbar-desktop__tabs'
              textColor='primary'
              value={idx ? (aboutTab ? 0 : false) : (profileTab ? 0 : false)}
            >
              <Tab label={menuItem}></Tab>
            </Tabs>
            <div className="dropdown__angle">
              <i className="fas fa-angle-down"></i>
            </div>
          </div>
          <ul className="dropdown__submenu">
            {subMenuItems.map((subMenuItem) => (
              <li key={subMenuItem.text} className="dropdown__submenu-item">
                <Typography className="dropdown__submenu-item">
                  <a onClick={() => { handleMenuLink(subMenuItem.link) }}>{subMenuItem.text}</a>
                </Typography>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}
