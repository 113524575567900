import React, { useEffect, useState } from 'react';
import { Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import BodyDialog from '../BodyDialog';
import ChatTextInput from './ChatTextInput';
import ChatCheckbox from './ChatCheckbox';
import ChatMulSlider from './ChatMulSlider';
import ChatConfirm from './ChatConfirm';
import ChatDatePicker from './ChatDatePicker';
import { scrollToLatestMessage } from '../../redux/actions/chatActions';


const ChatKeyboard = ({ action, onAnswer }) => {
  const dispatch = useDispatch();
  const keyboardDisplay = useSelector(state => state.chat.keyboardDisplay);
  const [slideInKeyboard, setSlideInKeyboard] = useState(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  let inputView = null;

  const handleAnswer = (...args) => {
    if (keyboardDisplay) {
       onAnswer(...args);
    }
  }

  switch (action.type) {
    case 'single_line_text':
      inputView = <ChatTextInput action={action} onAnswer={handleAnswer} />;
      break;
    case 'number':
      inputView = <ChatTextInput action={action} type="number" onAnswer={handleAnswer} />;
      break;
    case 'confirm':
      inputView = <ChatConfirm action={action} onAnswer={handleAnswer} />;
      break;
    case 'single_checkbox':
      inputView = <ChatCheckbox action={action} onAnswer={handleAnswer} />;
      break;
    case 'multiple_checkbox':
      inputView = <ChatCheckbox action={action} onAnswer={handleAnswer} multi />;
      break;
    case 'multiple_slider':
      inputView = <ChatMulSlider action={action} onAnswer={handleAnswer} />;
      break;
    case 'date_picker':
      inputView = <ChatDatePicker action={action} onAnswer={handleAnswer} />;
      break;
    case 'body_map':
      inputView = <BodyDialog onAnswer={handleAnswer} />;
      break;
    default:
      inputView = <div />;
      break;
  }

  const showKeyboard = () => {
    setIsKeyboardVisible(true);
    setTimeout(() => {
      setSlideInKeyboard(() => true);
      dispatch(scrollToLatestMessage);
    }, 300);
  };

  const hideKeyboard = () => {
    setSlideInKeyboard(false);
    setTimeout(() => {
      setIsKeyboardVisible(() => false);
    }, 100);
  };

  useEffect(() => {
    keyboardDisplay ? showKeyboard() : hideKeyboard();
  }, [keyboardDisplay]);

  return (
    <div className={slideInKeyboard ? "chat__keyboard" : "chat__keyboard chat__keyboard--invis"}>
      <Slide className="chat__keyboard--slide" direction="up" in={slideInKeyboard} timeout={500}>
        <form autoComplete="off">
          {inputView}
        </form>
      </Slide>
    </div>
  );
};

ChatKeyboard.propTypes = {
  action: PropTypes.object.isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default ChatKeyboard;
