import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import './assets/scss/index.scss';


ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
