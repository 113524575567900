import React from 'react';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  Icon,
  IconButton
} from '@material-ui/core';

function getIntialState(selectedFrom, selectedTo) {
  const intialState = {
    foreHead: 'body-map--unselected',
    rightJaw: 'body-map--unselected',
    leftJaw: 'body-map--unselected',
    rightNeckFront: 'body-map--unselected',
    leftNeckFront: 'body-map--unselected',
    rightShoulderFront: 'body-map--unselected',
    leftShoulderFront: 'body-map--unselected',
    rightUpperChest: 'body-map--unselected',
    centralUpperChest: 'body-map--unselected',
    leftUpperChest: 'body-map--unselected',
    rightAxillaFront: 'body-map--unselected',
    leftAxillaFront: 'body-map--unselected',
    rightLowerChest: 'body-map--unselected',
    centralLowerChest: 'body-map--unselected',
    leftLowerChest: 'body-map--unselected',
    rightUpperArmFront: 'body-map--unselected',
    epigastric: 'body-map--unselected',
    leftUpperArmFront: 'body-map--unselected',
    rightLowerArmFront: 'body-map--unselected',
    abdomen: 'body-map--unselected',
    leftLowerArmFront: 'body-map--unselected',
    rightLowerArmFront: 'body-map--unselected',
    perineum: 'body-map--unselected',
    rightHand: 'body-map--unselected',
    leftHand: 'body-map--unselected',
    rightThigh: 'body-map--unselected',
    leftThigh: 'body-map--unselected',
    rightShin: 'body-map--unselected',
    leftShin: 'body-map--unselected',
    rightFoot: 'body-map--unselected',
    leftFoot: 'body-map--unselected',
  };

  if (selectedFrom) {
    selectedFrom.forEach((part) => {
      intialState[part] = 'body-map--selected-from';
    });
  }
  if (selectedTo) {
    selectedTo.forEach((part) => {
      intialState[part] = 'body-map--selected-to';
    });
  }
  return intialState;
}

function BodyMapF({ selectedFrom, selectedTo, handleSelection, disabled=false }) {

  const bodyMap = getIntialState(selectedFrom, selectedTo);

  return (
    <svg className={disabled ? 'body-map--disabled' : 'body-map'} width="100%" height="357" viewBox="0 0 585.28 1775.57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id='leftFoot' className={bodyMap.leftFoot} onClick={() => handleSelection('leftFoot')}>
        <path d="M325.07,1761.57c.81,13.76,18.25,19.07,26.67,8.15a61,61,0,0,0,4.52-6.75c7.53-13.06,8.22-25.56,8.51-30.58C367.26,1690,378,1648,376.23,1605.62l-61.87-3.77C317.53,1647.08,321.49,1700.84,325.07,1761.57Z"/>
      </g>
      <g id='LeftShin' className={bodyMap.leftShin} onClick={() => handleSelection('leftShin')}>
        <path d="M373.91,1253.81l-74.23-32.08c.65,82.76,3.17,174.37,10,306.41,1.09,21,2.75,45.75,4.71,73.71l61.87,3.77,0-.82c-3.47-76.48,44-194.18,84.62-376.18l-51.82,23C397.12,1257.36,385.37,1258.17,373.91,1253.81Z"/>
      </g>
      <g id='RightFoot' className={bodyMap.rightFoot} onClick={() => handleSelection('rightFoot')}>
        <path d="M263.4,1761.57c-.81,13.76-18.25,19.07-26.67,8.15a61,61,0,0,1-4.52-6.75c-7.53-13.06-8.22-25.56-8.51-30.58-2.49-42.35-13.21-84.39-11.46-126.77l61.87-3.77C270.94,1647.08,267,1700.84,263.4,1761.57Z"/>
      </g>
      <g id='RightShin' className={bodyMap.rightShin} onClick={() => handleSelection('rightShin')}>
        <path d="M214.56,1253.81l74.23-32.08c-.64,82.76-3.17,174.37-10,306.41-1.09,21-2.75,45.75-4.71,73.71l-61.87,3.77,0-.82c3.47-76.48-44-194.18-84.61-376.18l51.82,23C191.35,1257.36,203.1,1258.17,214.56,1253.81Z"/>
      </g>
      <g id='Forehead' className={bodyMap.foreHead} onClick={() => handleSelection('foreHead')}>
        <path d="M292.84,0h-.27a76.81,76.81,0,0,0-76.81,76.81V123.4c21.82,15.37,49.92,30.76,77,30.76S349.86,137,369.59,123.4l-.2-46.59A76.81,76.81,0,0,0,292.84,0Z"/>
      </g>
      <g id='LeftJaw' className={bodyMap.leftJaw} onClick={() => handleSelection('leftJaw')}>
        <path d="M369.59,123.4V126a95.06,95.06,0,0,1-69.52,91.57,24.32,24.32,0,0,1-7.39.86V154.18C325.07,153.5,355.66,133,369.59,123.4Z"/>
      </g>
      <g id='RightJaw' className={bodyMap.rightJaw} onClick={() => handleSelection('rightJaw')}>
        <path d="M215.76,123.4V126a95.06,95.06,0,0,0,69.52,91.57,24.39,24.39,0,0,0,7.4.86V154.18C260.28,153.5,229.69,133,215.76,123.4Z"/>
      </g>
      <g id='LeftAxillaFront' className={bodyMap.leftAxillaFront} onClick={() => handleSelection('leftAxillaFront')}>
        <path d="M438.86,489.72a83.42,83.42,0,0,0-5.44-28.38L429,397.11l60.32,11.95.34-.15-39.93,75.31-5.05-40.63Z"/>
      </g>
      <g id='LeftUpperArmFront' className={bodyMap.leftUpperArmFront} onClick={() => handleSelection('leftUpperArmFront')}>
        <path d="M548,610.87c18.92-98.51,11.85-172-8.61-224.15l-49.72,22.19-39.93,75.31,20.91,169.32.7,2.51,78.1-32.21A22,22,0,0,1,548,610.87Z"/>
      </g>
      <g id='LeftLowerArmFront' className={bodyMap.leftLowerArmFront} onClick={() => handleSelection('leftLowerArmFront')}>
        <path d="M563.74,899.36S578.19,756.65,576.08,690c-1.15-36.46-10.62-51.08-19-56.85a22,22,0,0,1-7.66-9.32l-78.1,32.21,68.42,241.87,24,1.46Z"/>
      </g>
      <g id='LeftHand' className={bodyMap.leftHand} onClick={() => handleSelection('leftHand')}>
        <path d="M582.2,946.35l-18.46-47-24-1.46v0L524.61,913.4a16.86,16.86,0,0,0-4.68,14l7.32,55.08a3.25,3.25,0,0,0,5.7,1.68,47.07,47.07,0,0,0,10.86-24.45l.4-3.08a2.27,2.27,0,0,1,4.25-.78l10.13,18.92a16.41,16.41,0,0,1,1.67,10.72l-.57,3.08a16.5,16.5,0,0,1-5,9.09l-26.25,24.1a3.31,3.31,0,0,0,2.61,5.72l15.81-1.79,0,13.57a3.24,3.24,0,0,0,5.57,2.26l15.88-16.28a33.22,33.22,0,0,0,8.81-16.77l7.33-37.27A44.62,44.62,0,0,0,582.2,946.35Z"/>
      </g>
      <g id='LeftNeckFront' className={bodyMap.leftNeckFront} onClick={() => handleSelection('leftNeckFront')}>
        <path d="M292.66,330.32a19.06,19.06,0,0,0,8-1.69l98.9-44.83c-27.78-6.29-52.88-9-58.2-9.78,0,0-7.4-70.54-7.73-73.53a97.7,97.7,0,0,1-24.06,13.91c-4.9,1.79-11.15,4.44-16.9,4.06Z"/>
      </g>
      <g id='LeftShoulderFront' className={bodyMap.leftShoulderFront} onClick={() => handleSelection('leftShoulderFront')}>
        <path d="M539.42,386.72c-10.16-25.88-23.62-46.48-38.85-62.21-18.41-19-63.83-32.47-101-40.71L368.33,298,429,397.11l60.32,11.95Z"/>
      </g>
      <g id='LeftUpperChest' className={bodyMap.leftUpperChest} onClick={() => handleSelection('leftUpperChest')}>
        <polygon points="433.42 461.34 342.09 461.34 329.39 315.61 368.32 297.97 429.02 397.11 433.42 461.34"/>
      </g>
      <g id='LeftLowerChest' className={bodyMap.leftLowerChest} onClick={() => handleSelection('leftLowerChest')}>
        <path d="M342.09,461.34l9.7,109.93,50.79,36.9,1.17-13.48,2.19-35.61a12.78,12.78,0,0,1,1.34-4.91,12.93,12.93,0,0,1,2.32-3.28L423,537.31c10.48-14,16.1-30.08,16-47.59a83.42,83.42,0,0,0-5.44-28.38Z"/>
      </g>
      <g id='RightLowerChest' className={bodyMap.rightLowerChest} onClick={() => handleSelection('rightLowerChest')}>
        <path d="M151.77,461.34a83.66,83.66,0,0,0-5.44,28.38,77.58,77.58,0,0,0,16,47.59l13.35,13.58a12.93,12.93,0,0,1,2.32,3.28,12.78,12.78,0,0,1,1.34,4.91l2.19,35.61,1.17,13.48,50.79-36.9,9.7-109.93Z"/>
      </g>
      <g id='RightAxillaFront' className={bodyMap.rightAxillaFront} onClick={() => handleSelection('rightAxillaFront')}>
        <path d="M140.58,443.59l-5.05,40.63L95.6,408.91l.34.15,60.32-11.95-4.4,64.23a83.42,83.42,0,0,0-5.44,28.38Z"/>
      </g>
      <g id='RightUpperArmFront' className={bodyMap.rightUpperArmFront} onClick={() => handleSelection('rightUpperArmFront')}>
        <path d="M37.27,610.87c-18.92-98.51-11.85-172,8.61-224.15L95.6,408.91l39.93,75.31-20.9,169.32-.71,2.51-78.1-32.21A22,22,0,0,0,37.27,610.87Z"/>
      </g>
      <g id='RightLowerArmFront' className={bodyMap.rightLowerArmFront} onClick={() => handleSelection('rightLowerArmFront')}>
        <path d="M21.55,899.36S7.09,756.65,9.2,690c1.15-36.46,10.62-51.08,19-56.85a22,22,0,0,0,7.66-9.32l78.1,32.21L45.51,897.92l-24,1.46Z"/>
      </g>
      <g id='RightHand' className={bodyMap.rightHand} onClick={() => handleSelection('rightHand')}>
        <path d="M3.08,946.35l18.46-47,24-1.46,0,0L60.67,913.4a16.82,16.82,0,0,1,4.68,14L58,982.49a3.25,3.25,0,0,1-5.7,1.68,47.07,47.07,0,0,1-10.86-24.45l-.4-3.08a2.26,2.26,0,0,0-4.24-.78L26.69,974.78A16.48,16.48,0,0,0,25,985.5l.57,3.08a16.45,16.45,0,0,0,5,9.09l26.24,24.1a3.3,3.3,0,0,1-2.6,5.72l-15.82-1.79,0,13.57a3.25,3.25,0,0,1-5.58,2.26L17,1025.25a33.22,33.22,0,0,1-8.81-16.77L.84,971.21A44.53,44.53,0,0,1,3.08,946.35Z"/>
      </g>
      <g id='RightNeckFront' className={bodyMap.rightNeckFront} onClick={() => handleSelection('rightNeckFront')}>
        <path d="M292.62,330.32a19.09,19.09,0,0,1-8-1.69l-98.9-44.83c27.78-6.29,52.89-9,58.2-9.78,0,0,7.4-70.54,7.73-73.53A97.7,97.7,0,0,0,275.7,214.4c4.9,1.79,11.15,4.44,16.91,4.06Z"/>
      </g>
      <g id='RightShoulderFront' className={bodyMap.rightShoulderFront} onClick={() => handleSelection('rightShoulderFront')}>
        <path d="M95.94,409.06l60.32-11.95L217,298,185.69,283.8c-37.15,8.24-82.56,21.68-101,40.71C69.48,340.24,56,360.84,45.86,386.72Z"/>
      </g>
      <g id='RightUpperChest' className={bodyMap.rightUpperChest} onClick={() => handleSelection('rightUpperChest')}>
        <polygon points="156.26 397.11 216.96 297.97 255.89 315.61 243.19 461.34 151.86 461.34 156.26 397.11"/>
      </g>
      <g id='CentralUpperChest' className={bodyMap.centralUpperChest} onClick={() => handleSelection('centralUpperChest')}>
        <path d="M243.19,461.34l12.7-145.73,28.77,13a19.13,19.13,0,0,0,8,1.69h0a19.06,19.06,0,0,0,8-1.69l28.72-13,12.7,145.73Z"/>
      </g>
      <g id='CentralLowerChest' className={bodyMap.centralLowerChest} onClick={() => handleSelection('centralLowerChest')}>
        <path d="M351.88,571.27l-9.79-109.93h-98.9l-9.7,109.93L288.39,530a5.42,5.42,0,0,1,6.45,0Z"/>
      </g>
      <g id='Epigastric' className={bodyMap.epigastric} onClick={() => handleSelection('epigastric')}>
        <path d="M182.75,608.17l50.74-36.9L288.43,530a5.42,5.42,0,0,1,6.45,0l107.74,78.26Z"/>
      </g>
      <g id='Perineum' className={bodyMap.perineum} onClick={() => handleSelection('perineum')}>
        <path d="M232.34,826.84l60,51.86a2.81,2.81,0,0,0,3.7,0L356,826.84Z"/>
      </g>
      <g id='LeftThigh' className={bodyMap.leftThigh} onClick={() => handleSelection('leftThigh')}>
        <path d="M294.22,879.39a29.07,29.07,0,0,0,4.06-.29l9.51-1.33c3.43-.48,6.28,3.58,5.39,6.92-4.32,16.17-11.85,38.57-12,65.91-.71,105.5-2.15,183.31-1.47,271.07l74.23,32.08c11.46,4.36,23.21,3.55,35.09-2.16l51.82-23q3.42-15.37,6.79-31.36c58.48-279,26.05-371.11,9.52-409.44A394.89,394.89,0,0,0,452,739.07l-96,87.77L296.05,878.7a2.82,2.82,0,0,1-1.85.69Z"/>
      </g>
      <g id='RightThigh' className={bodyMap.rightThigh} onClick={() => handleSelection('rightThigh')}>
        <path d="M294.18,879.39a29.18,29.18,0,0,1-4.06-.29l-9.51-1.33c-3.42-.48-6.28,3.58-5.39,6.92,4.32,16.17,11.85,38.57,12,65.91.71,105.5,2.15,183.31,1.47,271.07l-74.23,32.08c-11.46,4.36-23.21,3.55-35.08-2.16l-51.82-23q-3.44-15.37-6.8-31.36c-58.47-279-26.05-371.11-9.52-409.44a394.89,394.89,0,0,1,25.18-48.69l96,87.77,59.91,51.86a2.82,2.82,0,0,0,1.85.69Z"/>
      </g>
      <g id='Abdomen' className={bodyMap.abdomen} onClick={() => handleSelection('abdomen')}>
        <path d="M356,826.84l96-87.77a425.54,425.54,0,0,0-53.09-69.62l3.76-61.28H182.75l4.75,61.28c-15.33,16.35-33,40.08-51.05,69.62l95.89,87.77Z"/>
      </g>
    </svg>
  );
}

export default BodyMapF;
