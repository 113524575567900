import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  Typography,
} from '@material-ui/core';

import ButtonListItem from '../components/ButtonListItem';
import Section from '../components/Section';
import MainTemplate from '../templates/MainTemplate';

const MyProfile = () => {
  const history = useHistory();

  const handleEventProfile = () => {
    history.push('/profile/event-profile');
  }
  const handleHealthBackground = () => {
    history.push('/profile/health-background');
  }

  const handleShare = () => {
    history.push('/data-donation/share');
  }

  return (
    <MainTemplate title='My profile' hasBack={true} hasDrawer={false}>
      <Section>
        <List className='list-view__list'>
          <ButtonListItem onClick={handleEventProfile} text='My event profile' className='black-text' hasIcon />
          <ButtonListItem onClick={handleHealthBackground} text='My health background' className='black-text' hasIcon />
          <ButtonListItem onClick={handleShare} text='Share my data' className='black-text' hasIcon />
        </List>
      </Section>
    </MainTemplate>
  )
};

export default MyProfile;
