import {
  getDataDonationForms,
  getDataDonationQuestions,
  saveDataDonationForm,
  submitDataDonationForm,
} from '../../services/heartPainApi/dataDonation';

export const GET_FORMS = 'GET_FORMS';
export const GET_CURRENT_FORM = 'GET_CURRENT_FORM';
export const SAVE_FORM_DATA = 'SAVE_FORM_DATA';
export const SAVE_FORM_DATA_ERROR = 'SAVE_FORM_DATA_ERROR';
export const GET_CURRENT_ANSWERS = 'GET_CURRENT_ANSWERS';
export const GET_FORM_SUBMIT_STATE = 'GET_FORM_SUBMIT_STATE';

export const getForms = forms => ({
  type: GET_FORMS,
  forms,
});

export const getCurrentForm = (questions, currentForm, saveData, submitState) => ({
  type: GET_CURRENT_FORM,
  currentForm,
  questions,
  saveData,
  submitState,
});

export const getCurrentAnswers = (saveData) => ({
  type: GET_CURRENT_ANSWERS,
  saveData,
});

export const getSubmitState = (submitState) => ({
  type: GET_FORM_SUBMIT_STATE,
  submitState,
})

export function fetchForms(id) {
  return async dispatch => {
    const { forms } = await getDataDonationForms();
    dispatch(getForms(forms));
  }
};

export function fetchCurrentForm(id) {
  return async dispatch => {
    const { questions, currentForm, saveData, submitState } = await getDataDonationQuestions(id);
    dispatch(getCurrentForm(questions, currentForm, saveData, submitState));
  }
};

export function saveFormAction(form, data) {
  const postData = { answers: data };

  return async (dispatch, getState) => {
    const saveData = await saveDataDonationForm(form, postData);
    dispatch(getCurrentAnswers(saveData));
  };
};

export function submitFormAction(id) {
  return async dispatch => {
    const state = await submitDataDonationForm(id);
    dispatch(getSubmitState(state));
  }
};