import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useTheme,
  useMediaQuery,
  Typography,
  Icon,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Section from '../components/Section';
import BackButton from '../components/BackButton';
import MainTemplate from './MainTemplate';

import { favouriteSelectedArticle, unfavouriteSelectedArticle } from '../redux/actions/articleActions';

const SurveyFormContent = ({ image, children }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <BackButton />
      {image && <img className={isMobile ? 'survey-form__picture' : 'survey-form-desktop__picture'} src={image} alt='survey_featured_image' />}
      {children}
    </>
  )
}

const SurveyFormTemplate = ({
  image,
  children,
}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <main id='main' className='survey-form'>
        <SurveyFormContent image={image} children={children} />
      </main>
    );
  } else {
    return (
      <MainTemplate>
        <div className='survey-form-desktop'>
          <SurveyFormContent image={image} children={children} />
        </div>
      </MainTemplate>
    );
  }
};

SurveyFormTemplate.propTypes = {
  image: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default SurveyFormTemplate;
