import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Link,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { forgetAction } from '../../redux/actions/authActions';
import BackButton from '../../components/BackButton';
import Section from '../../components/Section';
import DesktopSignInTemplate from '../../templates/DesktopSignInTemplate';


const ForgetSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const initialValues = {
  email: '',
};

const ForgetForm = ({ initialValues, ForgetSchema, onSubmit, errorMessage }) => (
  <>
    <Typography className='login__title' variant='h4' component='h4'>
      Forgot your password?
    </Typography>
    <Formik
      initialValues={initialValues}
      validationSchema={ForgetSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Typography className="login__description">
            Can't remember your password? No worries, just enter the email address you used to sign up and we'll email you a link to reset it. Please note that link will expire in 24 hours.
          </Typography>
          <Field
            className='login__input'
            component={TextField}
            name='email'
            type='email'
            label='Email'
          />

          <div className='login__submit'>
            {errorMessage &&
              <Typography className='login__error-message'>{errorMessage}</Typography>
            }
            <Button className='login__button' type='submit' variant='contained' color='secondary' disabled={isSubmitting}>Send email reset link</Button>
            <Typography>
              <Link className='login__text-button' component={RouterLink} to="/account/login">Cancel</Link>
            </Typography>
          </div>
        </Form>
      )}
    </Formik>
  </>
);

const Forget = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(state => state.auth.errorMessage);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = async (values, { setSubmitting }) => {
    const result = await dispatch(forgetAction(values));
    if (result) {
      setSubmitting(false);
    } else {
      history.push('/account/forget/success');
    }
  };

  if (isMobile) {
    return (
      <div className='login'>
        <BackButton />
        <img className='login__logo' src='/images/login_logo.png' alt='hp-logo' />
        <Section>
          <ForgetForm initialValues={initialValues} ForgetSchema={ForgetSchema} onSubmit={onSubmit} errorMessage={errorMessage} />
        </Section>
      </div>
    );
  } else {
    return (
      <DesktopSignInTemplate picture='/images/login_logo.png' left={6} right={6}>
        <Section className='login-desktop__section'>
          <ForgetForm initialValues={initialValues} ForgetSchema={ForgetSchema} onSubmit={onSubmit} errorMessage={errorMessage} />
        </Section>
      </DesktopSignInTemplate>
    );
  }
};

export default Forget;
