import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DASHBOARD_DOMAIN } from '../constant';

import { authCheckAction } from '../redux/actions/authActions';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const user = useSelector(({ auth }) => auth.user);
  const [authDetermined, setAuthDetermined] = React.useState(isAuthenticated);

  React.useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authCheckAction(() => setAuthDetermined(true)));
    }
  }, [isAuthenticated]); // eslint-disable-line

  return (
    authDetermined
      ? <Route
          {...rest}
          render={props => {
            if (user?.role === 'PATIENT') {
              if (user.firstLogin) {
                window.location.href = '/account/welcome';
                return null;
              }
              return <Component {...props} />;
            } else if (isAuthenticated) {
              window.location.href = DASHBOARD_DOMAIN;
              return null;
            }
            return (
              <Redirect to={
                {
                  pathname: '/account/login',
                  state: {
                    from: props.location,
                  },
                }
              }
              />
            );
          }}
        />
      : null
  );
};
export default ProtectedRoute;
