import React, { Component } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  Typography,
  Button,
  Link,
  Icon,
  IconButton,
  Fab,
  Tooltip,
} from '@material-ui/core';
import BodyMapF from '../components/BodyMapF';
import BodyMapB from '../components/BodyMapB';
import ChatSendButton from '../components/chat/ChatSendButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';


function BodyMapEdit({ isFirst, fromData, toData, handleClose, handleBodyChange }) {

  const [selectedFrom, setSelectedFrom] = React.useState(fromData);
  const [selectedTo, setSelectedTo] = React.useState(toData);
  const [first, setFirst] = React.useState(isFirst);
  const [front, setFront] = React.useState(true);
  const [noTravel, setNoTravel] = React.useState(false);

  const handleFlip = () => {
    setFront(!front);
  }

  function removeElementFromArray(array, element) {
    return array.filter(e => e !== element);
  }

  function handleNoTravel() {
    setNoTravel(true);
    setSelectedTo([]);
  }

  function handleSelection(part) {
    if (first) {
      if (selectedTo.includes(part)) {
        let newSelectedTo = selectedTo;
        newSelectedTo = removeElementFromArray(newSelectedTo, part);
        setSelectedTo(newSelectedTo);
      }
      if (selectedFrom.includes(part)) {
        let newSelectedFrom = selectedFrom;
        newSelectedFrom = removeElementFromArray(newSelectedFrom, part);
        setSelectedFrom(newSelectedFrom);
      } else {
        setSelectedFrom([...selectedFrom, part]);
      }
    } else if (!selectedFrom.includes(part)) {
      setNoTravel(false);
      if (selectedTo.includes(part)) {
        let newSelectedTo = selectedTo;
        newSelectedTo = removeElementFromArray(newSelectedTo, part);
        setSelectedTo(newSelectedTo);
      } else {
        setSelectedTo([...selectedTo, part]);
      }
    }
  }

  return (
    <div className='body-modal'>
      <div className='body-modal__content'>
        <TransformWrapper
          doubleClick={{ disabled: true }}
          centerOnInit={true}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <div className='body-modal__text'>
                <IconButton
                  className='body-modal__back-button'
                  aria-label='back'
                  onClick={() => {
                    resetTransform();
                    handleClose();
                  }}
                >
                  <Icon>arrow_back</Icon>
                </IconButton>
                <Typography>
                  {first ?
                    'Where did you experience this pain/discomfort? Select the starting location(s) below'
                  :
                    'Did your pain/discomfort spread or move? If so, select the location(s) it moved to'
                  }
                </Typography>
              </div>
              <div className='body-modal__body-map'>
                <Typography className='body-modal__map-label'>{front? 'Front' : 'Back'}</Typography>
                <Typography className='body-modal__map-direction-l'>{front? 'R' : 'L'}</Typography>
                <Typography className='body-modal__map-direction-r'>{front? 'L' : 'R'}</Typography>
                <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }} contentStyle={{ width: '100%', height: '100%' }}>
                  {front ?
                    <BodyMapF selectedFrom={selectedFrom} selectedTo={selectedTo} handleSelection={handleSelection} />
                    :
                    <BodyMapB selectedFrom={selectedFrom} selectedTo={selectedTo} handleSelection={handleSelection} />
                  }
                </TransformComponent>
                <Tooltip title="Flip Body Image">
                  <Fab
                    size="small"
                    aria-label="flip"
                    className='body-modal__flip-fab'
                    onClick={() => {
                      resetTransform();
                      handleFlip();
                    }}
                  >
                    <Icon>cached</Icon>
                  </Fab>
                </Tooltip>
                <Fab size="small" aria-label="zoom-in" className='body-modal__zoom-in-fab' onClick={() => zoomIn()}>
                  <ZoomInIcon />
                </Fab>
                <Fab size="small" aria-label="zoom-out" className='body-modal__zoom-out-fab' onClick={() => zoomOut()}>
                  <ZoomOutIcon />
                </Fab>
              </div>
              <div className='body-modal__keyboard'>
                <ChatSendButton
                  type='confirm'
                  text='Next'
                  disabled={selectedFrom.length === 0}
                  onClick={() => {
                    resetTransform();
                    handleBodyChange(selectedFrom, selectedTo);
                    handleClose();
                  }}
                />
              </div>
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};

export default BodyMapEdit;
