import { ChatMessageFrom, ChatMessageType } from '../constant';


const DEFAULT_MESSAGE_DELAY_MS = 600;
const MILLISECONDS_PER_CHARACTER = 50;
const MAX_MESSAGE_DELAY_MS = 4000;

export const calculateMessageDisplayDelay = message => {
  if (Number.isInteger(message?.data?.typingTimeInMs)) {
    return message.data.typingTimeInMs;
  } else if (message.from === ChatMessageFrom.USER) {
    return DEFAULT_MESSAGE_DELAY_MS;
  }

  let delay = 0;
  switch (message.type) {
    case ChatMessageType.TEXT:
      const messageTyingTime = message.text.length * MILLISECONDS_PER_CHARACTER;
      delay = Math.min(Math.max(200, messageTyingTime), MAX_MESSAGE_DELAY_MS);
      break;
    case ChatMessageType.TIMESTAMP:
      delay = 0;
      break;
    default:
      delay = DEFAULT_MESSAGE_DELAY_MS;
  }

  return delay;
};
