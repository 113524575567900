import React from 'react';
import PropTypes from 'prop-types';

import ChatSendButton from './ChatSendButton';
import useEventListener from '../../util/useEventListener';


const ChatConfirm = ({ action, onAnswer, disabled }) => {
  const answer = action.data.text;

  useEventListener('keypress', (event) => {
    if (disabled || event.key !== 'Enter') {
      return;
    }

    onAnswer({ answer });
  });

  return (
    <ChatSendButton type='confirm' text={answer} onClick={() => onAnswer({ answer })} disabled={disabled} />
  );
};

ChatConfirm.propTypes = {
  action: PropTypes.object.isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default ChatConfirm;
