import React from 'react';
import {
  useTheme,
  useMediaQuery,
  Typography,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Section from '../../components/Section';

const Welcome = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNextButton = () => {
    history.push('/chat');
  };

  return (
    <div className={isMobile ? 'login' : 'login-desktop__welcome'}>
      <img className={isMobile ? 'login__welcome-image' : 'login-desktop__welcome-image'} src='/images/welcome.png' alt='welcome-png' />
      <div className='login__welcome-content'>
        <Section>
          <Typography className='login__welcome-title' variant='h5' component='h5'>
            {"So glad you're here!"}
          </Typography>
          <Typography className='login__welcome-paragraph' variant='body1' paragraph>
            Welcome to <strong>at heart</strong>{" :) We're super excited to go through this journey with you. We'll be sharing resources and tools with you to keep your heart healthy and improve your wellness. Ready to get started?"}
          </Typography>
          <Button className='login__button' type='submit' variant='contained' color='secondary' onClick={handleNextButton}>
            {"Let's go!"}
          </Button>
        </Section>
      </div>
    </div>
  );
}

export default Welcome;
