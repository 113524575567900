import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { authCheckAction } from '../redux/actions/authActions';

const UnprotectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const [authDetermined, setAuthDetermined] = React.useState(isAuthenticated);

  const history = useHistory();

  React.useEffect(() => {
    if (!isAuthenticated) {
      dispatch(authCheckAction(() => setAuthDetermined(true)));
    }
  }, [isAuthenticated]); // eslint-disable-line

  return (
    authDetermined
      ? <Route
          {...rest}
          render={props => {
            if (!isAuthenticated) {
              return <Component {...props} />;
            } else if (isAuthenticated && history.location.state?.from?.pathname && !history.location.state?.from?.pathname.startsWith('/account/')) {
              return (
                <Redirect to={
                  {
                    pathname: history.location.state.from.pathname + history.location.state.from.search,
                  }
                }
                />
              );
            }
            return (
              <Redirect to={
                {
                  pathname: '/chat',
                }
              }
              />
            );
          }}
        />
      : null
  );
};
export default UnprotectedRoute;
