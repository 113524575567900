import { combineReducers } from 'redux';
import chatReducer from './chatReducer';
import authReducer from './authReducer';
import articleReducer from './articleReducer';
import dataDonationReducer from './dataDonationReducer';
import checkInReducer from './checkInReducer';

const rootReducer = combineReducers({
  chat: chatReducer,
  auth: authReducer,
  article: articleReducer,
  dataDonation: dataDonationReducer,
  checkIn: checkInReducer
});

export default rootReducer;
