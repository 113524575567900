import { api } from './api';


async function login(user) {
  try {
    const { data } = await api.post(`/auth/login`, user);
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

async function register(user) {
  try {
    const { data } = await api.post(`/auth/register`, user);
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

async function registerCheck(registerData) {
  try {
    const { data } = await api.post(`/auth/registerCheck`, registerData);
    return null;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

function logout() {
  api.post(`/auth/logout`);
}

async function forget(userData) {
  try {
    const { data } = await api.post(`/auth/forget`, userData);
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

async function reset(password, targetToken) {
  try {
    const { data } = await api.post('/auth/reset', {
      token: targetToken,
      password,
    });
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

async function authenticated() {
  try {
    const { status, data } = await api.post('/auth/authenticated');
    return ({
      isAuthenticated: status === 200,
      data,
    });
  } catch (e) {
    return false;
  }
}

async function accountVerify(targetToken) {
  try {
    const { data } = await api.post('/auth/accountVerify', targetToken);
    return true;
  } catch (e) {
    return false;
  }
}

export {
  authenticated,
  login,
  logout,
  register,
  forget,
  reset,
  registerCheck,
  accountVerify
};
