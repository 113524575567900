// TODO: This needs to be cleaned up

import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import ChatSendButton from './ChatSendButton';

const ChatTextInput = ({ action, type = 'text', onAnswer }) => {
  const [name, setName] = useState('');

  return (
    <div className='chat-textinput'>
      <ChatSendButton onClick={() => onAnswer({ answer: name })} disabled={name.length === 0} />
      <TextField
        id="chat-text-field"
        type={type}
        inputProps={{ autoComplete: 'off' }}
        autoComplete="off"
        className='chat-textinput__field'
        variant="outlined"
        onChange={e => setName(e.target.value)}
        placeholder={action.data.placeholder}
        onKeyDown={event => event.key === 'Enter' && onAnswer({ answer: name })} />
    </div>
  );
};

ChatTextInput.propTypes = {
  action: PropTypes.object.isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default ChatTextInput;
