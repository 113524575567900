import React, { useState, useEffect } from 'react';
import {
  useMediaQuery,
  useTheme,
  Fab,
  TextField,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Section from '../../components/Section';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveIcon from '@material-ui/icons/Save';
import MainTemplate from '../../templates/MainTemplate';
import BackButton from '../../components/BackButton';
import HPModal from '../../components/HPModal';

import {
  getNote,
  updateNote,
  deleteNote,
} from '../../services/heartPainApi/note';

const CheckInNote = () => {

  let { uuid } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let history = useHistory();
  const [editMode, setEditMode] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [date, setDate] = useState('');

  let today = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    (async () => {
      const targetNote = await getNote(uuid);
      if (!targetNote) {
        history.push('/checkins');
      } else {
        const { title, content, createdAt } = targetNote;
        setTitle(title);
        setContent(content);
        setDate(format(new Date(createdAt), 'yyyy-MM-dd'));
      }
    })()
  }, []);

  const handleNoteUpdate = async () => {
    await updateNote(
      uuid,
      {
        title: title,
        content: content
      }
    );
  }

  const handleNoteDelete = async () => {
    await deleteNote(uuid);
    history.push('/checkins');
  }

  const handleTitle = (e) => {
    setTitle(e.target.value);
  }

  const handleContent = (e) => {
    setContent(e.target.value);
  }

  return (
    <MainTemplate hasBack={true} hasPadding={true} title='My check-ins'>
      {!isMobile &&
        <div className='check-in__top'>
          <BackButton />
        </div>
      }
      <div className='note__fab'>
        {editMode ?
          <Fab color='primary' aria-label='edit' onClick={() => {
            handleNoteUpdate();
            setEditMode(!editMode);
          }}>
            <SaveIcon />
          </Fab>
          :
          <>
            <Fab color='primary' aria-label='edit' onClick={() => setEditMode(!editMode)}>
              <EditIcon />
            </Fab>
            <Fab color='primary' aria-label='edit' onClick={() => setOpenModal(true)}>
              <DeleteOutlineIcon />
            </Fab>
            <HPModal
              open={openModal}
              image='/images/note_delete_image.png'
              title='Delete note'
              buttonText='Yup, delete it'
              buttonOnClick={handleNoteDelete}
              className='data-donation__modal'
              text="Actually, I'll will keep it"
              textOnClick={() => setOpenModal(false)}
              onClose={() => setOpenModal(false)}
            >
              Are you sure you want to delete this note?
              </HPModal>
          </>
        }
      </div>
      <Section>
        <Typography className='note__date'>
          {date === today ?
            'Today'
            :
            date
          }
        </Typography>
        <TextField
          className='note__input-title'
          value={title}
          onChange={(e) => handleTitle(e)}
          multiline
          InputProps={{ disableUnderline: true }}
          disabled={!editMode}
        />
        <TextField
          className='note__input-content'
          value={content}
          onChange={(e) => handleContent(e)}
          multiline
          InputProps={{ disableUnderline: true }}
          disabled={!editMode}
        />
      </Section>
    </MainTemplate>
  );
};

export default CheckInNote;
