import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Tabs,
  AppBar,
  Tab,
  Box,
  Grid,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { fetchArticles, fetchFavouriteArticles } from '../../redux/actions/articleActions';
import Section from '../../components/Section';
import ArticleCard from '../../components/ArticleCard';
import ArticleList from '../../components/ArticleList';
import updateImagePath from '../../util/getImagePath';
import MainTemplate from '../../templates/MainTemplate';
import SearchBar from '../../components/SearchBar';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`article-library-tabpanel-${index}`}
      aria-labelledby={`article-library-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `article-library-tab-${index}`,
    'aria-controls': `article-library-tabpanel-${index}`,
    key: `article-library-tab-${index}`,
  };
}

const Library = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { category } = useParams();
  const favouriteArticles = useSelector(state => state.article.favouriteArticles);
  const categoryArticles = useSelector(state => state.article.articles);

  let defaultTabIndex = 0;
  if (category) {
    const index = categoryArticles.findIndex(c => c.slug === category);
    if (index !== -1) {
      defaultTabIndex = index + 1;
    }
  }

  const [tabIndex, setTabIndex] = React.useState(defaultTabIndex);

  useEffect(() => {
    dispatch(fetchFavouriteArticles());
    dispatch(fetchArticles());
  }, []);

  useEffect(() => {
    const index = categoryArticles.findIndex(c => c.slug === category);
    if (index !== -1) {
      setTabIndex(index + 1);
    }
  }, [category, categoryArticles]);

  const handleChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
    if (tabIndex > 0) {
      history.push(`/library/${categoryArticles[tabIndex - 1].slug}`);
    }
  };

  const handleSearchBar = async (searchValue) => {
    if (searchValue) {
      history.push(`/library/search?s=${searchValue}`);
    }
  };

  return (
    <MainTemplate title='Library' isWide={true} hasPadding={true}>
        <Section>
          <SearchBar handleSearch={handleSearchBar} />
          <AppBar position='static' color='default' component='div' elevation={0}>
            <Tabs
              className='library__tabs'
              value={tabIndex}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='article library'
            >
              <Tab label='Your favorites' {...a11yProps(0)} disableRipple />
              {categoryArticles.map((category, index) => (
                <Tab label={category.name} {...a11yProps(index+1)} disableRipple />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel value={tabIndex} index={0} className="library__tab-panel">
            {favouriteArticles.length > 0 ?
              <Typography color='textSecondary'>
                Your favorites
              </Typography>
              : <div className='library__no-faves-tab'>
              <img className='library__cover' src='/images/library_image.png' alt='library-no-faves-image' />
              <Typography align="center" variant='h5' component='h5'>
                No faves yet
              </Typography>
              <Typography className='library__no-faves-content' align="center" variant='body2' paragraph>
                Here's where all the articles you love will be saved for you to check out again later :)<br/>
                Just hit the favorite button in the article and it'll show up here!
              </Typography>
              </div>
            }
            <Grid container spacing={2}>
            {favouriteArticles.map((article) => (
              //TODO: Use text for subtitle (Category)
              <Grid key={article.title} item xs={12} sm={6} md={4}>
                <ArticleCard image={updateImagePath(article.thumbnail)} title={article.title} link={'/library/article/' + article.slug} type={article.type} />
              </Grid>
            ))}
            </Grid>
          </TabPanel>
          {categoryArticles.map((category, index) => (
            <TabPanel value={tabIndex} index={index + 1} key={category.id} className="library__tab-panel">
              <ArticleList categories={category.subCategories} description={category.description} first />
            </TabPanel>
          ))}
        </Section>

    </MainTemplate>
  );
};

export default Library;
