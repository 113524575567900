import { api } from './api';

async function turnOnHeartCheckNotification(user) {
  const { data, status } = await api.post('/notifyHeartCheck/on', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function turnOffHeartCheckNotification(user) {
  const { data, status } = await api.post('/notifyHeartCheck/off', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function turnOffHeartCheckNotificationById(uuid) {
  const { data, status } = await api.post(`/notifyHeartCheck/off/${uuid}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function turnOnWellnessCheckNotification(user) {
  const { data, status } = await api.post('/notifyWellnessCheck/on', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function turnOffWellnessCheckNotification(user) {
  const { data, status } = await api.post('/notifyWellnessCheck/off', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function turnOffWellnessCheckNotificationById(uuid) {
  const { data, status } = await api.post(`/notifyWellnessCheck/off/${uuid}`);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function agreeDataDonationConsent(user) {
  const { data, status } = await api.post('/dataDontaiton/consent/agree', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function disagreeDataDonationConsent(user) {
  const { data, status } = await api.post('/dataDontaiton/consent/disagree', user);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function userSettingsUpdate(settingsData) {
  const { data, status } = await api.post('/settings/update', settingsData);
  if (status !== 200) {
    return null;
  }
  return data;
}

async function userPasswordUpdate(passwordData) {
  try {
    const { data } = await api.put('/settings/password/update', passwordData);
    return data;
  } catch (e) {
    return {
      error: e?.response?.data?.msg
        ? e.response.data.msg
        : 'Unknown error, please try again later'
    };
  }
}

export {
  turnOnHeartCheckNotification,
  turnOffHeartCheckNotification,
  turnOnWellnessCheckNotification,
  turnOffWellnessCheckNotification,
  agreeDataDonationConsent,
  disagreeDataDonationConsent,
  turnOffWellnessCheckNotificationById,
  turnOffHeartCheckNotificationById,
  userSettingsUpdate,
  userPasswordUpdate,
};
