import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMediaQuery,
  useTheme,
  Typography,
  ButtonGroup,
  Button,
  Link
} from '@material-ui/core';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import Section from '../../components/Section';
import DataDonationTemplate from '../../templates/DataDonationTemplate';
import { dataDonationConsentAgree, dataDonationConsentDisagree } from '../../redux/actions/userActions';
import BackButton from '../../components/BackButton';
import HPModal from '../../components/HPModal';


const Consent = () => {
  const hasConsented = useSelector(state => state.auth.user.dataDonationConsent);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDisagreeModal, setOpenDisagreeModal] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  if (hasConsented) {
    return (
      <Redirect to={
        {
          pathname: '/data-donation/progress',
          state: { from: location.pathname },
        }
      }
      />
    );
  }

  async function agreeDataDonationConsent() {
    const consented = await dispatch(dataDonationConsentAgree());
    if (consented) {
      history.push('/data-donation/progress');
    }
  };

  async function disagreeDataDonationConsent() {
    dispatch(dataDonationConsentDisagree());
    setOpenDisagreeModal(false);
  }

  return (
    <DataDonationTemplate title='Consent' hasBack={true} buttonGroup='bottom' hasPadding={true}>
      {!isMobile &&
        <div className='data-donation__top'>
          <BackButton />
        </div>
      }
      <Section>
        <Typography className='data-donation__title' variant='h5' component='h5'>
          at heart Data Donation Consent
        </Typography>
        <Typography className='data-donation__subtitle' variant='h6' component='h5'>
          Welcome
        </Typography>
        <Typography className='data-donation__paragraph' variant='body1' paragraph>
          Women’s hearts are affected by menstruation, pregnancy, and menopause. We are interested in collecting more information on:
            <ol>
              <li><strong>Socio-economics</strong> (marital status, education, etc.)</li>
              <li><strong>Demographic Factors</strong> (smoking, diabetes, etc.)</li>
              <li><strong>Gynecological/Obstetrics</strong> history (menstrual cycles, pregnancies, menopause, etc.)</li>
              <li><strong>Identity and Society</strong> (physical activity, etc.)</li>
              <li><strong>Medications</strong> and</li>
              <li><strong>Other conditions</strong> (arthritis, depression, etc.)</li>
            </ol>
          You can decide when and how much you want to share.
        </Typography>
        <Typography className='data-donation__subtitle' variant='h6' component='h5'>
          Pay Attention!
        </Typography>
        <Typography className='data-donation__paragraph' variant='body1' paragraph>
          This information is collected only for the development and usability testing of <strong>at heart</strong>. All information is kept strictly confidential and only available to the Principal Investigator (PI) and members of the <strong>at heart</strong> investigative team.
        </Typography>
        <Typography className='data-donation__paragraph' variant='body1' paragraph>
          To ensure privacy, all personally identifying information is stored separately from health data entered in the <strong>at heart</strong> WebApp. No personal information is transmitted after the initial set-up. For security issues, information that is transmitted will be sent securely via encrypted HTTPS connection, preventing interception by a third party.
        </Typography>
        <Typography className='data-donation__paragraph' variant='body1' paragraph>
          If you have questions, please contact the study coordinator at <a href="mailto:women.atheart@utoronto.ca">women.atheart@utoronto.ca</a>. Keep in mind that communication via email is not absolutely secure. Please do not share personal information on email.
        </Typography>
      </Section>
      <ButtonGroup variant="contained" orientation='vertical' className={`data-donation__links bottom${isMobile ? '' : ' data-donation__links--desktop'}`} aria-label='vertical contained button group'>
        <Button className='text-button data-donation__button' color='primary' onClick={() => setOpenConfirmModal(true)}>
          I agree
        </Button>
        <Link onClick={() => setOpenDisagreeModal(true)}>
          <Typography className='text-link' variant='body1'>
            <strong>I disagree</strong>
          </Typography>
        </Link>
      </ButtonGroup>
      <HPModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        image='/images/submitted_image.png'
        title='Review'
        buttonText='Agree'
        text='Cancel'
        buttonOnClick={agreeDataDonationConsent}
        textOnClick={() => setOpenConfirmModal(false)}
      >
        By agreeing, you confirm that 1) you have read the consent form, and 2) wish to share more information in the <strong>at heart</strong> WebApp.
      </HPModal>
      <HPModal
        open={openDisagreeModal}
        onClose={() => setOpenDisagreeModal(false)}
        image='/images/submitted_image.png'
        buttonText='Okay'
        buttonOnClick={disagreeDataDonationConsent}
        textOnClick={() => setOpenDisagreeModal(false)}
      >
        Alright, when you want to donate some data, feel free to come back here
      </HPModal>
    </DataDonationTemplate>
  );
};

export default Consent;
