import { 
  GET_ALL_CHECK_IN_ENTRY,
  GET_MORE_ALL_CHECK_IN_ENTRY,
  GET_HEART_CHECK_IN_ENTRY,
  GET_MORE_HEART_CHECK_IN_ENTRY,
  GET_WELLNESS_CHECK_IN_ENTRY,
  GET_MORE_WELLNESS_CHECK_IN_ENTRY,
  GET_CURRENT_ALL_CHECK_IN_ENTRY,
  GET_CURRENT_HEART_CHECK_IN_ENTRY,
  GET_CURRENT_WELLNESS_CHECK_IN_ENTRY,
  GET_NOTES_CHECK_IN_ENTRY,
  GET_MORE_NOTES_CHECK_IN_ENTRY,
} from '../actions/checkInActions';

const defaultState = {
  currentCheckIn: null,
  checkIns: [],
  firstDate: null,
};

const checkInReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_CHECK_IN_ENTRY:
    case GET_HEART_CHECK_IN_ENTRY:
    case GET_WELLNESS_CHECK_IN_ENTRY:
    case GET_NOTES_CHECK_IN_ENTRY:
      return {
        ...state,
        checkIns: [...action.data.data],
        firstDate: action.data.firstDate,
      };

    case GET_MORE_ALL_CHECK_IN_ENTRY:
    case GET_MORE_HEART_CHECK_IN_ENTRY:
    case GET_MORE_WELLNESS_CHECK_IN_ENTRY:
    case GET_MORE_NOTES_CHECK_IN_ENTRY:
      return {
        ...state,
        checkIns: [...state.checkIns, ...action.data.data],
        firstDate: action.data.firstDate,
      };

    case GET_CURRENT_ALL_CHECK_IN_ENTRY:
    case GET_CURRENT_HEART_CHECK_IN_ENTRY:
    case GET_CURRENT_WELLNESS_CHECK_IN_ENTRY:
      return {
        ...state,
        currentCheckIn: action.data,
      };

    default:
      return { ...state };
  }
};

export default checkInReducer;
