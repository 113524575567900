import React from 'react';
import { format, parseISO } from 'date-fns'
import {
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const ChatTimestamp = ({ msg }) => {
  const { data } = msg;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const date = format(parseISO(data), 'yyyy-MM-dd');
  const today = format(new Date(), 'yyyy-MM-dd');

  return (
    <div className={isMobile ? 'chat-timestamp chat-timestamp--mobile' : 'chat-timestamp'}>
      <Typography className='chat-timestamp__text'>
        {date === today ?
          'Today'
          :
          date
        }
      </Typography>
    </div>
  );
};

ChatTimestamp.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default ChatTimestamp;
