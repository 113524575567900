import React from 'react';
import {
  useTheme,
  useMediaQuery,
  Typography,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Section from '../components/Section';

const createMarkup = text => ({
  __html: text,
});

const SuccessTemplate = ({ image, message, success=true }) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButton = () => {
    history.push('/account/login');
  };

  return (
    <div className='success-template'>
      <div className='success-template__content'>
        <Section>
          {image && <img className='success-template__image full-width' src={image} alt='success-png' />}
          <Typography className='success-template__title' variant='h5' component='h5'>
            {success ? 
              'Done!'
            :
              'Error.'
            }
          </Typography>
          <Typography className={isMobile ? 'success-template__paragraph success-template__paragraph--mobile' : 'success-template__paragraph'} variant='body1' paragraph>
            <span dangerouslySetInnerHTML={createMarkup(message)} />
          </Typography>
        </Section>
        <div className='success-template__button-container'>
          <Button className='success-template__button' type='submit' variant='contained' color='secondary' onClick={handleButton}>
            {"Back to Sign in"}
          </Button>
          </div>
      </div>
    </div>
  );
}

export default SuccessTemplate;
