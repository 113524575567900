import React from 'react';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  Icon,
  IconButton,
} from '@material-ui/core';

function getIntialState(selectedFrom, selectedTo) {
  const intialState = {
    headBack: 'body-map--unselected',
    leftNeckBack: 'body-map--unselected',
    rightNeckBack: 'body-map--unselected',
    leftShoulderBlade: 'body-map--unselected',
    rightShoulderBlade: 'body-map--unselected',
    leftShoulderBack: 'body-map--unselected',
    centralBack: 'body-map--unselected',
    rightShoulderBack: 'body-map--unselected',
    leftMiddleBack: 'body-map--unselected',
    rightMiddleBack: 'body-map--unselected',
    leftUpperArmBack: 'body-map--unselected',
    rightUpperArmBack: 'body-map--unselected',
    leftLowerBack: 'body-map--unselected',
    rightLowerBack: 'body-map--unselected',
    leftLowerArmBack: 'body-map--unselected',
    rightLowerArmBack: 'body-map--unselected',
    leftButtock: 'body-map--unselected',
    rightButtock: 'body-map--unselected',
    leftHandBack: 'body-map--unselected',
    rightHandBack: 'body-map--unselected',
    leftThighBack: 'body-map--unselected',
    rightThighBack: 'body-map--unselected',
    leftCalfBack: 'body-map--unselected',
    rightCalfBack: 'body-map--unselected',
    leftAnkleBack: 'body-map--unselected',
    rightAnkleBack: 'body-map--unselected',
  };

  if (selectedFrom) {
    selectedFrom.forEach((part) => {
      intialState[part] = 'body-map--selected-from';
    });
  }
  if (selectedTo) {
    selectedTo.forEach((part) => {
      intialState[part] = 'body-map--selected-to';
    });
  }
  return intialState;
}

function BodyMapB({ selectedFrom, selectedTo, handleSelection, disabled=false }) {

  const bodyMap = getIntialState(selectedFrom, selectedTo);

  return (
    <svg className={disabled ? 'body-map--back-disabled' : 'body-map--back'} width="100%" height="357" viewBox="0 0 579.58 1775.47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id='RightNeckBack' className={bodyMap.rightNeckBack} onClick={() => handleSelection('rightNeckBack')}>
        <path d="M288.44,209.57h0v87.66H442.86c-47.79-15.85-105.59-23.36-105.59-23.36l-7.91-75.41c-6.8,3.95-19.17,11.11-40.92,11.11"/>
      </g>
      <g id='LeftNeckBack' className={bodyMap.leftNeckBack} onClick={() => handleSelection('leftNeckBack')}>
        <path d="M288.44,209.57h0c-14.51,0-27.58-4.46-39.62-11.61l-7.95,75.93c-8.23,1.14-60.86,8.73-104.39,23.34h152Z"/>
      </g>
      <g id='LeftAnkleBack' className={bodyMap.leftAnkleBack} onClick={() => handleSelection('leftAnkleBack')}>
        <path d="M220.74,1732.29c.29,5,1,17.52,8.51,30.58a60.85,60.85,0,0,0,4.52,6.74c8.41,10.93,25.86,5.62,26.67-8.15C264,1700.72,268,1647,271.15,1601.72l-61.87,3.77C207.52,1647.88,218.25,1689.93,220.74,1732.29Z"/>
      </g>
      <g id='LeftHandBack' className={bodyMap.leftHandBack} onClick={() => handleSelection('leftHandBack')}>
        <path d="M3.08,946.31A44.53,44.53,0,0,0,.84,971.17l7.32,37.27A33.3,33.3,0,0,0,17,1025.21l15.88,16.28a3.25,3.25,0,0,0,5.57-2.26l0-13.57,15.82,1.78a3.3,3.3,0,0,0,2.6-5.72l-26.25-24.1a16.44,16.44,0,0,1-5-9.08L25,985.46a16.51,16.51,0,0,1,1.67-10.73l10.14-18.91a2.26,2.26,0,0,1,4.24.78l.4,3.08a47,47,0,0,0,10.9,24.49A3.25,3.25,0,0,0,58,982.44l7.32-55.08a16.8,16.8,0,0,0-4.68-14L45.56,898l-24.07,1.47Z"/>
      </g>
      <g id='LeftLowerArmBack' className={bodyMap.leftLowerArmBack} onClick={() => handleSelection('leftLowerArmBack')}>
        <path d="M114.63,653.5,45.49,897.92l.07.07-24.07,1.47.06-.15S7.09,756.61,9.2,690c1.14-36.1,10.43-50.79,18.71-56.68a22.9,22.9,0,0,0,7.61-9Z"/>
      </g>
      <g id='LeftUpperArmBack' className={bodyMap.leftUpperArmBack} onClick={() => handleSelection('leftUpperArmBack')}>
        <path d="M35.53,624.25l79.1,29.25,24-209.82L40,403.48c-15.75,50.73-19.69,119-2.72,207.35A21.59,21.59,0,0,1,35.53,624.25Z"/>
      </g>
      <g id='LeftShoulderBack' className={bodyMap.leftShoulderBack} onClick={() => handleSelection('leftShoulderBack')}>
        <path d="M136.48,297.23c-21.75,7.3-41.22,16.35-51.75,27.23-18.39,19-34.2,45.09-44.74,79l98.67,40.2s4.31,11.94,10.14,28.13c24.51-4,48.59-6.83,74.5-8.56Z"/>
      </g>
      <g id='LeftMiddleBack' className={bodyMap.leftMiddleBack} onClick={() => handleSelection('leftMiddleBack')}>
        <path d="M289.5,460.91v125.9a400.7,400.7,0,0,0-107.3,14.9L179.49,559c-.14-2.08-18.6-53.75-30.63-87.23A809.16,809.16,0,0,1,289.5,460.91Z"/>
      </g>
      <g id='RightShoulderBack' className={bodyMap.rightShoulderBack} onClick={() => handleSelection('rightShoulderBack')}>
        <path d="M442.86,297.23c21.74,7.3,41.22,16.35,51.75,27.23,18.38,19,34.2,45.09,44.73,79l-98.67,40.2s-4.31,11.94-10.13,28.13c-24.51-4-48.6-6.83-74.5-8.56Z"/>
      </g>
      <g id='RightMiddleBack' className={bodyMap.rightMiddleBack} onClick={() => handleSelection('rightMiddleBack')}>
        <path d="M289.83,460.91v125.9a400.72,400.72,0,0,1,107.31,14.9L399.85,559c.13-2.08,18.6-53.75,30.63-87.23A809.27,809.27,0,0,0,289.83,460.91Z"/>
      </g>
      <g id='LeftThighBack' className={bodyMap.leftThighBack} onClick={() => handleSelection('leftThighBack')}>
        <path d="M186.65,936.44c-53.67-.56-88.5-36.05-100.6-51.1-4.92,62.57-.13,159.25,31.85,311.82q4.75,22.65,9.66,44a555.21,555.21,0,0,1,158.08-.17c1.08-96-.52-177.27-1.28-290.49-.14-21.71.49-65.39.49-65.4C271.7,900.26,241.09,937,186.65,936.44Z"/>
      </g>
      <g id='LeftCalfBack' className={bodyMap.leftCalfBack} onClick={() => handleSelection('leftCalfBack')}>
        <path d="M127.56,1241.21c40,174.8,85.14,288.8,81.75,363.49l0,.79,61.87-3.77c2-28,3.62-52.64,4.71-73.68,6.27-121.81,8.91-209.21,9.78-287a555.21,555.21,0,0,0-158.08.17Z"/>
      </g>
      <g id='RightHandBack' className={bodyMap.rightHandBack} onClick={() => handleSelection('rightHandBack')}>
        <path d="M576.5,946.31a44.44,44.44,0,0,1,2.25,24.86l-7.33,37.27a33.3,33.3,0,0,1-8.81,16.77l-15.88,16.28a3.25,3.25,0,0,1-5.57-2.26l0-13.57-15.81,1.78a3.31,3.31,0,0,1-2.61-5.72L549,997.62a16.37,16.37,0,0,0,5-9.08l.57-3.08a16.38,16.38,0,0,0-1.67-10.73l-10.13-18.91a2.27,2.27,0,0,0-4.25.78l-.4,3.08a47,47,0,0,1-10.89,24.49,3.25,3.25,0,0,1-5.67-1.73l-7.32-55.08a16.84,16.84,0,0,1,4.68-14L534,898l24.08,1.47Z"/>
      </g>
      <g id='RightLowerArmBack' className={bodyMap.rightLowerArmBack} onClick={() => handleSelection('rightLowerArmBack')}>
        <path d="M465,653.5l69.13,244.42L534,898l24.08,1.47-.06-.15S572.49,756.61,570.39,690c-1.15-36.1-10.44-50.79-18.71-56.68a22.93,22.93,0,0,1-7.62-9Z"/>
      </g>
      <g id='RightUpperArmBack' className={bodyMap.rightUpperArmBack} onClick={() => handleSelection('rightUpperArmBack')}>
        <path d="M544.05,624.25,465,653.5,439,443.6l100.56-40.12c15.75,50.73,19.69,119,2.72,207.35A21.66,21.66,0,0,0,544.05,624.25Z"/>
      </g>
      <g id='HeadBack' className={bodyMap.headBack} onClick={() => handleSelection('headBack')}>
        <rect x="212.49" width="154.03" height="209.57" rx="77.01"/>
      </g>
      <g id='LeftShoulderBlade' className={bodyMap.leftShoulderBlade} onClick={() => handleSelection('leftShoulderBlade')}>
        <polygon points="136.48 297.23 222.96 297.23 223.29 463.23 136.48 297.23"/>
      </g>
      <g id='RightShoulderBlade' className={bodyMap.rightShoulderBlade} onClick={() => handleSelection('rightShoulderBlade')}>
        <polygon points="442.86 297.23 356.9 297.26 356.57 463.26 442.86 297.23"/>
      </g>
      <g id='CentralBack' className={bodyMap.centralBack} onClick={() => handleSelection('centralBack')}>
        <path d="M357.17,297.27l-.33,165.93c-21-1.52-44-2.34-67.41-2.29-24-.53-45.77.75-66.4,2.13l-.33-165.77Z"/>
      </g>
      <g id='LeftButtock' className={bodyMap.leftButtock} onClick={() => handleSelection('leftButtock')}>
        <path d="M186.46,671.14c-23,24.52-54.63,62.21-78.08,116.58-7.49,17.37-18.25,45.78-22.33,97.62,12.1,15,46.78,51.1,100.6,51.1,52.19,0,81.58-30.94,102.79-56.71V685C248.52,685.32,213.89,678.5,186.46,671.14Z"/>
      </g>
      <g id='LeftLowerBack' className={bodyMap.leftLowerBack} onClick={() => handleSelection('leftLowerBack')}>
        <path d="M186.46,671.14c27.43,7.36,62.06,14.18,103,13.9V586.81a400.7,400.7,0,0,0-107.3,14.9Z"/>
      </g>
      <g id='RightAnkleBack' className={bodyMap.rightAnkleBack} onClick={() => handleSelection('rightAnkleBack')}>
        <path d="M358.54,1732.29c-.3,5-1,17.52-8.52,30.58a59.44,59.44,0,0,1-4.51,6.74c-8.42,10.93-25.86,5.62-26.67-8.15-3.59-60.74-7.55-114.51-10.72-159.74l61.87,3.77C371.76,1647.88,361,1689.93,358.54,1732.29Z"/>
      </g>
      <g id='RightThighBack' className={bodyMap.rightThighBack} onClick={() => handleSelection('rightThighBack')}>
        <path d="M392.63,936.44c53.67-.56,88.49-36.05,100.59-51.1,4.93,62.57.13,159.25-31.85,311.82q-4.74,22.65-9.66,44a555.21,555.21,0,0,0-158.08-.17c-1.08-96,.52-177.27,1.28-290.49.15-21.71-.48-65.39-.48-65.4C307.57,900.26,338.18,937,392.63,936.44Z"/>
      </g>
      <g id='RightCalfBack' className={bodyMap.rightCalfBack} onClick={() => handleSelection('rightCalfBack')}>
        <path d="M451.71,1241.21C411.75,1416,366.58,1530,370,1604.7l0,.79-61.87-3.77c-2-28-3.62-52.64-4.7-73.68-6.28-121.81-8.91-209.21-9.79-287a555.21,555.21,0,0,1,158.08.17Z"/>
      </g>
      <g id='RightButtock' className={bodyMap.rightButtock} onClick={() => handleSelection('rightButtock')}>
        <path d="M392.82,671.14c23,24.52,54.62,62.21,78.07,116.58,7.5,17.37,18.25,45.78,22.33,97.62-12.1,15-46.78,51.1-100.59,51.1-52.2,0-81.58-30.94-102.8-56.71V685C330.75,685.32,365.39,678.5,392.82,671.14Z"/>
      </g>
      <g id='RightLowerBack' className={bodyMap.rightLowerBack} onClick={() => handleSelection('rightLowerBack')}>
        <path d="M392.82,671.14c-27.43,7.36-62.07,14.18-103,13.9V586.81a400.72,400.72,0,0,1,107.31,14.9Z"/>
      </g>
    </svg>
  );
}

export default BodyMapB;
