import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageScrollController = () => {
  const location = useLocation();

  useEffect(() => {

    // Don't do anything if the from is undefined.
    // This usually means the page refresh, or going back in history
    if (!location?.state?.from?.pathname) {
      return;
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

export default PageScrollController;
