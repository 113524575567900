import React from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Typography,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Section from './Section';

function HPModal({
  open,
  onClose,
  className,
  image,
  title,
  buttonText,
  buttonOnClick,
  text,
  textOnClick,
  children,
}) {

  return (
    <Modal
      aria-labelledby='hp-modal-title'
      aria-describedby='hp-modal-description'
      className={`hp-modal ${className}`}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className='hp-modal__content'>
          {image &&
          <img className='hp-modal__image' src={image} alt={`${title} image`} />
          }
          <Section>
            {title &&
              <Typography align='center' className='hp-modal__title' variant='h4' component='h4'>
                {title}
              </Typography>
            }
            {children &&
              <Typography
                className='hp-modal__paragraph'
                variant='body1'
                align='center'
                paragraph
              >
                {children}
              </Typography>
            }
          </Section>
          {(buttonOnClick && buttonText) &&
            <Button
              variant='contained'
              color='primary'
              className='text-button hp-modal__button'
              onClick={buttonOnClick}
              style={{ width: 'auto' }}
            >
              {buttonText}
            </Button>
          }
          {(textOnClick && text) &&
            <Typography className='hp-modal__text' onClick={textOnClick} variant='subtitle2'>
              {text}
            </Typography>
          }
        </div>
      </Fade>
    </Modal>
  );
}

HPModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  image: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default HPModal;
