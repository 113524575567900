// TODO: Clean up

import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import ArticleCard from './../../ArticleCard';
import updateImagePath from '../../../util/getImagePath';


const ChatArticle = ({ msg }) => {
  const { choices, title } = msg;
  return (
    <div className='chat-article__container'>
      { title &&
        <Typography className='chat-article__title' variant='h5'>{title}</Typography>
      }
      {
        choices.map(article =>
          <div className='chat-article__row' key={article.title}>
            <ArticleCard image={updateImagePath(article.thumbnail)} title={article.title} link={`/library/article/${article.slug}`} type={article.type} />
          </div>
        )
      }
    </div>
  );
};

ChatArticle.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default ChatArticle;
