import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  LinearProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';

function ProgressCard({
  image,
  title,
  link,
  progress,
  type,
  isSubmit,
}) {

  const completeIconImage = '/images/progress_complete_icon.png';
  const progressColor = isSubmit ? 'secondary' : 'primary';

  return (
    <Card className='progress-card'>
      <CardActionArea className='progress-card__content' href={link}>
        <CardMedia
          className='progress-card__image'
          component='img'
          alt='Progress image'
          image={image}
          title={title}
        />
        <CardContent className='progress-card__title'>
          <Typography className='progress-card__header' variant='subtitle1'>
            {title}
          </Typography>
          {progress === 0
            && (
              <Typography variant='subtitle2' color='textSecondary'>
                Get Started
              </Typography>
            )
          }
          <div className="progress-card__indicator">
            {progress > 0
              && (
              <LinearProgress
                variant='determinate'
                value={progress * 100}
                color={progressColor}
                />
              )
            }
            {isSubmit && progress === 1
              && (
              <CardMedia
                className='progress-card__icon'
                component='img'
                alt='progress type icon'
                image={completeIconImage}
              />
              )
            }
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

ProgressCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  isSubmit: PropTypes.bool.isRequired,
};

ProgressCard.defaultProps = {
  image: '/images/article_image.png',
  isSubmit: false,
};

export default ProgressCard;
