import { api } from './api';

async function getHealthBackground(user) {
  try {
    const { data, status } = await api.get('/profile/health-background', user);
    return data;
  } catch (e) {
    return null;
  }
}

async function updateHealthBackground(value, user) {
  try {
    const { data, status } = await api.put('/profile/health-background', value, user);
    return data;
  } catch (e) {
    return null;
  }
}

async function getEventProfile(user) {
  try {
    const { data, status } = await api.get('/profile/event-profile', user);
    return data;
  } catch (e) {
    return null;
  }
}

async function updateEventProfile(value, user) {
  try {
    const { data, status } = await api.post('/profile/event-profile', value, user);
    return data;
  } catch (e) {
    return null;
  }
}

export {
  getHealthBackground,
  updateHealthBackground,
  getEventProfile,
  updateEventProfile,
};
