import React from 'react';
import MainTemplate from '../templates/MainTemplate';
import BodyDialog from '../components/BodyDialog';

const Education = () => (
  <MainTemplate>
    Education page
    <BodyDialog />
  </MainTemplate>
);

export default Education;
