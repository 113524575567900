import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  useTheme,
  useMediaQuery,
  Typography,
  Button,
  Icon,
  TextField,
  Fab,
  Slide,
  Dialog,
} from '@material-ui/core';
import { format } from 'date-fns'
import EditIcon from '@material-ui/icons/Edit';
import MainTemplate from '../templates/MainTemplate';
import BodyMapF from '../components/BodyMapF';
import BodyMapB from '../components/BodyMapB';
import Section from '../components/Section';
import BodyMapEdit from '../components/BodyMapEdit';
import Checkbox from './data-donation/form/Checkbox';
import { bodyPartsName } from '../constant/bodyPartsName';
import { discomfortOption } from '../constant/discomfortOption';
import { symptomOption } from '../constant/symptomOption';
import { getEventProfile, updateEventProfile } from '../services/heartPainApi/profile';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventProfileEdit = () => {
  const [profile, setProfile] = useState(null);


  const [date, setDate] = useState('');
  const [descriptionOther, setDescriptionOther] = useState(null);
  const [symptomOther, setSymptomOther] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [openFrom, setOpenFrom] = React.useState(false);
  const [openTo, setOpenTo] = React.useState(false);

  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    (async () => {
      const { rawData = {} } = await getEventProfile() ?? {};
      if (Object.keys(rawData).length === 0){
        setProfile({});
      } else {
        const { data, createdAt } = rawData;
        setDate(format(new Date(createdAt), 'MMMM dd, yyyy'))
        setProfile(data);
        if (data.description.list.includes('other')) {
          setDescriptionOther(data.description.other);
        }
        if (data.symptom.list.includes('other')) {
          setSymptomOther(data.symptom.other);
        }
      }
    })()
  }, []);

  const FrontBodyDisplay = ({ selectedFrom, selectedTo }) => (
    <>
      <Typography className='event-profile__body-map-label'>Front</Typography>
      <Typography className={isMobile ? 'event-profile__body-map-direction-l' : 'body-modal__map-direction-l'}>R</Typography>
      <Typography className={isMobile ? 'event-profile__body-map-direction-r' : 'body-modal__map-direction-r'}>L</Typography>
      <BodyMapF selectedFrom={selectedFrom} selectedTo={selectedTo} disabled />
    </>
  )

  const BackBodyDisplay = ({ selectedFrom, selectedTo }) => (
    <>
      <Typography className='event-profile__body-map-label'>Back</Typography>
      <Typography className={isMobile ? 'event-profile__body-map-direction-l' : 'body-modal__map-direction-l'}>L</Typography>
      <Typography className={isMobile ? 'event-profile__body-map-direction-r' : 'body-modal__map-direction-r'}>R</Typography>
      <BodyMapB selectedFrom={selectedFrom} selectedTo={selectedTo} disabled />
    </>
  )

  function handleAnswerChange(event, value, profileId) {
    setCanSubmit(value.length !== 0);
    if (profileId === 'description' && value[0] === 'no_pain_discomfort') {
      const emptyLocation = {
        location: []
      }
      setProfile({
        ...profile,
        [profileId]: {
          ...profile[profileId],
          list: value,
        },
        'from': emptyLocation,
        'to': emptyLocation,
      });
    } else {
      setProfile({
        ...profile,
        [profileId]: {
          ...profile[profileId],
          list: value,
        },
      });
    }
  }

  function handleBodyChange(from, to) {
    const fromResult = {
      location: from
    };
    const toResult = {
      location: to
    }
    setCanSubmit(true);
    setProfile({
      ...profile,
      from: fromResult,
      to: toResult
    })
  }

  function handleNoTravel() {
    if (profile.to.location.length > 0) {
      const toResult = {
        location: []
      }
      setCanSubmit(true);
      setProfile({
        ...profile,
        to: toResult
      })
    }
  }

  async function handleSubmit() {
    const eventProfileData = {
      profile,
      descriptionOther,
      symptomOther,
    }
    await updateEventProfile(eventProfileData);
    history.push('/profile/event-profile');
  }

  const handleOpenFrom = () => {
    setOpenFrom(true);
  };

  const handleOpenTo = () => {
    setOpenTo(true);
  };

  const handleCloseFrom = () => {
    setOpenFrom(false);
  };

  const handleCloseTo = () => {
    setOpenTo(false);
  };

  if (profile === null) {
    return null;
  } else if (Object.keys(profile).length === 0) {
    return(
      <MainTemplate title='My profile' hasBack={true} hasDrawer={false} hasPadding={true}>
        <Section>
          <Typography className='event-profile__title' variant='h5'>
            Please finish Onboarding flow in Chat first.
          </Typography>
        </Section>
      </MainTemplate>
    );
  } else {
    return (
      <MainTemplate title='My profile' hasBack={true} hasDrawer={false} hasPadding={true}>
        <img className='event-profile__picture' src='/images/event_profile_logo.png' alt='chat banner image' />
        <Section>
          <Typography className='event-profile__title event-profile__title--edit' variant='h5'>
            My event profile
          </Typography>
          <Typography className='event-profile__title-description'>
            Update your event profile to reflect your most recent cardiac event.
          </Typography>
          <Typography className='event-profile__date'>
            Last updated: {date}
          </Typography>
          <div className='event-profile__row'>
            <Checkbox
              nodeId={'description'}
              key={`single_checkbox_1`}
              question='1. At the time of your last cardiac event, how would you describe your heart pain/discomfort?'
              data={discomfortOption}
              answer={profile.description.list}
              onParentChange={handleAnswerChange}
              multi={true}
            />
            {(profile['description'].list.includes('other')) &&
              <TextField
                className='event-profile__other'
                color='secondary' id="description-other"
                label="Other pain/discomfort"
                variant="outlined"
                value={descriptionOther}
                onChange={(e) => {
                  setCanSubmit(true);
                  setDescriptionOther(e.target.value);
                }}
              />
            }
          </div>
          <div className='event-profile__row'>
            <Typography className='event-profile__question' variant='subtitle1'>
              2. Where did you experience this pain/discomfort?
            </Typography>
            <Typography>
              {
                profile['from'].location.map(value => bodyPartsName[value]).join(', ')
              }
            </Typography>
            <div className='event-profile__body-map-container'>
              <div className='event-profile__fab'>
                <Fab color='primary' aria-label='edit' onClick={handleOpenFrom} disabled={profile.description.list[0] === 'no_pain_discomfort'}>
                  <EditIcon />
                </Fab>
              </div>
              <div className={isMobile ? 'event-profile__body-map event-profile__body-map--space' : 'chat-body-map__map chat-body-map__map--space'}>
                <FrontBodyDisplay selectedFrom={profile['from'].location} selectedTo={[]} />
              </div>
              <div className={isMobile ? 'event-profile__body-map' : 'chat-body-map__map'}>
                <BackBodyDisplay selectedFrom={profile['from'].location} selectedTo={[]} />
              </div>
            </div>
            <Dialog open={openFrom} TransitionComponent={Transition} onClose={handleCloseFrom}>
              <BodyMapEdit isFirst={true} fromData={profile['from'].location} toData={profile['to'].location} handleClose={handleCloseFrom} handleBodyChange={handleBodyChange} />
            </Dialog>
          </div>
          <div className='event-profile__row'>
            <Typography className='event-profile__question' variant='subtitle1'>
              3. Did your pain/discomfort spread or move? If so, select the location(s) it moved to
            </Typography>
            <Typography>
              {
                profile['to'].location.map(value => bodyPartsName[value]).join(', ')
              }
            </Typography>
            <div className='event-profile__body-map-container'>
              <div className='event-profile__fab'>
                <Fab color='primary' aria-label='edit' onClick={handleOpenTo} disabled={profile.description.list[0] === 'no_pain_discomfort'}>
                  <EditIcon />
                </Fab>
              </div>
              <div className={isMobile ? 'event-profile__body-map event-profile__body-map--space' : 'chat-body-map__map chat-body-map__map--space'}>
                <FrontBodyDisplay selectedFrom={profile['from'].location} selectedTo={profile['to'].location} />
              </div>
              <div className={isMobile ? 'event-profile__body-map' : 'chat-body-map__map'}>
                <BackBodyDisplay selectedFrom={profile['from'].location} selectedTo={profile['to'].location} />
              </div>
            </div>
            <div className='chat-checkbox'>
              <div className='chat-checkbox__row'>
                  <Button
                    key='no-travel'
                    className={`checkbox${(profile['to'].location.length === 0) ? ' selected' : ''}`}
                    variant="contained"
                    onClick={() => handleNoTravel()}
                    disableElevation
                  >
                    Nope, the pain didn't travel
                  </Button>
              </div>
            </div>
            <Dialog open={openTo} TransitionComponent={Transition} onClose={handleCloseTo}>
              <BodyMapEdit isFirst={false} fromData={profile['from'].location} toData={profile['to'].location} handleClose={handleCloseTo} handleBodyChange={handleBodyChange} />
            </Dialog>
          </div>
          <div className='event-profile__row'>
            <Checkbox
              nodeId={'symptom'}
              key={`single_checkbox_4`}
              question='4. What other symptoms, if any, did you experience at the time of your last cardiac event?'
              data={symptomOption}
              answer={profile.symptom.list}
              onParentChange={handleAnswerChange}
              multi={true}
            />
            {(profile['symptom'].list.includes('other')) &&
              <TextField
                className='event-profile__other'
                color='secondary'
                id="symptom-other"
                label="Other symptoms"
                variant="outlined"
                value={symptomOther}
                onChange={(e) => {
                  setCanSubmit(true);
                  setSymptomOther(e.target.value);
                }}
              />
            }
          </div>
          <div className='event-profile__button-container'>
            <Button
              className='event-profile__button'
              color='primary'
              variant="contained"
              disabled={!canSubmit}
              onClick={handleSubmit}
            >
              Save changes
            </Button>
          </div>
        </Section>
      </MainTemplate>
    )
  }
}

export default EventProfileEdit;
