import {
  answerNode,
  getNextNode,
} from '../../services/heartPainApi/node';

import {
  getChatMessages,
  triggerHeartCheck,
  triggerWellnessCheck,
} from '../../services/heartPainApi/chat';

import sleep from '../../util/sleep';
import { calculateMessageDisplayDelay } from '../../util/chat';
import { ChatMessageFrom, IsProd } from '../../constant';


export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_OLD_MESSAGES = 'GET_OLD_MESSAGES';
export const GET_SCHEDULE_MESSAGES = 'GET_SCHEDULE_MESSAGE';
export const UPDATE_FIRST_SCHEDULE_MESSAGE_TO_MESSAGES = 'UPDATE_FIRST_SCHEDULE_MESSAGE_TO_MESSAGES';
export const SHOW_KEYBOARD = 'SHOW_KEYBOARD';
export const HIDE_KEYBOARD = 'HIDE_KEYBOARD';
export const BOT_IS_TYPING = 'BOT_IS_TYPING';

export const getMessages = data => ({
  type: GET_MESSAGES,
  data,
});

export const getOldMessages = data => ({
  type: GET_OLD_MESSAGES,
  data,
});

export const getScheduleMessages = data => ({
  type: GET_SCHEDULE_MESSAGES,
  data,
});

export const updateFirstScheduleMessageToMessages = () => ({
  type: UPDATE_FIRST_SCHEDULE_MESSAGE_TO_MESSAGES,
});

export const showKeyboard = () => ({
  type: SHOW_KEYBOARD
});

export const hideKeyboard = () => ({
  type: HIDE_KEYBOARD
});

export const botIsTyping = (isTyping = true) => ({
  type: BOT_IS_TYPING,
  payload: {
    isTyping,
  },
});

export function triggerWellnessCheckFlow() {
  return async dispatch => {
    const { skip } = await triggerWellnessCheck();
    if (!skip) {
      dispatch(fetchMessages());
    }
    return skip;
  };
}

export function triggerHeartCheckFlow() {
  return async dispatch => {
    const { skip } = await triggerHeartCheck();
    if (!skip) {
      dispatch(fetchMessages());
    }
    return skip;
  };
}

export function scrollToLatestMessage() {
  const chatMessages = document.querySelector('.chat-messages');
  if (chatMessages) {
    setTimeout(() => {
      chatMessages.scrollTop = chatMessages.scrollHeight;
    }, 100);
  };
}

export function fetchMessages() {
  return async (dispatch, getState) => {
    let node = getState().chat.currentNode;
    let data = await getChatMessages({after: getState().chat.lastMessageId});
    if (node.id !== data.node.id){
      await dispatch(getMessages(data));
      dispatch(scrollToLatestMessage)
    }
  };
}

export function fetchMoreMessages() {
  return async (dispatch, getState) => {
    let firstId = getState().chat.firstMessageId;
    if (firstId) {
      let data = await getChatMessages({before: firstId});
      await dispatch(getOldMessages(data));
    }
  };
}

export function fetchScheduleMessages() {
  return async (dispatch, getState) => {
    let node = getState().chat.currentNode;
    let data = await getChatMessages({after: getState().chat.lastMessageId});
    if (node.id !== data.node.id){
      await dispatch(getScheduleMessages(data));
      await dispatch(checkScheduleMessages());
    }
  };
}

export function checkPendingMessages() {
  return async dispatch => {
    setInterval(() => {
      dispatch(fetchMessages());
    }, 5000);
  }
}

export function checkScheduleMessages() {
  return async (dispatch, getState) => {
    if (getState().chat.scheduleMessages.length === 0) {
      return;
    }

    if (IsProd) {
      await dispatch(hideKeyboard());
    }

    while (getState().chat.scheduleMessages.length > 0) {
      const scheduleMessages = getState().chat.scheduleMessages;
      const nextMessage = scheduleMessages[0];

      if (IsProd) {
        const delay = calculateMessageDisplayDelay(nextMessage);
        if (nextMessage.from !== ChatMessageFrom.USER && delay > 0) {
          await dispatch(botIsTyping(true));
          await sleep(delay);
          await dispatch(botIsTyping(false));
        }
      }

      await dispatch(updateFirstScheduleMessageToMessages());
      await dispatch(scrollToLatestMessage);
      await dispatch(fetchMessages());
    }
    await dispatch(showKeyboard());
    await dispatch(scrollToLatestMessage);
  }
}

export function setAnswer(node, data) {
  const postData = { payload: data };

  return async (dispatch, getState) => {
    const nextNode = await answerNode(node.id, postData);
    dispatch(fetchScheduleMessages());
  };
}
