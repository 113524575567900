import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Link,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { registerAction } from '../../redux/actions/authActions';
import BackButton from '../../components/BackButton';
import Section from '../../components/Section';
import DesktopSignInTemplate from '../../templates/DesktopSignInTemplate';

const SignupForm = ({ handleFormFilled, isValid, errorMessage, values }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const passwordProps = {
    endAdornment: (
      <InputAdornment position='end'>
        <IconButton
          aria-label='toggle password visibility'
          onClick={handleClickShowPassword}
        >
          {showPassword
            ? <img src='/images/VisiblePassword.svg' />
            : <img src='/images/HiddenPassword.svg' />
          }
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <>
      <Field
        className='login__input'
        component={TextField}
        name='email'
        type='email'
        label='Email'
      />
      <Field
        className='login__input'
        component={TextField}
        name='emailConfirm'
        type='email'
        label='Confirm email'
      />
      <Field
        className='login__input'
        component={TextField}
        name='password'
        type={showPassword ? 'text' : 'password'}
        label='Create a password'
        InputProps={passwordProps}
      />
      <Field
        className='login__input'
        component={TextField}
        name='passwordConfirm'
        type={showPassword ? 'text' : 'password'}
        label='Confirm password'
        InputProps={passwordProps}
      />
      <Field
        className='login__input'
        component={TextField}
        name='studyId'
        type='text'
        label='Unique study ID'
      />
      <div className='login__submit'>
        {errorMessage &&
          <Typography className='login__error-message'>{errorMessage}</Typography>
        }
        <Button className='login__button' variant='contained' color='secondary' disabled={!isValid} onClick={()=>{handleFormFilled(values)}}>Next</Button>
        <Typography className='' variant='subtitle2'>
          {"Already have an account yet?  "}
          <Link className='green-text semi-bold' component={RouterLink} to="/account/login">
            Sign in
          </Link>
        </Typography>      
      </div>
    </>
  )
}

const Signup = ({ handleFormFilled, isValid, errorMessage, values }) => {

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <div className='login'>
        <BackButton />
        <img className='login__logo login__logo--small' src='/images/login_logo.png' alt='hp-logo' />
        <Section>
          <Typography className='login__title' variant='h4' component='h4'>
            Create account
          </Typography>
          <SignupForm handleFormFilled={handleFormFilled} isValid={isValid} errorMessage={errorMessage} values={values} />
        </Section>
      </div>
    );
  } else {
    return (
      <>
      <BackButton isAbsolute={true} />
      <DesktopSignInTemplate picture='/images/login_logo.png' left={6} right={6}>
        <Section  className='login-desktop__section'>
          <Typography className='login__title' variant='h4' component='h4'>
            Create account
          </Typography>
          <SignupForm handleFormFilled={handleFormFilled} isValid={isValid} errorMessage={errorMessage} values={values} />
        </Section>
      </DesktopSignInTemplate>
      </>
    );
  }
};

export default Signup;
