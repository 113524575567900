import React from 'react';
import {
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';


const createMarkup = text => ({
  __html: text,
});

const ChatText = ({ msg }) => {
  let chatClass = ((msg.from === 'USER') ? 'chat-messages__user' : 'chat-messages__bot');
  chatClass += ((msg.state === 'red') ? ' chat-messages__red' : '');
  chatClass += ((msg.state === 'yellow') ? ' chat-messages__yellow' : '');

  return (
    <section className={`chat-messages__message ${chatClass}`}>
      <Typography>
        <span dangerouslySetInnerHTML={createMarkup(msg.text)} />
      </Typography>
    </section>
  );
}

ChatText.propTypes = {
  msg: PropTypes.object.isRequired,
};

export default ChatText;
