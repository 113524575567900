import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { registerAction } from '../../redux/actions/authActions';
import { registerCheck } from '../../services/heartPainApi/auth';

import Signup from './signup';
import Terms from './terms';

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
  emailConfirm: Yup.string()
    .oneOf([Yup.ref('email'), null], "The email addresses don't match"),
  password: Yup.string()
    .min(8, 'Password must be at least 9 characters long')
    .required('Required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], "The passwords don't match"),
  studyId: Yup.string()
    .length(5, 'ID must be 5 characters long')
    .matches(/^[0-9]*$/g, 'Study ID is invalid')
    .required('Required'),
});

const initialValues = {
  email: '',
  password: '',
  emailConfirm: '',
  passwordConfirm: '',
  studyId: '',
};

const SignupContainer = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [formFilled, setFormFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (values) => {
    const success = await dispatch(registerAction(values));
    if (success) {
      history.push('/account/success');
    }
  };

  const handleFormFilledCheck = async(values) => {
    const registerData = {email: values.email, studyId: values.studyId};
    const registerError = await registerCheck(registerData);
    if (!registerError) {
      setFormFilled(true);
      history.push('/account/signup/terms');
    } else {
      setErrorMessage(registerError.error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form className='login-desktop'>
          <Switch>
            <Route exact path="/account/signup" render={routeProps => <Signup {...routeProps} handleFormFilled={handleFormFilledCheck} isValid={isValid} errorMessage={errorMessage} values={values}/>}/>
            <Route path="/account/signup/terms" render={routeProps => <Terms {...routeProps} formFilled={formFilled} values={values}/>}/>
            <Route render={() => <Redirect to='/account/signup' />} />
          </Switch>
        </Form>
      )}
    </Formik>
  )
}

export default SignupContainer;