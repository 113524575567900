// TODO: This needs to be cleaned up

import React, { useState } from 'react';
import {
  TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import { detect } from 'detect-browser';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import ChatSendButton from './ChatSendButton';

const ChatDatePicker = ({ action, onAnswer }) => {
  const [date, setDate] = useState('');
  const browser = detect();

  if (browser.name === 'safari' && browser.os !== 'ios') {
    return (
      <div className='chat-textinput'>
        <ChatSendButton onClick={() => onAnswer({ answer: format(date, 'yyyy-MM-dd') })} disabled={date.length === 0} />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            className='chat-desktop__safari-date-picker'
            variant="inline"
            inputVariant="outlined"
            format="yyyy-MM-dd"
            value={date}
            onChange={date => {
              setDate(date)
            }}
            error={false}
            helperText={null}
            disableFuture
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  } else {
    return (
      <div className='chat-textinput'>
        <ChatSendButton onClick={() => onAnswer({ answer: date })} disabled={date.length === 0} />
        <TextField
          id="chat-text-field"
          type="date"
          className='chat-textinput__field chat-textinput__field--date'
          variant="outlined"
          onChange={e => setDate(e.target.value)}
          placeholder='yyyy-mm-dd'
        />
      </div>
    );
  }
};

ChatDatePicker.propTypes = {
  action: PropTypes.object.isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default ChatDatePicker;
