import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { fetchCurrentArticle } from '../redux/actions/articleActions';
import ArticleTemplate from '../templates/ArticleTemplate';
import { useParams } from "react-router-dom";
import updateImagePath from '../util/getImagePath';
import ReactPlayer from 'react-player';

const createMarkup = text => ({
  __html: text,
});

const Article = () => {

  let { slug } = useParams();

  const dispatch = useDispatch();

  const currentArticle = useSelector(state => state.article.currentArticle);

  useEffect(() => {
    dispatch(fetchCurrentArticle(slug));
  }, []);

  let path;

  if (currentArticle && currentArticle.featuredImage) {
    path = updateImagePath(currentArticle.featuredImage);
  }

  return (
    currentArticle ?
      <ArticleTemplate id={currentArticle.id} title={currentArticle.title} category={currentArticle.category} image={path} favourited={currentArticle.is_favourited}>
        {currentArticle.type === 'youtube' &&
          <ReactPlayer
            className='article__video-player'
            url={currentArticle.data.link}
            controls
            width="100%"
          />
        }
        <Typography>
          <span dangerouslySetInnerHTML={createMarkup(currentArticle.content)} />
        </Typography>
      </ArticleTemplate>
    :
      null
  );

}
export default Article;
