export default `
<div class='terms-conditions'>
  <p><strong>Acceptance</strong></p>
  <p>It is important that you read all the terms and conditions carefully. Your access and use of <strong>at heart</strong> is conditioned on your acceptance of and compliance with the terms and conditions. These terms and conditions apply to all users of <strong>at heart</strong> and all written and other materials made available through the WebApp, including, without limitation, text, photographs, images, illustrations, audio clips, video clips, computer software and code (the &lsquo;Content&rsquo;). By accessing and using <strong>at heart</strong> you agree to be bound by these terms and conditions. If you disagree with any part of the terms and conditions then you must not access the <strong>at heart</strong> WebApp. These terms and conditions may be updated at any time. Use of the <strong>at heart</strong> WebApp after such changes are posted will signify your acceptance of the revised terms and conditions. You should visit this page periodically to review this agreement.</p>
  <p><strong>Content</strong></p>
  <p>The <strong>at heart</strong> WebApp may help you make decisions about cardiac pain/discomfort and/or symptoms if you have already been diagnosed with heart disease. You are at the centre of care in <strong>at heart</strong>. This means that content is tailored to your unique symptoms and needs. <strong>at heart</strong> focuses on self-management and wellness for women who have established heart disease. Based on the information you enter and the status of your heart pain/discomfort and/or symptoms, you will get feedback and suggestions on four aspects of wellness: physical, mental, emotional, and spiritual. Each of these aspects should be equally developed for optimal health.</p>
  <p><strong>Copyright</strong></p>
  <p>All information in the <strong>at heart</strong> WebApp is protected by copyright Canada and internationally. No information can be reproduced or distributed without the prior and express permission of the principal investigator.</p>
  <p><strong>Not Medical Advice</strong></p>
  <p>The information provided in the <strong>at heart</strong> WebApp is for informational purposes only and is not professional medical advice, diagnosis, treatment or care, nor is it intended to be a substitute for this care. Always seek the advice of a physician or other qualified health care provider properly licensed to practice medicine or general health care in your jurisdiction concerning any questions you may have regarding any information obtained from <strong>at heart</strong> and any medical conditions you believe may be relevant to you or to someone else. Never disregard professional medical advice or delay in seeking it because of something you have read in <strong>at heart</strong>. Always consult with your physician or other qualified health care provider before embarking on a new treatment, diet or fitness program. Information contained in <strong>at heart</strong> is not exhaustive and does not cover all the available information related to heart disease.</p>
  <p><strong>Medical Emergency</strong></p>
  <p>Do not use <strong>at heart</strong> for medical emergencies. If you know you have a medical emergency, call a physician, qualified health care provider or 911 immediately. Under no circumstances should you attempt self-management of acute cardiac pain/discomfort and/or symptoms based on anything you have seen or read in <strong>at heart</strong>.</p>
  <p><strong>No Regulatory Approval</strong></p>
  <p>The <strong>at heart</strong> WebApp has not been evaluated by Health Canada or any other domestic or foreign regulatory agency and is not approved to diagnose, treat, cure or prevent major adverse cardiac events (MACE). While the information contained may be of use in other jurisdictions, these materials were provided and designed for use in Canada.</p>
  <p><strong>Disclaimer</strong></p>
  <p>The <strong>at heart</strong> WebApp content is provided &lsquo;as is&rsquo;. The information is intended to be correct, accurate, current and timely. However, under no circumstances can the investigators, designers or developers be held liable for consequences caused by or deriving from use of the WebApp or any information contained in the WebApp. This includes damages arising from use of the WebApp, including any that may arise from an defects or viruses caused from downloading or using the WebApp, defects or errors in the WebApp or the content (be it human or computer), or from third party websites (via hyperlinks).</p>
  <p><strong>Links to Third Party Websites</strong></p>
  <p><strong>at heart</strong> may contain links to third-party websites that are not controlled or connected to <strong>at heart</strong> To this end, <strong>at heart</strong> has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party website or service. You acknowledge that <strong>at heart</strong> shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use or reliance on any such content, goods or services available on or through any such websites or services.</p>
  <p><strong>Confidentiality</strong></p>
  <p>These Terms and Conditions also reflect data protection laws and provide clarity regarding how we collect, store and use information. Here is a summary of our terms:</p>
  <ul>
  <li>To ensure privacy, all identifying information (e.g., name, address, email, etc.) will be stored separately from personal health information</li>
  <li>None of your identifying information will be transmitted after the initial log-in (e.g., email address, password, etc.)</li>
  <li>The <strong>at heart</strong> WebApp also collects personal health information (e.g., socioeconomics, risk factors, gynecological/obstetrics, lifestyle, and other conditions). You can choose to share/not share this information. This personal health information will be stored separately from your identifying information on a secure server and only accessible by the principal investigator and investigative team</li>
  <li>The <strong>at heart</strong> WebApp collects statistical information through a log file. This generates information that includes, but not limited to, pages visited, type of device, unique device ID, IP address, mobile operating system, internet browser, etc. It is important to know that individual identities cannot be determined, and no information is shared with third parties. For the purposes of this pilot study, your information will be stored and destroyed as per the policies at the University of Toronto</li>
  <li>The information you enter into the <strong>at heart</strong> WebApp will not be provided to your healthcare team and is not part of your medical record</li>
  <li>For security reasons, all transmitted information will be sent by encrypted HTTPS connections</li>
  <li>Your use of the <strong>at heart</strong> WebApp is entirely voluntary, which means you may choose to skip questions related to your education and income and will not be penalized for this</li>
  </ul>
  <p><strong>Security</strong></p>
  <p>We are concerned about safeguarding the confidentiality of your information. Physical, electronic, and procedural safeguards will be used to protect your information. Only the principal investigator and members of the investigative team will have access to your information.</p>
  <p><strong>Contact Us</strong></p>
  <p>If you have any questions about these terms and conditions, please email <a href="mailto:women.atheart@utoronto.ca">women.atheart@utoronto.ca</a> or telephone <a href="tel:+18885662515">1-888-566-2515</a>.</p>
  <p><strong>Express Consent to Receive Electronic Messages from the </strong><strong>at heart</strong><strong>WebApp</strong></p>
  <p>By using the WebApp, I hereby expressly consent to receiving periodic electronic messages, including alerts, reminders and notifications from the <strong>at heart</strong> WebApp.</p>
</div>
`;
